<template>
    <div class="about">
        <Header v-if="state" ref="headBasicRef" :homeBasisData="homeBasisData" :homeClassData="homeClassData"
                  :homeCourseData="homeCourseData" :key="timer"/>
        <Left ref="basicRef" v-if="state" :homeBasisData="homeBasisData"/>
        <div v-if="state" class="content-box" :class="{ 'content-collapse': collapse }">
            <div class="content" v-if="state1">
                <router-view v-slot="{ Component }">
                    <transition name="move" mode="out-in">
                        <keep-alive :include="tagsList">
                            <component :is="Component"/>
                        </keep-alive>
                    </transition>
                </router-view>
                <!-- <el-backtop target=".content"></el-backtop> -->
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "./vHeader";
import Header from "./Header";
import vSidebar from "./vLeft";
import Left from "./Left";
import axios from "axios";
import TeacherUser from "../../models/Home/TeacherUser";
import websocket from "../../utils/websocket";

export default {
    components: {
        vHeader,
        vSidebar,
        Header,
        Left
    },
    data() {
        return {
            homeClassData: [],
            homeCourseData: [],
            contentState: false,
            state: false,
            state1: false,
            homeBasisData: {},
            timer: '',
            codeNum: '',
        }
    },
    beforeCreate() {
    },
    created() {
        // this.state = true;
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }
        this.homeBasisInfo();

        // this.timer = new Date().getTime()
    },
    inject: ["conn_id"],
    watch: {
        theConnId() {
            // console.log('conn_id改变')
        }
    },
    computed: {

        tagsList() {
            return this.$store.state.tagsList.map(item => item.name);
        },
        collapse() {
            return this.$store.state.collapse;
        },
        theConnId() {
            return this.conn_id()
        }
    },
    methods: {
        //首页基本信息
        homeBasisInfo() {
            TeacherUser.getBasisInfo({}, ({type, list}) => {
                if (type === 200) {
                    this.homeBasisData = list;
                    this.homeGroupList();
                    // this.$refs.basicRef.info = list;
                    localStorage.setItem("goodsCategory", list.bureau.goods_category);
                }
            })
        },
        //班级列表
        homeGroupList() {
            const params = {
                limit: 999999,
                page: 1
            }
            TeacherUser.getGroupList(params, ({type, list}) => {
                if (type === 200) {
                    if (this.$store.getters.getGroupData === null) {
                        if (list.list.length !== 0) {
                            this.$store.commit('setGroupData', {
                                group_id: list.list[0].group_id,
                                group_name: list.list[0].group_name,
                                group_phone: list.list[0].phone,
                            });
                        }
                    }
                    this.homeClassData = list.list;
                    // this.group_name = list.list[0].group_name;
                    this.homeCourseList();
                }
            })
        },
        //首页课程标题
        homeCourseList() {
            const params = {
                group_id: this.$store.getters.getGroupData.group_id,
            }
            TeacherUser.getCourseList(params, ({type, list}) => {
                if (type === 200) {
                    this.homeCourseData = list.list;
                    this.$store.commit('setCourseData', list.list);
                    this.state1 = true;
                    this.state = true;
                    this.timer = new Date().getTime()
                    this.codeNum = new Date().getTime() + 1;

                    websocket.Send({
                        // token: JSON.parse(localStorage.getItem('info')).token,
                        token: this.$store.getters.getUserData.token,
                        conn_id: this.theConnId,
                        data: '',
                        group_id: this.$store.getters.getGroupData.group_id,
                        action: 'group_state'
                    });
                }
            })
        },
    }
};
</script>
<style scoped>
.about{
    background: #F4F5FA;
}
.content{
    padding: 0;
}
.content-box {
    background: #F4F5FA;
}
</style>
