<template>
    <div>
        <div class="main-box-class">
            <div ref="aaa" class="center-vertical">
                <div class="section" v-for="(item, index) in list" :key="index"
                     :style="{'height':index==3?'1000px':'500px'}">
                    <div style="width:100%;height:100%;font-size:30px;text-align:center;font-weight:bold;color:#000000;"
                         :style="{'background':item.backgroundcolor}">
                        {{ item.name }}
                        <br>
                        <span style="font-size: 20px;font-weight: normal" v-html="item.content"></span>
                    </div>
                </div>
            </div>
        </div>

        <div id="nac" style="height:500px;"></div>
        <nav class="nav-box-class">
            <a class="nav1" v-for="(item, index) in list" :key="index" @click="jump(index)"
               :class="index==0?'current':''">{{ item.nameRight }}</a>
        </nav>
    </div>
</template>
<script>
import {videoPlayer} from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

import "video.js/dist/video-js.css";
export default {
    data() {
        return {
            scroll: '',
            list: [{
                name: "视频标题",
                nameRight: "视频",
                content: "<div class='demo'>\n" +
                    "        <video-player\n" +
                    "            class=\"video-player vjs-custom-skin\"\n" +
                    "            ref=\"videoPlayer\"\n" +
                    "            name=\"videoPlayer\"\n" +
                    "            id=\"video\"\n" +
                    "            :playsinline=\"true\"\n" +
                    "            :options=\"playerOptions\"\n" +
                    "            @play=\"onPlayerPlay($event)\"\n" +
                    "            @pause=\"onPlayerPause($event)\"\n" +
                    "            @ended=\"onPlayerEnded($event)\"\n" +
                    "            @waiting=\"onPlayerWaiting($event)\"\n" +
                    "            @timeupdate=\"onPlayerTimeupdate($event)\"\n" +
                    "            @statechanged=\"playerStateChanged($event)\"\n" +
                    "            @canplaythrough=\"onPlayerCanplaythrough($event)\"\n" +
                    "            @ready = \"playerReadied\"\n" +
                    "        ></video-player>\n" +
                    "    </div>",
                backgroundcolor: "#FFFFFF"
            }, {
                name: "图片标题",
                nameRight: "图片",
                content: "图片内容",
                backgroundcolor: "#FFFFFF"
            }, {
                name: "作品标题",
                nameRight: "作品",
                content: "作品内容",
                backgroundcolor: "#FFFFFF"
            }, {
                name: "题目标题",
                nameRight: "题目",
                content: "题目内容",
                backgroundcolor: "#FFFFFF"
            }],
            navList: [1, 2, 3, 4, 5],
            playTime:'',
            current:'',
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src:
                            "https://media.w3.org/2010/05/sintel/trailer_hd.mp4" // url地址
                    }
                ],
                poster: "", // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                },
            },
        }

    },
    methods: {
        dataScroll: function (event) {
            // console.log(123)
            // 获取滚动条离顶部的距离
            let top = event.target.scrollTop;
            this.scroll = top;
            // console.log(top)
            // 滚动条距离底部的距离scrollBottom
            let scrollBottom =
                event.target.scrollHeight -
                event.target.scrollTop -
                event.target.clientHeight;
            // console.log(scrollBottom )
            // this.scroll = document.getElementsByClassName('container')// documentElement.scrollTop || document.body.scrollTop;
            // console.log(document.getElementsByClassName('container')[0].style);
            // console.log(document.body.scrollTop);
        },
        jump(index) {
            let jump = document.getElementsByClassName('section');
            // 获取需要滚动的距离
            let total = jump[index].offsetTop;
            this.$refs.aaa.scrollTo(0, total)
            // Chrome
            document.body.scrollTop = total;
            // Firefox
            document.documentElement.scrollTop = total;
            // Safari
            window.pageYOffset = total;
            // $('html, body').animate({
            // 'scrollTop': total
            // }, 400);
        },
        loadSroll: function () {
            var self = this;
            var $navs = $(".nav1");
            var sections = document.getElementsByClassName('section');
            for (var i = sections.length - 1; i >= 0; i--) {
                if (self.scroll >= sections[i].offsetTop - 200) {
                    console.log($navs.eq(i).addClass("current"));
                    $navs.eq(i).addClass("current").siblings().removeClass("current")
                    break;
                }
            }
        },
        // 播放回调
        onPlayerPlay(player) {
            console.log("player play!", player);
        },

        // 暂停回调
        onPlayerPause(player) {
            console.log("player pause!", player);
        },

        // 视频播完回调
        onPlayerEnded($event) {
            console.log(player);
        },
        // DOM元素上的readyState更改导致播放停止
        onPlayerWaiting(player) {
            let time = localStorage.getItem("cacheTime")
            if(player.cache_.currentTime - Number(time) > 0.1){
                this.current = Number(time)
                this.playerReadied(player)
            }else{
                this.current = player.cache_.currentTime
            }
        },

        // 已开始播放回调
        onPlayerPlaying($event) {
            // console.log(player)
        },

        // 当播放器在当前播放位置下载数据时触发
        onPlayerLoadeddata($event) {
            // console.log(player)
        },

        // // 当前播放位置发生变化时触发。
        onPlayerTimeupdate(player) {
            this.playTime = player.cache_.currentTime
            let playTime = player.cache_.currentTime
            setTimeout(function () {
                localStorage.setItem("cacheTime",playTime)
            },500)


            let time = localStorage.getItem("cacheTime")
            if(player.cache_.currentTime - Number(time) > 2){
                this.current = Number(time)
                this.playerReadied(player)
            }else{
                this.current = player.cache_.currentTime
            }
        },

        //媒体的readyState为HAVE_FUTURE_DATA或更高
        onPlayerCanplay(player) {
            // console.log('player Canplay!', player)
        },

        //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
        onPlayerCanplaythrough(player) {
            // console.log('player Canplaythrough!', player)
        },

        //播放状态改变回调
        playerStateChanged(playerCurrentState) {
            console.log(playerCurrentState.timeupdate)
            if(parseInt(playerCurrentState.timeupdate) === 7){
                this.$refs.videoPlayer.player.pause()
            }
            // this.$refs.videoPlayer.player.pause()
            // console.log('player current update state', playerCurrentState)
        },

        //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
        playerReadied(player) {
            console.log('example player 1 readied', player);
            player.currentTime(this.current)
        }
    },
    watch: {
        scroll: function (a, b) {
            this.loadSroll()
        }
    },
    mounted() {
        // this.$nextTick(() => {
        //     //  浏览器滚动事件不能自己手动加参数。。
        //     window.addEventListener('scroll', this.dataScroll)
        // })
        window.addEventListener('scroll', this.dataScroll, true);  // 监听（绑定）滚轮滚动事件
        // window.addEventListener('scroll', this.dataScroll);
    }
}
</script>

<style lang="less" scoped>
.section {
    height: 500px;
    width: 100%
}

.main-box-class {
    width: 100%;
    height: 100vh;
    background: url(../../assets/img/back-test.png) no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /*margin-top: -15vh;*/
}

.center-vertical::-webkit-scrollbar {
    width: 0 !important
}

.center-vertical {
    -ms-overflow-style: none;
}

.center-vertical {
    overflow: -moz-scrollbars-none;
}

.nav-box-class {
    position: fixed;
    right: 200px;
    top: 30%;
    padding: 30px;
    /*background: pink;*/
}

.center-vertical {
    overflow-y: auto;
    height: 70vh;
    width: 60%;
    margin: 0 auto;
    position: relative;
    top: 12%;
    opacity: 0.9;
    border-radius: 12px;
}

.nav1 {
    display: block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #eee;
    margin: 10px 0;
    border-radius: 50%;
    background: url(../../assets/img/back-test2.png) no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 3px #FFFFFF solid;
;
}

.current {
    background: #e89556;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
}
.video-js.vjs-fluid {
    height: 100%;

    .vjs-tech {
        object-fit: cover;
    }
}

.dark_cover {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
}

</style>
