import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        userData: localStorage.getItem('info') ? JSON.parse(localStorage.getItem('info')) : null,
        groupData: localStorage.getItem('groupData') ? JSON.parse(localStorage.getItem('groupData')) : null,
        courseData: localStorage.getItem('courseData') ? JSON.parse(localStorage.getItem('courseData')) : null,
        unloadGroupData: localStorage.getItem('unloadGroupData') ? JSON.parse(localStorage.getItem('unloadGroupData')) : null,
        gameData: localStorage.getItem('gameData') ? JSON.parse(localStorage.getItem('gameData')) : null,
        canUnlock: localStorage.getItem('canUnlock')? JSON.parse(localStorage.getItem('canUnlock')) : null,
    },
    mutations: {
        setUserData(state, obj) {
            state.userData = obj;
            localStorage.setItem('info', JSON.stringify(obj))
        },
        setGroupData(state, obj) {
            state.groupData = obj;
            localStorage.setItem('groupData', JSON.stringify(obj))
        },
        setCourseData(state, obj) {
            state.courseData = obj;
            localStorage.setItem('courseData', JSON.stringify(obj))
        },
        setUnloadGroupData(state, obj) {
            state.unloadGroupData = obj;
            localStorage.setItem('unloadGroupData', JSON.stringify(obj))
        },
        setGameData(state, obj) {
            state.gameData = obj;
            localStorage.setItem('gameData', JSON.stringify(obj))
        },
        setClearState(state, obj) {
            state.userData = null;
            state.groupData = null;
            state.courseData = null;
            state.unloadGroupData = null;
            state.gameData = null;
        },
        setCanUnlock(state, obj){
            state.canUnlock = obj;
            localStorage.setItem('canUnlock', JSON.stringify(obj))
        }
    },
    getters: {
        getUserData(state) {
            return state.userData;
        },
        getGroupData(state) {
            return state.groupData;
        },
        getCourseData(state) {
            return state.courseData;
        },
        getUnloadGroupData(state) {
            return state.unloadGroupData;
        },
        getGameData(state) {
            return state.gameData;
        },
        getCanUnlock(state) {
            return state.canUnlock;
        }
    },
    actions: {},
    modules: {
    },
})
