import BaseModel from "../common/model/BaseModel";
import BaseCall from "../common/model/BaseCall";

export default class Resources extends BaseModel {
    constructor() {
        super();

    }

    static SidebarData(role = 1) {
        let data;
        if (role === 1) {
            data =
                [
                    //type 1 选中   type 0 未选中   status 2 学生
                    {
                        svg_icon: 'video-icon',
                        icon: './img/icon/video-icon.png',
                        text: '课程',
                        cut_icon: './img/icon/video-cut-icon.png',
                        type: 0,
                        url: 'VideoList'
                    },
                    {
                        svg_icon: 'work-icon',
                        icon: './img/icon/work-icon.png',
                        text: '作品库',
                        cut_icon: './img/icon/work-cut-icon.png',
                        type: 0,
                        url: 'studentWorks',
                        top_menu: [{text: '作品集合', type: 0, url: 'WorksList'}, {text: '作品展示', type: 0, url: 'WorksShow'}]
                    },
                    {
                        svg_icon: 'student-manage-icon',
                        icon: './img/icon/student-manage-icon.png',
                        text: '学生管理',
                        cut_icon: './img/icon/student-manage-cut-icon.png',
                        type: 0,
                        url: 'StudentManage',
                        top_menu: [{text: '学生管理', type: 0, url: 'StudentManage'}]
                    },
                    {
                        svg_icon: 'class-manage-icon',
                        icon: './img/icon/class-manage-icon.png',
                        text: '课程管理',
                        cut_icon: './img/icon/class-manage-cut-icon.png',
                        type: 0,
                        url: 'UnlockClass',
                        top_menu: [{text: '解锁课程', type: 0, url: 'UnlockClass'}]
                    },
                    {
                        svg_icon: 'tools-icon',
                        icon: './img/icon/tools-icon.png',
                        text: '创作工具',
                        cut_icon: './img/icon/tools-cut-icon.png',
                        type: 0,
                        url: 'Tool',
                        top_menu: [{text: '创作工具', type: 0, url: 'Tool'}]
                    },
                    {
                        svg_icon: 'small-tools-icon',
                        icon: './img/icon/small-tool-icon.png',
                        text: '益智小工具',
                        cut_icon: './img/icon/small-tool-icon.png',
                        type: 0,
                        url: 'ToolGame',
                        top_menu: [{text: '来开动脑筋吧', type: 0, url: 'ToolGame'}]
                    },
                    {
                        svg_icon: 'answer-icon',
                        icon: './img/icon/answer-icon.png',
                        text: '1对1解答',
                        cut_icon: './img/icon/answer-cut-icon.png',
                        type: 0,
                        url: 'ExplainStaff',
                        top_menu: [{text: '您的专属服务', type: 0, url: 'ExplainStaff'}]
                    }
                ]
        } else if (role === 2) {
            data =
                [
                    //type 1 选中   type 0 未选中   status 2 学生
                    {
                        svg_icon: 'video-icon',
                        icon: './img/video-icon.png',
                        text: '课程',
                        cut_icon: './img/cut-video-icon.png',
                        type: 0,
                        url: 'studentVideoList'
                    },
                    {
                        svg_icon: 'work-icon',
                        icon: './img/works-icon.png',
                        text: '作品库',
                        cut_icon: './img/cut-works-icon.png',
                        type: 0,
                        url: 'singleStudentWorks',
                        top_menu: [{text: '小小创作家', type: 0, url: 'studentWorksList'}, {
                            text: '上传作品',
                            type: 0,
                            url: 'studentWorksUpload'
                        }]
                    },
                    {
                        svg_icon: 'tools-icon',
                        icon: './img/creation-icon.png',
                        text: '创作工具',
                        cut_icon: './img/cut-creation-icon.png',
                        type: 0,
                        url: 'Tool',
                        top_menu: [{text: '创作工具', type: 0, url: 'Tool'}]
                    },
                    {
                        svg_icon: 'small-tools-icon',
                        icon: './img/game-icon.png',
                        text: '益智小工具',
                        cut_icon: './img/cut-game-icon.png',
                        type: 0,
                        url: 'ToolGame',
                        top_menu: [{text: '来开动脑筋吧', type: 0, url: 'ToolGame'}]
                    }
                ]
        }
        return data;
    }

    static selectMenuData(role = 1) {
        let data;
        if (role === 1) {
            data = [
                // {
                //     id: 1,
                //     name: '个人中心',
                //     url: 'PersonalCenter'
                // },
                {
                    id: 2,
                    name: '帮助',
                    url: 'SystemHelp'
                },
                {
                    id: 3,
                    name: '修改密码',
                    url: ''
                },
                {
                    id: 4,
                    name: '下载',
                    url: 'ResourceDown'
                },
                {
                    id: 5,
                    name: '退出',
                    url: ''
                }
            ]
        }else if(role === 2){
            data = [
                // {
                //     id: 1,
                //     name: '个人中心',
                //     url: 'PersonalCenter'
                // },
                {
                    id: 3,
                    name: '修改密码',
                    url: ''
                },
                {
                    id: 4,
                    name: '下载',
                    url: 'ResourceDown'
                },
                {
                    id: 5,
                    name: '退出',
                    url: ''
                }
            ]
        }
        return data;
    }
}
