<template>
    <div class="header-class">
        <div class="header-box-class">
            <div class="header-left">
                <div class="group-icon-class">
                    <el-image class="group-img-class" :src="groupIcon"
                              fit="cover">
                    </el-image>
                    <div class="group-name-class">
                        <span class="text-class">班级：</span>
                        <!--                        <span @click="selectGroupCLick()">-->
                        <div class="select-group-class" @click="selectGroupCLick()" ref="treeWrap1">
                            <div class="name-class">
                                {{ group_name }}
                            </div>
                            <div class="point-back-class">
                                <i v-if="groupIconState === false" class="iconfont icon-point"></i>
                                <i v-else class="iconfont icon-point-2 active-icon-color"></i>
                                <SelectAlert class="group-class" v-if="selectGroupState" :Data="selectGroupData"
                                             @returnData="emitData" type="2"></SelectAlert>
                                <!--                                <SelectAlertX lass="group-class" v-if="selectGroupState" :Data="selectGroupData"-->
                                <!--                                              @returnData="emitData" type="2"></SelectAlertX>-->
                            </div>
                        </div>

                        <!--                        </span>-->
                    </div>
                </div>
                <div class="lesson-title-class">
                    <div>{{ class_name }}</div>
                </div>
            </div>
            <div class="header-center"></div>
            <div class="header-right">
                <div class="bureau-class">
                    <el-image class="bureau-img-class" :src="homeBasisData.bureau.bureau_avatar"
                              fit="cover">
                    </el-image>
                </div>
                <div class="point-back-class" ref="treeWrap2" @click="selectMenuCLick">
                    <i v-if="menuIconState === false" class="iconfont icon-point"></i>
                    <i v-else class="iconfont icon-point-2 active-icon-color"></i>
                    <SelectAlert class="SelectAlert-class" v-if="selectMenuState" :Data="selectMenuData"
                                 @returnData="emitData"></SelectAlert>
                </div>
            </div>
        </div>

        <div v-show="savePassStatus">
            <SavePassword @alertPassword="alertPasswordStatus"></SavePassword>
        </div>
    </div>
</template>

<script>
import SelectAlert from '../../components/selector/SelectAlert';
import TeacherUser from "../../models/Home/TeacherUser";
import websocket from "../../utils/websocket";
import Resources from "../../models/Resources";
import SavePassword from "../common/SavePassword";
import eventVue from '../../assets/js/event.js'
import UnloadClass from "../../models/Home/UnloadClass";
import SelectAlertX from "@/components/selector/SelectAlertX";

export default {
    props: ['homeClassData', 'homeCourseData', 'homeBasisData'],
    name: "Header",
    components: {
        SelectAlert,
        SavePassword,
        SelectAlertX
    },
    data() {
        return {
            groupIcon: './img/figure.png',
            groupIconState: false,
            menuIconState: false,
            pointIcon: './img/icon/point-icon.png',
            bureauIcon: './img/works-3.png',
            group_name: '选择班级',
            class_name: '',
            selectGroupState: false,
            selectMenuState: false,
            selectGroupData: { type: 'group-select', data: [] },
            selectMenuData: {
                type: 'menu-select',
                data: Resources.selectMenuData(this.$store.getters.getUserData.role)
            },
            savePassStatus: false,
            flag: false,
            flag2: false
        }
    },
    created() {
        // console.log(this.homeBasisData);
        // if(this.homeBasisData.bureau === undefined){
        //     console.log(this.homeBasisData);
        //     this.homeBasisData.bureau.bureau_avatar = '';
        // }
        // homeClassData
        const _self = this;
        this.homeClassData.forEach(item => {
            _self.selectGroupData.data.push({ id: item.group_id, name: item.group_name, phone: item.phone });
        })

        //赋值 第一次
        if (this.$store.getters.getGroupData === null) {
            if (this.homeClassData.length !== 0) {
                this.group_name = this.homeClassData[0].group_name;
            }
        } else {
            this.group_name = this.$store.getters.getGroupData.group_name;
        }
        this.class_name = this.$store.getters.getCourseData[0].title;
        // this.selectGroupState = false

        //缓存区分班级课程类型
        if (this.$store.getters.getUserData.role === 2) {
            _self.listUnlockedLessonNumInfo();
            _self.canUnlockState();
            setInterval(function () {
                _self.listUnlockedLessonNumInfo();
                _self.canUnlockState();
            }, 300000) //60000 改成了5分钟
        }
    },
    mounted() {
        let _this = this;
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap1;
            if (tree && _this.flag2 === false) {
                if (!tree.contains(e.target)) {
                    _this.closeSelectAlert()
                }
            }
        })
        document.addEventListener('click', (e) => {
            let tree = this.$refs.treeWrap2;
            if (tree && _this.flag === false) {
                if (!tree.contains(e.target)) {
                    _this.closeSelectAlert()
                }
            }
        })
    },
    methods: {
        //左侧班级
        selectGroupCLick() {
            this.selectGroupState = this.selectGroupState === false;
            this.groupIconState = this.groupIconState === false;
            // this.groupIconState = this.groupIconState === false
            this.flag = true;
        },
        //右侧菜单
        selectMenuCLick() {
            this.selectMenuState = this.selectMenuState === false;
            this.menuIconState = this.menuIconState === false;
            this.flag2 = true;
        },

        emitData(res) {
            console.log(res)
            //班级选择
            if (res.type === 'group-select') {
                // 传的值是固定的 {id:'',name:''}
                const command = { group_id: res.data.id, group_name: res.data.name, group_phone: res.data.phone }
                this.handleCommand(command)
            }
            // 右侧菜单选择
            if (res.type === 'menu-select') {
                this.handleRightMenu(res.data)
            }
        },
        //y切换右侧菜单
        handleRightMenu(command) {
            if (command.id === 3 && command.name === '修改密码') {
                //修改密码
                this.savePassStatus = true;
            } else if (command.id === 5 && command.name === '退出') {
                //退出
                this.exitClick();
            } else {
                this.$router.push({ path: command.url })
            }
        },
        //切换班级
        handleCommand(command) {
            this.$store.commit('setGroupData', command);
            this.group_name = command.group_name;
            console.log(this.$store.getters.getCourseData);

            this.selectGroupState = true;//关闭弹窗
            this.groupIconState = true;
            this.homeCourseList(3);
            let socketWs = websocket.getWebSocket();
            socketWs.close()
        },
        //关闭修改密码弹窗
        alertPasswordStatus() {
            this.savePassStatus = false;
        },
        //首页课程标题
        homeCourseList(cut = 1) {
            const params = {
                group_id: this.$store.getters.getGroupData.group_id,
            }
            TeacherUser.getCourseList(params, ({ type, list }) => {
                if (type === 200) {
                    this.$store.commit('setCourseData', list.list);
                    this.class_name = this.$store.getters.getCourseData[0].title;
                    if (cut === 3) {
                        eventVue.$emit('courseState', false);
                    }
                }
            })
        },
        //退出按钮
        exitClick() {
            this.$Dialog.confirm(
                {
                    message: '<span style="font-size: 16px">确定退出吗？</span>',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    localStorage.clear();
                    var cookies = document.cookie.split(";");
                    cookies.forEach(item => {
                        var eqPos = item.indexOf("=");
                        console.log(eqPos);
                        var name = eqPos > -1 ? item.substr(0, eqPos) : item;
                        console.log(name);
                        this.vueSetCookie(name, '', -1)
                    })

                    this.isRouterAlive = false
                    this.$nextTick(function () {
                        this.isRouterAlive = true
                    })
                    this.$router.push({
                        path: '/',
                        name: 'Login',
                        params: {}
                    })
                    window.location.reload()
                })
                .catch(() => {
                    // on cancel
                });

        },
        vueSetCookie(c_name, value, expire) {
            var date = new Date()
            date.setSeconds(date.getSeconds() + expire)
            document.cookie = c_name + "=" + escape(value) + "; expires=" + date.toGMTString()
            //console.log(document.cookie)
        },
        closeSelectAlert() {
            //左侧弹窗
            this.selectGroupState = false;
            this.groupIconState = false; //按钮箭头
            // console.log(this.selectGroupState);
            this.flag = false;
            this.flag2 = false;
            //右侧弹窗
            this.selectMenuState = false;
            this.menuIconState = false; //按钮箭头
        },
        //添加缓存 只有学生端的时候
        async listUnlockedLessonNumInfo() {
            let _this = this;
            UnloadClass.getListUnlockedLessonNum({}, ({ type, list }) => {
                if (type === 200) {
                    _this.$store.commit('setUnloadGroupData', list.list.lesson_num)
                }
            })
        },
        //设置时间
        canUnlockState(){
            UnloadClass.canUnlock({}, ({ type, list }) => {
                if (type === 200) {
                    if (list !== 0) {
                        this.$store.commit('setCanUnlock', list)
                    } else {
                        this.$store.commit('setCanUnlock', list)
                    }
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.header-class {
    width: 100%;
    height: 140px;
    background: #FFFFFF;
    border-radius: 0 0 10px 10px;

    .header-box-class {
        display: flex;

        .header-left {
            flex-grow: 0.5;
            height: 140px;
            width: 35px;
            //background-color: #42b983;
            .group-icon-class {
                display: flex;
                line-height: 40px;
                margin-top: 40px;
                margin-left: 100px;

                .group-img-class {
                    width: 40px;
                    height: 40px;
                }

                .group-name-class {
                    margin-left: 10px;
                    display: flex;

                    .text-class {
                        font-size: 20px;
                        color: #333333;
                        font-weight: bold;
                    }

                    .select-group-class {
                        cursor: pointer;
                        display: flex;
                    }

                    .select-group-class:hover {
                        .point-back-class {
                            .icon-point {
                                color: #FF8B0C;
                            }
                        }
                    }

                    .name-class {
                        font-size: 16px;
                        color: #666666;
                        font-weight: bold;
                        padding: 0 10px;
                    }


                    .point-back-class {
                        width: 10px;
                        height: 10px;
                        position: relative;
                        color: #949494;

                        /deep/ .select-alert-class li {
                            text-align: left !important;
                            padding: 0 8px;
                        }

                        /deep/ .main-class {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                            //white-space: unset;
                        }

                        .iconfont {
                            font-size: 10px;
                        }

                        .group-class {
                            //width: 150px
                        }
                    }

                    .icon-point:hover {
                        cursor: pointer;
                        color: #FF8D0D;
                    }
                }

            }

            .lesson-title-class {
                margin-left: 150px;
                color: #666666;
                font-weight: bold;
            }
        }

        .header-center {
            flex-grow: 1;
            height: 140px;
            width: 35px;
            //background-color: #bcbec2;
            color: #ffffff;
            text-align: center;
        }

        .header-right {
            flex: 0.2;
            height: 140px;
            width: 35px;
            color: #ffffff;
            display: flex;

            .bureau-class {
                text-align: right;
                width: 60px;
                height: 60px;
                border-radius: 10px;
                margin-top: 40px;

                .bureau-img-class {
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                }
            }

            .point-back-class {
                margin-top: 60px;
                width: 20px;
                height: 20px;
                margin-left: 10px;
                color: #949494;
                position: relative;

                .iconfont {
                    font-size: 10px;
                }

                .SelectAlert-class {
                    margin-top: 20px;
                    width: 130px;
                    font-size: 16px;
                }
            }

            .icon-point:hover {
                cursor: pointer;
                color: #FF8D0D;
            }
        }
    }

    .active-icon-color {
        color: #FF8D0D;
    }
}
</style>
