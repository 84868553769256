<template>
    <div class="list-class">
        <div class="list-box-class">
            <div class="table-class">
                <el-table
                    :data="worksList.list"
                    style="width: 100%"
                    :header-cell-style="setSellStyle"
                >
                    <el-table-column
                        label="作品信息"
                        width="180">
                        <template slot-scope="scope">
                                <span v-if="scope.row.class_name === '课后作业'">
                                    <el-image class="code-but-class" :src="worksImg1" fit="cover"
                                              style="width: 90%;height:90%;cursor: pointer;"></el-image>
                                </span>
                            <span v-else-if="scope.row.class_name === '课堂练习'">
                                    <el-image class="code-but-class" :src="worksImg2" fit="cover"
                                              style="width: 90%;height:90%;cursor: pointer;"></el-image>
                                </span>
                            <span v-else-if="scope.row.class_name === '自由编程'">
                                    <el-image class="code-but-class" :src="worksImg3" fit="cover"
                                              style="width: 90%;height:90%;cursor: pointer;"></el-image>
                                </span>
                            <!--                                -->
                            <!--                                <el-image v-if-else="scope.row.class_name === '课堂练习'" class="code-but-class" :src="worksImg2" fit="cover"-->
                            <!--                                          style="width: 90%;height:90%;cursor: pointer;"></el-image>-->
                            <!--                                <el-image v-if-else="scope.row.class_name === '自由编程'" class="code-but-class" :src="worksImg3" fit="cover"-->
                            <!--                                          style="width: 90%;height:90%;cursor: pointer;"></el-image>-->
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="class_name"
                        width="180">
                        <template slot="header" slot-scope="{}">
                            <div class="type_export">
                                {{ typeName }}<i class='el-icon-caret-bottom'></i>
                                <el-select v-model="typeName" @change="typeClick($event)" placeholder="请选择">
                                    <el-option v-for="item in typeOptions" :key="item.class_id"
                                               :label="item.class_name"
                                               :value="item"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="nickname"
                        label="姓名">
                        <template slot="header" slot-scope="{}">
                            <div class="type_export">
                                {{ studentName }}<i class='el-icon-caret-bottom'></i>
                                <el-select v-model="studentName" @change="studentClick" placeholder="请选择">
                                    <el-option v-for="item in studentOptions" :key="item.id"
                                               :label="item.name"
                                               :value="item"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="lesson_name"
                        label="课时名称">
                        <template slot-scope="scope">
                            <div v-html="scope.row.lesson_name"></div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="work_name"
                        label="作品名">
                    </el-table-column>
                    <el-table-column
                        prop="group_name"
                        label="班级">
                    </el-table-column>
                    <el-table-column
                        label="最近发布时间">
                        <template slot="header" slot-scope="{}">
                            <div class="month_export">
                                {{ timeName }}
                                <i class='el-icon-caret-bottom'></i>
                                <el-date-picker
                                    v-model="timeVal"
                                    type="date"
                                    @change="batchWithdrawalCommission"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                </el-date-picker>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            {{ Number(scope.row.issue_time) * 1000 | formatDate3 }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="time"
                        label="分享 | 评语 | 查看作品">

                        <template slot-scope="scope">

                            <el-popover
                                placement="bottom"
                                width="322"
                                trigger="click">
                                <div style="padding: 10px;">
                                    <div
                                        :style="{backgroundImage:'url(' + shareBack + ')',backgroundRepeat:'no-repeat',cursor: 'pointer'}"
                                        style="background-size: 100% 100%;height: 40vh;">
                                        <div
                                            style="width:50px;height: 50px;border-radius: 50%;background: #FFFFFF;float: left;margin:16px 10px">
                                            <el-image round class="code-but-class" :src="qrData.bureau_avatar_url"
                                                      fit="cover"
                                                      style="width: 50px;height:50px;cursor: pointer;border-radius: 50%"></el-image>
                                        </div>
                                        <div style="width:calc(100% - 110px);float: left;margin:16px;color:#FFFFFF">
                                            {{ qrData.nickname }}，在{{ qrData.bureau_name }}学习果宝编程课程。
                                        </div>
                                    </div>

                                    <el-row>
                                        <el-col :span="16" style="margin-top: 20px">
                                            <div
                                                style="padding: 10px 0;font-size: 18px;color: #FF8D15;font-weight: bold">
                                                {{ qrData.bureau_name }}
                                            </div>
                                            <div style="color: #FF8D15;font-weight: bold;padding-bottom: 10px">
                                                扫右边二维码看我的作品
                                            </div>
                                            <div style="padding-top: 10px;">
                                                <span><i class="el-icon-phone-outline"></i></span>
                                                &nbsp;
                                                <span>{{ qrData.bureau_phone }}</span>
                                            </div>
<!--                                            <div style="padding: 10px 0;">-->
<!--                                                <span><i class="el-icon-map-location"></i></span>-->
<!--                                                &nbsp;-->
<!--                                                <span style="-->
<!--                                                    width: 86%;-->
<!--                                                    display: inline-block;-->
<!--                                                    line-height: 12px;-->
<!--                                                overflow: hidden;-->
<!--                                                white-space: nowrap;-->
<!--                                                text-overflow:ellipsis;">{{ qrData.bureau_location }}</span>-->
<!--                                            </div>-->
                                        </el-col>
                                        <el-col :span="8">
                                            <div style="margin-top:20px">
                                                <el-image slot="reference" class="code-but-class"
                                                          :src="qrData.qr_code"
                                                          fit="cover"
                                                          style="width: 100%;height:100%;"></el-image>
                                            </div>
<!--                                            <div @click="downQrImg(qrData.qr_code)" style="cursor: pointer">下载二维码-->
<!--                                            </div>-->
                                        </el-col>
                                        <el-col :span="24">
                                            <div style="padding: 10px 0;">
                                                <span><i class="el-icon-map-location"></i></span>
                                                &nbsp;
                                                <span>{{ qrData.bureau_location }}</span>
                                            </div>
                                        </el-col>
                                    </el-row>


                                </div>

                                <el-image slot="reference" class="code-but-class" :src="shareIconImg" fit="cover"
                                          style="width: 20px;height:20px;cursor: pointer;"
                                          @click="shareQrClick(scope.row.work_id)"></el-image>
                            </el-popover>
                            &nbsp;&nbsp;&nbsp;&nbsp;

                            <el-popover
                                :ref="'popover-' + scope.row.work_id"
                                placement="left"
                                width="500"
                                trigger="click"
                                @show="showStudentWorkList(scope.row)"
                                @hide="hideStudentWorkList(scope.row)">
                                <div class="leave-class" style="padding: 16px;">
                                    <div>
                                        <p>选择使用过的照片/视频 <span @click="usedPhotoBox.status = !usedPhotoBox.status;"
                                                             style="cursor:pointer;margin-left:10px;">{{ usedPhotoBox.status ? '收起' : '展开' }}</span>
                                        </p>
                                        <el-row :gutter="10" style="overflow-y: auto;"
                                                :style="{maxHeight:usedPhotoBox.status?'200px':'0px'}">
                                            <el-col :span="6" v-for="(item,index) in myUploadFileList" :key="index">
                                                <div
                                                    style="border-radius: 12px;border: 1px solid #ddd;cursor: pointer;margin-bottom: 5px;"
                                                    @click="clickBufferPhoto(item)"
                                                >
                                                    <img
                                                        v-if="item.type === 'image'"
                                                        :src="item.relativeUrl"
                                                        :alt="item.name"
                                                        style="width:100%;"
                                                    >
                                                    <img style="width:100%;" v-else src="../../assets/img/video.jpg"
                                                         :alt="item.name">
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>

                                    <!-- action="/resource/v1/uploadFile"
                                    action="https://jsonplaceholder.typicode.com/posts/" -->
                                    <el-upload
                                        class="upload-demo"
                                        :headers="headerObj"
                                        :action="uploadUrl"
                                        :on-preview="studentWorkPreview"
                                        :on-remove="studentWorkRemove"
                                        :file-list="studentWorkfileList"
                                        :before-upload="studentWorkBeforeUpload"
                                        :on-success="studentWorkSuccess"
                                        list-type="picture">
                                        <el-button size="small" type="primary">上传新照片/视频</el-button>
                                        <!-- <template #tip>
                                            <div class="el-upload__tip">
                                            只能上传 jpg/png 文件，且不超过 500kb
                                            </div>
                                        </template> -->
                                    </el-upload>
                                    <p>填写评价</p>
                                    <el-input
                                        type="textarea"
                                        :rows="5"
                                        placeholder="学生的表现评价"
                                        :ref="'goods_'+scope.row.work_id"
                                        v-model="scope.row.teacher_comment">
                                    </el-input>

                                    <div style="text-align: center;margin-top:10px">
                                        <button style="padding: 6px 20px;color:#FFFFFF;background: #FF8D15;
                                        border: none;border-radius: 6px" @click="leaveClick(scope.row)">确定
                                        </button>
                                    </div>
                                </div>

                                <el-image slot="reference" class="code-but-class" :src="leaveIconImg" fit="cover"
                                          style="width: 20px;height:20px;cursor: pointer;"
                                          @click="leaveAlertClick(scope.row.work_id)"
                                ></el-image>
                            </el-popover>

                            &nbsp;
                            <span style="font-size: 24px;color: #fc9c38;cursor: pointer">
                                    <span
                                        style="font-size: 24px;color: #FF8D15;cursor: pointer;position: relative;top:2px">
                                <el-image slot="reference" class="code-but-class" :src="checkIconImg" fit="cover"
                                          style="width: 26px;height:26px;cursor: pointer;"
                                          @click="downClick(scope.row.work_id)"></el-image>
                                    </span>
                                <!--                                <i @click="downClick(scope.row.work_url)" class="el-icon-download"></i>-->
                                </span>

                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="block" style="text-align: center">
                <el-pagination background layout="prev, pager, next,jumper, total"
                               :total="worksList.count" @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page.sync="currentPage3">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import eventVue from '../../assets/js/event.js'
import Works from "../../models/Home/Works";
import Student from "../../models/Home/Student";
import {formatDate} from "../../assets/js/date.js";
import LessonVideo from "../../models/Home/LessonVideo";
import Api from "../../models/Home/Api";
import Qs from 'qs';
import axios from "axios";

export default {
    name: "WorksList",
    data() {
        return {
            checkIconImg: './img/check-icon.png',
            shareBack: './img/share-back2.png',
            worksImg1: './img/works-1.png',
            worksImg2: './img/works-2.png',
            worksImg3: './img/works-3.png',
            qrData: '',
            leaveStatus: true,
            textarea: '',
            shareIconImg: './img/share-icon.png',
            leaveIconImg: './img/leave-icon.png',
            typeOptions: [],
            studentOptions: [],
            timeVal: '',
            studentName: '姓名',
            typeName: '类别',
            timeName: '最近发布时间',
            currentPage3: 1,
            worksList: {},
            page: 1,
            class_id: 0,
            student_id: 0,
            issue_time: 0,


            studentWorkfileList: [
                // {id:9 ,name:'food.jpg',url:'https://storage.shubenji.cn/GuoBao/resource/f8f5795ed072d361.jpg?OSSAccessKeyId=LTAI4Fdpcm5Gwue9tEaZfiGS&Expires=1630306430&Signature=JSu7lcuH6Sd0R3zUXSW0E7YdH2Y%3D',type:'video'},
            ],
            headerObj: {
                // token: JSON.parse(localStorage.getItem('info')).token
            },

            // =============
            usedPhotoBox: {
                status: true,
            },
            myUploadFileList: '',
            uploadUrl:'/resource/v1/uploadFile?token='+JSON.parse(localStorage.getItem('info')).token
        }
    },
    created() {
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }
        console.log(this.$route.query.student_name)
        if (this.$route.query.student_name !== undefined && this.$route.query.student_id !== undefined) {
            this.studentName = this.$route.query.student_name;
            this.student_id = this.$route.query.student_id;
        }

        this.getWorksShowInfo();
        this.getWorksTypeInfo();
        this.getStudentInfo();

        console.log(this.myUploadFileList);
        if (this.myUploadFileList !== '') {
            this.myUploadFileList.forEach(item => {
                Api.fetchData({
                        url: item.url
                    },
                    '/resource/v1/getResourceUrl', ({type, list}) => {
                        item.relativeUrl = list
                    })
            });
        }
    },
    computed: {
        CourseData() {//监听store的数据
            return this.$store.getters.getCourseData
        }
    },
    watch: {
        CourseData() {
            this.getWorksShowInfo();
            this.getWorksTypeInfo();
            this.getStudentInfo();
            this.myUploadFileList.forEach(item => {
                Api.fetchData({
                        url: item.url
                    },
                    '/resource/v1/getResourceUrl', ({type, list}) => {
                        item.relativeUrl = list
                    })
            });
        }
    },
    methods: {
        //学生作品点击文件列表中已上传文件时的钩子
        studentWorkPreview(info) {
            Api.fetchData({
                    works_id: row.work_id
                },
                '/bureau/v1.2/listWorksResource', ({type, list}) => {
                    list.forEach((item, index) => {
                        this.clickBufferPhoto({
                            id: item.resource_id,
                            name: (item.type === 'image' ? '照片' : '视频') + (index + 1),
                            relativeUrl: item.url,
                            type: item.type
                        });
                    });
                })
        },
        // 学生作品图片或视频移除时的钩子
        studentWorkRemove(info) {
            var rec_id = info.rec_id ? info.rec_id : 0;
            if (rec_id !== 0) {
                Api.fetchData({
                        rec_id: rec_id
                    },
                    '/bureau/v1.2/unbindWorksResource', ({type, list}) => {
                        this.$message({
                            message: '解绑成功',
                            type: 'success',
                            iconClass: 'iconClass',
                        });
                    })
            }
            this.studentWorkfileList.forEach((item, index) => {
                if (item.id === info.id) this.studentWorkfileList.splice(index, 1);
            })
        },
        // 学生作品图片或视频上传前的钩子
        studentWorkBeforeUpload(file) {
            console.log(file);
            const isLt2M = file.size / 1024 / 1024 < 50;

            if (!isLt2M) {
                // this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        //上传文件成功时的钩子
        studentWorkSuccess(res) {
            console.log(res);
            var data = res.data,
                urlArr = data.url.split('/'),
                name = urlArr[urlArr.length - 1],
                video = ['mp4'],
                // img = ['png','PNG','jpg','JPG','gif','GIF'],
                suf = name.split('.')[1],
                type = video.indexOf(suf) === 0 ? 'video' : 'image';

            if (this.myUploadFileList === '') {
                this.myUploadFileList = [];
            }
            this.myUploadFileList.push({
                id: data.id,
                relativeUrl: data.relativeUrl,
                url: data.url,
                name: name,
                type: type
            });
            // this.myUploadFileList
            this.clickBufferPhoto({
                id: data.id,
                name: name,
                relativeUrl: data.relativeUrl,
                type: type,
            });
            localStorage.setItem('myUploadFile', JSON.stringify(this.myUploadFileList));
        },
        // 点击缓冲的图片时
        clickBufferPhoto(row) {
            var n = 0;
            this.studentWorkfileList.forEach(item => {
                if (item.id === row.id) {
                    this.$message({
                        message: '已经有添加过了',
                        type: 'warning',
                        iconClass: 'iconClass',
                    });
                    n++;
                }
            });
            if (n === 0) {
                let url = '';
                if (row.type === 'video') {
                    url = './img/teacher-msg.jpg';
                } else {
                    url = row.relativeUrl;
                }
                this.studentWorkfileList.push({
                    id: row.id,
                    name: row.name,
                    url: url,
                    type: row.type,
                    rec_id: row.rec_id ? row.rec_id : 0
                });
            }

        },
        // 显示作品文件框框时触发
        showStudentWorkList(row) {
            this.studentWorkfileList = [];
            Api.fetchData({
                    works_id: row.work_id
                },
                '/bureau/v1.2/listWorksResource', ({type, list}) => {
                    list.forEach((item, index) => {
                        this.clickBufferPhoto({
                            id: item.resource_id,
                            name: (item.type === 'image' ? '照片' : '视频') + (index + 1),
                            relativeUrl: item.url,
                            type: item.type,
                            rec_id: item.rec_id
                        });
                    });
                })
        },
        hideStudentWorkList() {
        },


        downQrImg(imgData) {
            // let imgData ='data:image/png;base64,'+this.initdata.participantQRCode
            //let imgData ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWgAAAFoAQAAAABSnlx4AAABe0lEQVR42u3aS5KEIAyA4bjyGBxVjsoxXMk0JpC22rJndmTqd2G17ecqPEJA6l8uQaPRaDQajUZH0YfYtdaaU3tMu8hW+t8bOpDW57K2Xw2et7Je3qKD6BblrWkNdZFFP361BnRM/erF0uBoCOiw2t7ktC/oqNpD3WBOvxy/0fPpnikdFn69fcur0PNpv1rkNdQj3g/rHfR82vputfzoXMa0Tj1yJnQoXfvixT+xnOlmREZPq9ubtWrk7dcZ/rEuRcfSvSu/fyyXYRkdQlu6tNrEKt4Q0n6bKaGn1RrqqlOs57/WJNBx9DmxdpjqR3kBHUdbVqS5ri1OF9V5jNLoEHoMwWNXZdTk9QU6jH6fYq0DV59nj4cqEXo2fan4VS8q+OYnOo7ulfiRGqk57vs8emrtu52pF42sCH8zu6IjaAt1lpH/lofIo2fWti41vcv3Mwvo6bSf9Cpdb76/gg6kPVPSUwhaJTp3VR7zKvRsmvPfaDQajUaj0f9G/wAuF0qW7lRCOwAAAABJRU5ErkJggg==";//这里放需要下载的base64
            this.downloadFile('二维码.png', imgData);
        },
        //下载
        downloadFile(fileName, content) {
            let aLink = document.createElement('a');
            let blob = this.base64ToBlob(content); //new Blob([content]);

            let evt = document.createEvent("HTMLEvents");
            // console.log("点击下载", evt)
            evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            aLink.download = fileName;
            aLink.href = URL.createObjectURL(blob);

            // aLink.dispatchEvent(evt);
            aLink.click()
        },
        //base64转blob
        base64ToBlob(code) {
            let parts = code.split(';base64,');
            let contentType = parts[0].split(':')[1];
            let raw = window.atob(parts[1]);
            let rawLength = raw.length;

            let uInt8Array = new Uint8Array(rawLength);

            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], {type: contentType});
        },
        downClick(workId) {
            window.open("/gbscratch/?workId=" + workId);
            // const aTag = document.createElement('a')
            // aTag.href = workUrl //文件id
            // aTag.click()
        },
        ListClickShow() {

        },
        leaveAlertClick() {
            this.textarea = null;
        },
        //学生管理列表
        getStudentInfo() {
            let param = {
                group_id: this.$store.getters.getGroupData.group_id,
                page: 1,
                limit: 500,
            }
            Student.getStudentList(param, ({type, list}) => {
                if (type === 200) {
                    this.studentOptions = list.list;
                }
            })
        },
        getWorksTypeInfo() {
            Works.getWorksType({}, ({type, list}) => {
                if (type === 200) {
                    this.typeOptions = list.list;
                }
            })
        },
        //列表信息
        getWorksShowInfo(type = 1, studentData = null) {
            if (type === 2) {
                this.class_id = 0;
                this.student_id = 0;
                this.issue_time = 0;
                this.timeVal = '';
                this.studentName = '学生姓名';
                this.typeName = '类别';
                this.timeName = '最近发布时间';
            }
            if (type === 3 && studentData !== null) {
                this.class_id = 0;
                this.student_id = studentData.student_id;
                this.issue_time = 0;
                this.timeVal = '';
                this.studentName = studentData.student_name;
                this.typeName = '类别';
                this.timeName = '最近发布时间';
            }
            let param;
            param = {
                class_id: this.class_id,
                student_id: this.student_id,
                issue_time: this.issue_time,
                group_id: this.$store.getters.getGroupData.group_id,
                page: this.page,
                limit: 10,
            }


            Works.getWorksShow(param, ({type, list}) => {
                if (type === 200) {
                    this.worksList = list;
                }
            })

        },
        //生成二维码中
        shareQrClick(work_id) {
            let param = {
                work_id: work_id,
            }
            Works.getWorksQrCode(param, ({type, list}) => {
                if (type === 200) {
                    this.qrData = list;
                }
            })
        },
        //留言按钮
        leaveClick(row) {
            this.$Dialog.confirm(
                {
                    message: '确定提交吗？',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    const request = Qs.stringify({
                        work_id: row.work_id,
                        content: row.teacher_comment,
                    });

                    this.studentWorkfileList.forEach(item => {
                        if (item.rec_id === 0) {
                            Api.fetchData(
                                {
                                    works_id: row.work_id,
                                    resource_id: item.id,
                                    type: item.type
                                },
                                '/bureau/v1.2/bindWorksResource',
                                ({type, list}) => {
                                })
                        }
                    });

                    Works.saveEvaluateWork(request, ({type, list}) => {
                        if (type === 200) {
                            this.$refs[`popover-` + row.work_id].doClose()
                        }
                    });

                })
                .catch(() => {
                    // on cancel
                });
        },
        batchWithdrawalCommission(val) {
            this.timeVal = val;
            this.timeName = val;
            this.issue_time = new Date(val).getTime() / 1000;
            this.page = 1;
            this.getWorksShowInfo()
        },
        //类别点击
        typeClick(data) {
            this.typeName = data.class_name;
            this.class_id = data.class_id;
            this.page = 1;
            this.getWorksShowInfo()
        },
        //学生姓名点击
        studentClick(data) {
            // console.log(data);
            this.studentName = data.name;
            this.student_id = data.id;
            this.page = 1;
            this.getWorksShowInfo()
        },
        setSellStyle({row, column, rowIndex, columnIndex}) {
            if (column.label === '作品信息') {
                return "background:#FF8D0D;borderRadius: 5px 0 0 5px;color:#FFFFFF"
            } else if (column.label === '分享 | 评语 | 下载') {
                return "background:#FF8D0D;borderRadius: 0 5px 5px 0;color:#FFFFFF"
            } else {
                return "background:#FF8D0D;color:#FFFFFF"
            }
        },
        handleSizeChange() {
            // console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getWorksShowInfo()
            // console.log(`当前页: ${val}`);
        },
    },
    filters: {
        /*
    时间格式自定义 只需把字符串里面的改成自己所需的格式
   */
        formatDate(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd");
        },
        formatDate2(time) {
            var date = new Date(time);
            return formatDate(date, "hh:mm:ss");
        },
        formatDate3(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd. hh:mm:ss");
        },
    },
}
</script>

<style lang="less" scoped>
.list-class {
    width: 100%;
    //height: calc(99.5vh - 120px);

    .list-box-class {
        width: 96%;
        margin: 15px auto;
        height: calc(99.7vh - 150px);
        background: #FFFFFF;
        border-radius: 30px;
        box-shadow: 0 15px 30px 0 #d7d7d7;

        .table-class::-webkit-scrollbar {
            width: 0 !important
        }

        .table-class {
            -ms-overflow-style: none;
        }

        .table-class {
            overflow: -moz-scrollbars-none;
        }

        .table-class {
            width: 96%;
            margin: 0 auto;
            padding: 2% 0;
            height: calc(99.7vh - 300px);
            overflow-y: scroll;
        }
    }

    /deep/ .el-pagination.is-background .btn-next, /deep/ .el-pagination.is-background .btn-prev, /deep/ .el-pagination.is-background .el-pager li {
        background-color: #FFFFFF;
        border: 1px solid #E3E3E3;
    }

    .month_export {
        position: relative !important;
        background-color: #FF8D15;
        border: none;
        cursor: pointer;

        .el-date-editor {
            background-color: darkblue !important;
            width: auto !important;
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            opacity: 0;
        }
    }

    .type_export {
        position: relative !important;
        background-color: #FF8D15;
        border: none;
        cursor: pointer;

        .el-select {
            background-color: darkblue !important;
            width: auto !important;
            position: absolute !important;
            top: -5px !important;
            left: 0 !important;
            opacity: 0;
        }
    }

    .qr-box-class {

    }
}

/deep/ .el-input--suffix .el-input__inner {
    //padding-right: px;
    width: 100%;
}

/deep/ .el-input__inner {
    cursor: pointer;
    width: 50px;
}

/deep/ .el-textarea__inner {
    resize: none
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #FF8D0D;
    color: #FFF;
}

/deep/ .el-pagination.is-background .el-pager li.active {
    color: #fff;
    cursor: default;
}

/deep/ .el-pagination.is-background .el-pager li:hover {
    color: #FF8D0D;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #FF8D0D;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
    background-color: #FF8D0D;
    color: #FFF;
}

</style>
<style>
.el-select-dropdown__item.selected {
    font-size: 14px !important;

    color: #606266 !important;
    cursor: pointer !important;
}

.iconClass {
    display: none;
}
</style>
