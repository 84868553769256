<template>
    <div class="left-class">
        <div class="user-class">
            <div v-if="data.userinfo.user_avatar !== ''" class="edit-class" @click="menuClick(9)"
                 :style="{backgroundImage:'url(' + data.userinfo.user_avatar + ')',backgroundRepeat:'no-repeat',cursor: 'pointer'}">
                <!--                <span>编辑</span>-->
            </div>
            <div v-else class="edit-class" @click="menuClick(9)"
                 :style="{backgroundImage:'url(' + headBackImg + ')',backgroundRepeat:'no-repeat'}">
                <span>编辑</span>
            </div>
            <div>{{ data.userinfo.nickname }}</div>
            <div>{{ data.userinfo.slogan }}</div>
            <div v-if="menuShow === 2">
                <!-- <div v-if="goodsType==='by_lesson_fee'">剩余时长：{{ timeNum }}分钟</div> -->
                <!-- <div v-else >学习时长：{{ timeNum }}分钟</div> -->
                <div v-if="goodsType !== 'by_lesson_fee'">学习时长：{{ timeNum }}分钟</div>
            </div>
        </div>

        <div class="menu-class" v-if="menuShow === 1">
            <div v-for="(item,index) in menu" :key="index" class="menu-list-class"
                 :class="{'menu-color' : menuColor === index}" @click="menuClick(index)">
                <!--老师端-->
                <el-row :gutter="10">
                    <el-col :span="8">
                        <el-image v-if="item.type === 1" class="menu-icon-class" :src="item.cut_icon"
                                  fit="cover"></el-image>
                        <el-image v-else class="menu-icon-class" :src="item.icon" fit="cover"></el-image>
                    </el-col>
                    <el-col :span="16">
                        <span>{{ item.text }}</span>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div class="menu-class" v-if="menuShow === 2">
            <div v-for="(item,index) in studentMenu" :key="index" class="menu-list-class"
                 :class="{'menu-color' : menuColor === index}" @click="menuClick(index)">
                <!--学生端-->
                <el-row :gutter="10">
                    <el-col :span="8">
                        <el-image v-if="item.type === 1" class="menu-icon-class" :src="item.cut_icon"
                                  fit="cover"></el-image>
                        <el-image v-else class="menu-icon-class" :src="item.icon" fit="cover"></el-image>
                    </el-col>
                    <el-col :span="16">
                        <span>{{ item.text }}</span>
                    </el-col>
                </el-row>
            </div>
        </div>

        <!--        <div class="code-class">-->
        <!--            <div class="box-class" @click="(unLockLessonNum !== 0 || userData.role === 1) ? proClick() : alertLock()">-->
        <!--                <div class="but-class">-->
        <!--                    <div>-->
        <!--                        <el-image class="code-but-class" :src="codeButImg"-->
        <!--                                  fit="cover"></el-image>-->
        <!--                    </div>-->
        <!--                    <div>开始编程</div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>

</template>

<script>
import eventVue from '../assets/js/event.js'
import StudentTime from "../models/Home/StudentTime";
import Api from "../models/Home/Api";
import LessonVideo from "../models/Home/LessonVideo";
import UnlockClass from "../models/Home/UnloadClass";

export default {
    props: {
        homeBasisData: {type: Object}
    },
    name: "Left",
    data() {
        return {
            timeNum: 0,
            data: this.homeBasisData,
            menuColor: 0,
            headBackImg: './img/head-back.png',
            codeButImg: './img/code-but.png',
            menu: [
                //type 1 选中   type 0 未选中   status 2 学生
                {icon: './img/video-icon.png', text: '视频', cut_icon: './img/cut-video-icon.png', type: 1},
                {icon: './img/works-icon.png', text: '作品库', cut_icon: './img/cut-works-icon.png', type: 0},
                {
                    icon: './img/student-icon.png',
                    text: '学生管理',
                    cut_icon: './img/cut-student-icon.png',
                    type: 0,
                },
                {
                    icon: './img/unlock-icon.png',
                    text: '课程管理',
                    cut_icon: './img/cut-unlock-icon.png',
                    type: 0,
                },
                {
                    icon: './img/creation-icon.png',
                    text: '创作工具',
                    cut_icon: './img/cut-creation-icon.png',
                    type: 0,
                },
                {
                    icon: './img/game-icon.png',
                    text: '益智小工具',
                    cut_icon: './img/cut-game-icon.png',
                    type: 0,
                },
                {
                    icon: './img/consult-icon.png',
                    text: '1对1解答',
                    cut_icon: './img/cut-consult-icon.png',
                    type: 0,
                }
            ],
            studentMenu: [
                //type 1 选中   type 0 未选中   status 2 学生
                {icon: './img/video-icon.png', text: '视频', cut_icon: './img/cut-video-icon.png', type: 1},
                {icon: './img/works-icon.png', text: '作品库', cut_icon: './img/cut-works-icon.png', type: 0},
                {icon: './img/creation-icon.png', text: '创作工具', cut_icon: './img/cut-creation-icon.png', type: 0,},
                {icon: './img/game-icon.png', text: '益智小工具', cut_icon: './img/cut-game-icon.png', type: 0,}
            ],
            userData: JSON.parse(localStorage.getItem('info')),
            goodsType: localStorage.getItem('goodsCategory'),
            menuShow: 1,
            lessonId: 0,
            lastLessonId: 0,
            theDuration: 0,
            Lessonstatus: 0,
            unLockLessonNum: 0
        }
    },
    created() {
        const self = this;
        eventVue.$on('onBasicInfo', function (message) {
            console.log(message);
            // user_avatar
            // nickname
            // slogan
            self.data.userinfo.user_avatar = message.imageUrl;
            self.data.userinfo.nickname = message.nickname;
            self.data.userinfo.slogan = message.slogan;
        })
        eventVue.$on('lessonId', function (message) {
            self.lessonId = message;
            self.syncTimeInfo();
        })
        eventVue.$on("lessonStatus", function (status) {
            self.Lessonstatus = status;
        });
        if (this.userData.role === 2) {
            this.syncTimeInfo();
            setInterval(function () {
                self.syncTimeInfo();
            }, 300000) //60000 改成了5分钟
            // eventVue.$on('saveSyncTime', function (message) {
            //     self.syncTimeInfo();
            // })
        }
        // this.getUnLockLessonNum();
    },
    mounted() {
        if (this.userData.role === 1) {
            this.menuShow = 1;
        } else if (this.userData.role === 2) {
            this.menuShow = 2;
        }
    },
    methods: {
        syncTimeInfo() {
            let pageName;
            if (this.studentMenu[0].type === 1) {
                pageName = '视频';
            } else {
                pageName = '作品库';
            }

            const params = {
                time: parseInt((new Date()).getTime() / 1000),
                page_url: 'pageName',
                require_sync_time: 0,
                group_id: this.$store.getters.getGroupData.group_id,
                lesson_id: this.lessonId
            }
            eventVue.$on('courseIntro', function (message) {
                self.course_Intro = message;
            })

            if (params.lesson_id === 0) {
                return false;
            }

            StudentTime.getSyncTime(params, ({type, list}) => {
                if (type === 200) {
                    if (this.theDuration > 0 && list.data.duration <= 0 && this.lastLessonId == params.lesson_id) {
                        location.reload();
                    }
                    this.lastLessonId = params.lesson_id;

                    this.theDuration = list.data.duration;
                    this.timeNum = parseInt(list.data.duration * 60);
                    if (parseInt(list.data.duration * 60) >= 60) {
                        let h = parseInt(list.data.duration);
                        let m = parseInt(list.data.duration * 60) % 60;
                        this.timeNum = `${h}小时${m}`;
                    }
                }
            })
        },
        // getUnLockLessonNum() {
        //     Api.fetchData({params:{}}, 'listUnlockedLessonNum', ({type, list}) => {
        //         if (type === 200) {
        //             this.unLockLessonNum = list;
        //         }
        //     })
        // },
        proClick() {
            window.open("/gbscratch/");
        },
        menuClick(index) {
            this.menuColor = index;
            let pass;
            if (this.userData.role === 1) {
                this.menuType(this.menu, index);
            } else {
                this.menuType(this.studentMenu, index);
            }
            if (index === 0) {
                pass = 0;
            } else {
                pass = index + '-' + 0;
            }
            eventVue.$emit('getValue', index)
            this.$emit('viewCut', pass)
        },
        menuType(data, index) {
            data.forEach(function (item, key) {
                if (index === key) {
                    data[key].type = 1;
                } else {
                    data[key].type = 0;
                }
            })
        },
        alertLock() {
            this.$message({iconClass: 'not', message: '请解锁课程', center: true});
        }
    }
}
</script>

<style lang="less" scoped>
.left-class {
    width: 14%;
    height: calc(99.5vh - 118px);
    box-shadow: 0 24px 20px 0 #d7d7d7;

    .user-class {
        width: 80%;
        margin: 0 auto;
        border-bottom: 1px solid #F3F3F3;
        padding: 10% 0;
        text-align: center;

        .edit-class {
            width: 120px;
            height: 120px;
            margin: 0 auto;
            background-size: 100% 100%;
            border-radius: 50%;

            span {
                display: inline-block;
                padding-top: 90px;
            }
        }

        div:nth-child(2) {
            font-size: 20px;
            font-weight: 600;
            padding-top: 20px;
        }

        div:nth-child(3) {
            color: #9C9C9C;
            padding-bottom: 10px;
        }
    }

    .menu-class::-webkit-scrollbar {
        width: 0 !important
    }

    .menu-class {
        -ms-overflow-style: none;
    }

    .menu-class {
        overflow: -moz-scrollbars-none;
    }

    .menu-class {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(99.5vh - 118px - 250px);

        .menu-list-class {
            margin-top: 20px;
            line-height: 32px;
            padding: 8px 0;
            cursor: pointer;
        }

        .menu-icon-class {
            vertical-align: middle;
            width: 32px;
            height: 32px;
            float: right;
        }
    }

    .code-class {
        width: 150px;
        margin: 0 auto;
        text-align: center;
        position: relative;
        top: 10%;
        //position: relative;
        //top: 20%;
        //left:-18%;
        ////bottom: 20px;
        ////padding: 50px 0;
        //z-index: 0;

        .box-class {
            width: 150px;
            //height: 150px;
            margin: 0 auto;
            border-radius: 50%;
            background: #FFA514;
            box-shadow: 2px 2px 2px 2px rgba(255, 139, 12, 0.14);

            .but-class {
                padding: 30px 0;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }

    .menu-color {
        background: linear-gradient(135deg, #FFBF1C 0%, #FF8B0C 100%);
        border-radius: 30px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        color: #FFFFFF;
    }

    .code-but-class {
        width: 60%;
        height: 60%
    }
}
</style>
