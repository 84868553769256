<template>
    <div class="header">
        <!-- 折叠按钮 -->
        <div class="collapse-btn">
            <div class="Logo-class">
                <!--                                        <el-image class="institution-class" :src="basicData.bureau.bureau_avatar" fit="fill" style="width: 30%;height: 30%"></el-image>-->
                <img v-if="state" :src="homeBasisData.bureau.bureau_avatar" alt=""
                     class="institution-class">
            </div>
        </div>
        <div class="logo" v-if="state" :title="homeBasisData.bureau.bureau_name ">{{
                homeBasisData.bureau.bureau_name
            }}
        </div>
        <div class="course">
            <span v-for="(item,index) in topMenu">
                <span :class="{'cssColor' : item.type ===1}" @click="typeClick(index,item.url)">
                    {{ item.text }}
                </span>
            </span>
        </div>
        <div class="header-right">
            <div class="header-user-con">
                <div class="group-class">
                    <el-dropdown trigger="click" @command="handleCommand">
                        <el-button type="primary">
                            {{ group_name }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item class="menu-class" v-for="item in homeClassData" :command="item">
                                {{ item.group_name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>

                <!-- 消息中心 -->
                <div class="helper-class" v-if="$store.getters.getUserData.role === 1">
                    <span @click="typeClick(0,'SystemHelp')">帮助</span>
                </div>
                <!-- 用户名下拉菜单 -->
                <div class="icon-popover-class">
                    <el-popover
                        ref="popover"
                        placement="bottom"
                        width="70"
                        trigger="click" style="width: 100%">
                        <div style="text-align: center">
                            <ul>
                                <li class="popover-class"
                                    @click="savePasswordClick()">修改密码
                                </li>
                                <li class="popover-class"
                                    @click="typeClick(0,'ResourceDown')">下载
                                </li>
                                <li class="popover-class"
                                    @click="exitClick()">退出
                                </li>
                            </ul>
                        </div>
                        <i slot="reference" class="el-icon-arrow-down"></i>
                    </el-popover>
                </div>
            </div>
        </div>
        <div v-show="savePassStatus">
            <SavePassword @alertPassword="alertPasswordStatus"></SavePassword>
        </div>
    </div>
</template>
<script>
import eventVue from '../../assets/js/event.js'
import TeacherUser from "../../models/Home/TeacherUser";
import SavePassword from "../common/SavePassword";
import Resources from "../../models/Resources";
import UnloadClass from "../../models/Home/UnloadClass";
import websocket from "../../utils/websocket";

export default {
    props: ['homeClassData', 'homeCourseData', 'homeBasisData'],
    data() {
        return {
            courseInfo: this.homeCourseData,
            test: {
                img: './img/login/institution.png'
            },
            fullscreen: false,
            name: "linxin",
            message: 2,
            topMenu: [],
            fontColor: 0,
            // homeClassData: [],//班级列表
            group_name: '选择班级',
            savePassStatus: false,
            state: false,
            courseName: '',
            menu: Resources.SidebarData(this.$store.getters.getUserData.role),
        };
    },
    components: {
        SavePassword
    },
    created() {
        if (this.homeBasisData.bureau != undefined) {
            this.state = true;
        }
        if(this.$store.getters.getGroupData === null){
            if (this.homeClassData.length !== 0) {
                this.group_name = this.homeClassData[0].group_name;
            }
        }else{
            this.group_name = this.$store.getters.getGroupData.group_name;
        }


        let c_name = ''
        if (this.courseInfo.length !== 0) {
            c_name = this.courseInfo[0].title;
        }
        const _this = this;
        let topState = true;
        eventVue.$on('topMenu', function (message) {
                _this.topMenu = message;
                topState = false
            // }

        })
        //班级列表
        // this.homeGroupList()

        //缓存区分班级课程类型
        if (this.$store.getters.getUserData.role === 2) {
            _this.listUnlockedLessonNumInfo();
            setInterval(function () {
                _this.listUnlockedLessonNumInfo();
            }, 10000) //60000 改成了5分钟
        }
    },
    methods: {
        // 侧边栏折叠
        collapseChage() {
            // this.$store.commit("hadndleCollapse", !this.collapse);
        },
        typeClick(index, url) {
            let _this = this;
            this.topMenu.forEach((item,key) =>{
                if(key === index){
                    item.type = 1;
                }else{
                    item.type = 0;
                }
            })
            this.fontColor = index;
            this.$router.push({path: url})
        },
        //切换班级
        handleCommand(command) {
            this.$store.commit('setGroupData', command);
            this.group_name = command.group_name;

            this.homeCourseList(3);
            let socketWs = websocket.getWebSocket();
            socketWs.close()
        },
        //首页课程标题
        homeCourseList(cut = 1) {
            const params = {
                group_id: this.$store.getters.getGroupData.group_id,
            }
            TeacherUser.getCourseList(params, ({type, list}) => {
                if (type === 200) {
                    this.$store.commit('setCourseData', list.list);
                    let _this = this;
                    if(this.$route.name === 'VideoList'){
                        _this.topMenu = [{text: _this.$store.getters.getCourseData[0].title, type: 1, url: 'VideoList'}];
                    }
                    if (this.$route.name === 'studentVideoList') {
                        _this.topMenu = [{text: _this.$store.getters.getCourseData[0].title, type: 1, url: 'studentVideoList'}];
                    }

                    _this.menu.forEach(item => {
                        if (item.url === 'VideoList') {
                            item.top_menu = [{text: _this.$store.getters.getCourseData[0].title, type: 1, url: 'VideoList'}];
                        }
                        if (item.url  === 'studentVideoList') {
                            item.top_menu = [{text: _this.$store.getters.getCourseData[0].title, type: 1, url: 'studentVideoList'}];
                        }
                    })
                    eventVue.$emit('headCutData', _this.menu);
                    if (cut === 3) {
                        eventVue.$emit('courseState', false);
                    }
                    if (cut === 2) {
                        eventVue.$emit('firstState', false);
                    }
                }
            })
        },
        //修改密码
        savePasswordClick() {
            this.$refs.popover.doClose();
            this.savePassStatus = true;
        },
        //修改密码弹窗
        alertPasswordStatus() {
            this.savePassStatus = false;
        },
        //退出按钮
        exitClick() {
            this.$Dialog.confirm(
                {
                    message: '<span style="font-size: 16px">确定退出吗？</span>',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    // on confirm
                    localStorage.clear();
                    // localStorage.removeItem('info');
                    // localStorage.removeItem('courseData');
                    // localStorage.removeItem('groupData');
                    // localStorage.removeItem('spriteState')
                    // localStorage.removeItem('blockList')
                    // localStorage.removeItem('lessCode')
                    // localStorage.removeItem('deleteState')
                    // localStorage.removeItem('test')
                    // localStorage.removeItem('freeState')
                    // localStorage.removeItem('initialState')
                    // localStorage.removeItem('unloadGroupData')
                    // localStorage.removeItem('lockGameId')
                    // localStorage.removeItem('gameState')
                    // localStorage.removeItem('covertState')

                    this.isRouterAlive = false
                    this.$nextTick(function () {
                        this.isRouterAlive = true
                    })
                    this.$router.push({
                        path: '/',
                        name: 'Login',
                        params: {}
                    })
                    window.location.reload()

                })
                .catch(() => {
                    // on cancel
                });

        },
        //添加缓存 只有学生端的时候
        async listUnlockedLessonNumInfo() {
            let _this = this;
            UnloadClass.getListUnlockedLessonNum({}, ({type, list}) => {
                console.log(type,list)
                if (type === 200) {
                    console.log(list)
                    _this.$store.commit('setUnloadGroupData', list.list.lesson_num)
                }
            })
        },
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    }
};
</script>
<style scoped lang="less">
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    box-shadow: 0px 0px 24px 0px #d7d7d7;
}

.collapse-btn {
    float: left;
    padding: 10px 40px;
    cursor: pointer;
    line-height: 120px;
   width: 100px;
    .institution-class {
        width: 100px;
        height: 100px;
    }
}

.header .logo {
    float: left;
    width: 250px;
    line-height: 120px;
    color: #FF8D15;
    font-size: 28px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
}

.course {
    float: left;
    line-height: 120px;
    font-size: 20px;
    color: #4a4a4a;
    margin-left: 50px;

    span {
        margin-right: 50px;
        cursor: pointer;
    }
}

.header-right {
    float: right;
    padding-right: 120px;
}

.header-user-con {
    display: flex;
    height: 120px;
    align-items: center;
}

.group-class {
    margin-right: 50px;

    .el-button--primary {
        background: #FF8D15;
        border: #FF8D15;
    }
}

.menu-class:hover {
    background: #fcd2a6 !important;
    color: #606266 !important;
}

.helper-class {
    margin-right: 50px;
    cursor: pointer;
}

.icon-popover-class {
    .el-icon-arrow-down {
        font-size: 26px;
        cursor: pointer;
    }
}

.popover-class {
    border-bottom: 1px solid #F4F4F4;
    padding: 10px 0 !important;
    cursor: pointer;
}

.cssColor {
    color: #FF8E0D !important;
}

/deep/ .el-dropdown-selfdefine {
    //padding: 18px 28px;
    font-size: 20px;
}
</style>
