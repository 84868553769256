import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class Query extends BaseModel {

    /**
     * 问号
     */
    static getListFaqs(params, fn) {
        BaseCall.get('getListFaqs', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
