<template>
    <div class="list-class">
        <div class="list-box-class">
            <!-- <div class="table-class">
                <el-table
                    :data="studentData.list"
                    style="width: 100%"
                    :header-cell-style="setSellStyle"
                >
                    <el-table-column
                        label="头像"
                        width="180">
                        <template slot-scope="scope">
                            <span v-if="typeof (scope.row.avatar) === 'undefined'">
                                 <el-image class="code-but-class" :src="headImg"
                                           fit="cover"
                                           style="width: 80px;height:80px;cursor: pointer;border-radius: 50%"></el-image>
                            </span>
                            <span v-else>
                                <el-image class="code-but-class" :src="scope.row.avatar"
                                          fit="cover"
                                          style="width: 80px;height:80px;cursor: pointer;border-radius: 50%"></el-image>
                            </span>

                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="nickname"
                        label="姓名"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="登录账号"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        label="班级">
                        <template>
                            {{ $store.getters.getGroupData.group_name }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="phone"
                        label="电话">
                    </el-table-column>
                    <el-table-column
                        prop="issue_time"
                        label="最近发布时间"
                        align="center">
                    </el-table-column>
                </el-table>
            </div> -->
            <el-row :gutter="40" class="row-pick-box">
                <el-col :span="6" v-for="(item, index) in studentData.list" :key="index">
                    <div class="pick-student-box">
                        <div :style="{height: '120px', position:'relative'}">
                            <div class="img-box-head">
                                <img
                                    width="100%"
                                    :src="item.avatar ? item.avatar : './img/default-head-img2.png'" alt="">
                            </div>
                        </div>
                        <div :style="{position:'relative',overflow:'hidden',padding:'10px',paddingTop:'0'}">
                            <div class="card-box-keep-out"></div>
                            <el-card
                                :style="{color:'#626262',fontSize: '14px',position:'relative', paddingTop:'35px'}">
                                <p class="text">姓名：{{ item.nickname }}</p>
                                <p class="text">
                                    <el-row class="text">
                                        <el-col :span="8">
                                            <span>课程进度：</span>
                                        </el-col>
                                        <el-col :span="16">
                                            <el-progress
                                                :percentage="Math.round((item.lessons_num/item.total_lesson_num)*100)"
                                                color="#FF8D0D" :stroke-width="9"
                                                :style="{marginTop:'8px'}"></el-progress>
                                        </el-col>
                                        <!-- <el-col :span="8" :style="{textAlign:'right',overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}">
                                            <span style="color:#FF8D0D;" ></span>
                                        </el-col> -->
                                    </el-row>
                                </p>
                                <p class="text" :title="item.latest_lesson_name">
                                    <el-row>
                                        <el-col :span="8">
                                            <span>最新课时：</span>
                                        </el-col>
                                        <el-col :span="16">
                                                <span class="lesson-name-class">
                                                    <span v-if="item.latest_lesson_name"
                                                          style="color:#FF8D0D;">{{ item.latest_lesson_name }}</span>
                                                    <span v-else style="color:#FF8D0D;">暂无课时</span>
                                                </span>
                                        </el-col>
                                    </el-row>
                                </p>
                                <p class="text">登录账号：{{ item.name }}</p>
                                <span class="text game-but-class" @click="studentListDiaClick(item.id)">解锁小游戏</span>
                            </el-card>
                        </div>

                    </div>
                </el-col>
            </el-row>
            <div class="block" style="text-align: center;margin-top: 30px;">
                <el-pagination background layout="prev, pager, next, total, jumper" :page-size="8"
                               v-if="noneStatus === false"
                               :total="studentData.count" @size-change="handleSizeChange"
                               @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>

        <el-dialog
            class="student-list"
            title="游戏列表"
            :center="true"
            :visible.sync="studentListDialog"
            width="57%"
            z-index="500">
            <div>
                <el-row :gutter="45">
                    <el-col v-for="item in info" :lg="{span: '4-8'}" :span="6" :key="item.rec_id"
                            class="list-le-col">
                        <div @click="checkClick(item.rec_id)">
                            <el-card class="box-card">
                                <div>
                                    <el-avatar size="medium" class="avatar-box" :src="item.game_img"></el-avatar>
                                </div>
                                <p class="name">{{ item.game_name }}</p>
                                <span class="pitch-box"
                                      :class="studentLockGames.indexOf(item.rec_id) >= 0 ? 'checked' : 'unchecked'">
                                        <i class="el-icon-success success"></i>
                                    </span>
                            </el-card>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <p class="student-text">
                <!-- <span class="text-span">本次共解锁<span class="color-FF8B0C">{{lockStudent.unlockMin}}</span>分钟</span> -->
                <!--                    <span class="text-span">单个学生使用时长<span class="color-FF8B0C">1</span>分钟</span>-->
                <!--                    <span class="text-span">共<span class="color-FF8B0C">2</span>人</span>-->
                <!--                    <span class="text-span">总价<span class="color-FF8B0C">3</span>元</span>-->
                <!-- <span class="button">确定</span> -->
            </p>
        </el-dialog>
        <el-dialog
            class="student-list"
            title="本次消耗"
            :center="true"
            :visible.sync="studentAffirm"
            width="28%">
            <div class="student-qr-text">
                    <span class="text-span left">本次消耗：<span
                        class="color-FF8B0C"></span>分钟</span>
                <span class="text-span right">价格：<span
                    class="color-FF8B0C"></span>元</span>
                <p style="clear:both;"></p>
            </div>
            <p class="student-qr-text">
                <span class="button">确定</span>
            </p>
        </el-dialog>
    </div>
</template>

<script>
import eventVue from '../../assets/js/event.js'
import Student from "../../models/Home/Student";
import Api from "../../models/Home/Api";

export default {
    name: "WorksList",
    data() {
        return {
            studentAffirm: false,
            studentListDialog: false,
            noneStatus: false,
            currentPage3: 5,
            studentData: {},
            headImg: './img/default-head-img2.png',
            page: 1,
            info: [
                {id: 1, name: '天平小游戏', img: './img/default-head-img2.png', state: 0},
                {id: 2, name: '泡泡打字', img: './img/default-head-img2.png', state: 1},
            ],
            studentLockGames: [],
            theStudent: 0
        }
    },
    created() {
        this.getStudentInfo();
        this.getGameInfo();
        let self = this;
        eventVue.$on("classClick", function (courseData) {
            self.page = 1;
            self.getStudentInfo();
        })
    },
    computed: {
        GroupData() {//监听store的数据
            return this.$store.getters.getGroupData
        }
    },
    watch: {
        GroupData() {
            this.getStudentInfo();
        }
    },
    methods: {
        //学生管理列表
        getStudentInfo() {
            let param = {
                group_id: this.$store.getters.getGroupData.group_id,
                page: this.page,
                limit: 8,
            }
            Student.getStudentList(param, ({type, list}) => {
                if (type === 200) {
                    list.list.forEach(item => {
                        if (item.latest_lesson_name.indexOf('<br>') > -1) {
                            item.latest_lesson_name = item.latest_lesson_name.replace("<br>", "-")
                        }
                    })
                    this.studentData = list;

                    console.log(this.studentData);
                }
            })
        },
        getGameInfo() {
            Api.fetchData({params: {}}, '/bureau/v1.2/listGames', ({type, list}) => {
                this.info = list;
            })
        },
        getLockGames(id) {
            Api.fetchData({
                student_id: id,
            }, '/bureau/v1.2/getLockGamesByStudent', ({type, list}) => {
                this.studentLockGames = list;
            })
        },
        setSellStyle({row, column, rowIndex, columnIndex}) {
            if (column.label === '头像') {
                return "background:#FF8D0D;borderRadius: 5px 0 0 5px;color:#FFFFFF"
            } else if (column.label === '最近发布时间') {
                return "background:#FF8D0D;borderRadius: 0 5px 5px 0;color:#FFFFFF"
            } else {
                return "background:#FF8D0D;color:#FFFFFF"
            }
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.getStudentInfo();
        },
        imgClick() {
            eventVue.$emit('saveFontColor', 1)
            //1.1 作品展示
            this.$emit('viewCut', '2-1')
        },
        // unlockGame() {
        //
        // },
        //选中状态
        checkClick(game_id) {
            var index = this.studentLockGames.indexOf(game_id),
                mes = index >= 0 ? '上锁' : '开锁',
                is_lock = index >= 0 ? 0 : 1;
            this.$Dialog.confirm(
                {
                    message: '<span style="font-size: 16px">确定' + mes + '吗？</span>',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    Api.fetchData({
                        students: this.theStudent,
                        games: game_id,
                        is_lock: is_lock
                    }, '/bureau/v1.2/lockGamesByStudent', () => {
                        if (is_lock === 0) {
                            this.studentLockGames.splice(index, 1);
                            this.$message({
                                message: "上锁成功",
                                type: "success",
                                iconClass: "iconClass",
                            });
                        } else {
                            this.studentLockGames.push(game_id);
                            this.$message({
                                message: "开锁成功",
                                type: "success",
                                iconClass: "iconClass",
                            });
                        }
                    })
                })
        },
        studentListDiaClick(studentId) {
            this.studentListDialog = true;
            this.theStudent = studentId;
            this.getLockGames(studentId);
        }
    }
}
</script>

<style lang="less" scoped>
.student-list {
    .list-le-col {
        margin-bottom: 30px;

        .box-card {
            text-align: center;
            position: relative;
            cursor: pointer;

            .avatar-box {
                width: 100px;
                height: 100px;
                background-color: #fff;
            }

            .name {
                font-size: 24px;
                color: #898989;
            }

            .pitch-box {
                position: absolute;
                top: 10px;
                right: 10px;
                border-radius: 16px;
                border: 1px solid #ddd;

                .success {
                    font-size: 24px;
                }
            }

            .checked {
                border: none;
                color: #FF8D0D;
            }

            .unchecked {
                i {
                    color: #fff;
                }
            }

            .disable {
                border: none;

                i {
                    color: #fff;
                }
            }

            .in-use {
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
            }
        }
    }
}

.lesson-name-class {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis; /*只是起到注解作用*/

    white-space: nowrap; /*不让文本换行*/

    overflow: hidden; /*超出的部分显示...*/
}

.student-text, .student-qr-text {
    text-align: right;
    font-size: 24px;
    letter-spacing: 1px;

    .text-span {
        margin-right: 24px;
    }

    .button {
        background: #FF8B0C;
        color: #fff;
        font-size: 20px;
        padding: 10px 40px;
        border-radius: 27px;
        cursor: pointer;
    }
}

.student-qr-text {
    color: #898989;
    text-align: right;
    margin: 0 13px;
    margin-top: 45px;
}

.left {
    float: left;
}

.right {
    float: right;
}

.row-pick-box {
    height: 88%;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

}

::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.pick-student-box {
    margin-top: 8px;

    .text {
        line-height: 30px;
    }
}

.card-box-keep-out {
    position: absolute;
    width: 40%;
    height: 128px;
    background: #fff;
    top: -78px;
    left: 30%;
    border-radius: 50%;
    z-index: 1;
    box-shadow: 0 0.01042rem 0.0625rem 0 #ddd inset;
}

.img-box-head {
    width: 130px;
    height: 130px;
    position: absolute;
    left: 117px;
    bottom: -31px;
    z-index: 2;
    border-radius: 1rem;
    overflow: hidden;
}

.game-but-class {
    padding: 8px 10px;
    background: #FF8D0D;
    color: #FFFFFF;
    text-align: center;
    border-radius: 22px;
    cursor: pointer;
}

.game-but-class:hover {
    background: #f69c36;
}

.header_home {
    width: 86%
}

.list-class {
    width: 100%;
    //height: calc(99.7vh - 120px);

    .list-box-class {
        width: calc(100% - 40px);
        margin: 12px auto;
        height: calc(99.7vh - 175px);
        background: #FFFFFF;
        border-radius: 10px;
        //box-shadow: 0 15px 30px 0 #d7d7d7;

        .table-class::-webkit-scrollbar {
            width: 0 !important
        }

        .table-class {
            -ms-overflow-style: none;
        }

        .table-class {
            overflow: -moz-scrollbars-none;
        }

        .table-class {
            width: 96%;
            margin: 0 auto;
            padding: 2% 0;
            height: calc(99.7vh - 300px);
            overflow-y: scroll;
        }
    }

    /deep/ .el-pagination.is-background .btn-next, /deep/ .el-pagination.is-background .btn-prev, /deep/ .el-pagination.is-background .el-pager li {
        background-color: #FFFFFF;
        border: 1px solid #E3E3E3;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #FF8D0D;
        color: #FFF;
    }

    /deep/ .el-pagination.is-background .el-pager li.active {
        color: #fff;
        cursor: default;
    }

    /deep/ .el-pagination.is-background .el-pager li:hover {
        color: #FF8D0D;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #FF8D0D;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
        background-color: #FF8D0D;
        color: #FFF;
    }

    //::v-deep .pagination {
    //    text-align: center;
    //}
    //
    //::v-deep .el-pagination.is-background {
    //    .btn-next,
    //    .btn-prev,
    //    .el-pager li {
    //        margin: 0 12px;
    //        color: #666666;
    //        border-radius: 10px;
    //        font-size: 30px;
    //        font-weight: lighter;
    //        padding-top: 16px;
    //        line-height: 12px;
    //        height: 30px;
    //        width: 30px;
    //        border: 1px solid #ddd;
    //        background-color: #fff;
    //    }
    //
    //    .btn-next,
    //    .btn-prev {
    //        width: 45px;
    //        height: 45px;
    //        padding: 0;
    //    }
    //
    //    .el-pagination__total,
    //    .el-pagination__jump {
    //        font-size: 25px;
    //        line-height: 45px;
    //    }
    //
    //    .el-input__inner {
    //        height: 45px;
    //        font-size: 25px;
    //        border-radius: 10px;
    //    }
    //}
    //
    //::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    //    color: #fff;
    //    width: 45px;
    //    height: 45px;
    //}
    //
    //::v-deep .el-pagination {
    //    .btn-next,
    //    .btn-prev {
    //        .el-icon {
    //            font-size: 30px;
    //            font-weight: 100;
    //            color: #e3e3e3;
    //        }
    //    }
    //}
    .iconClass {
        display: none;
    }
}
</style>
