import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class StudentTime extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {

            },
        }
    }

    // 作品集合
    static getSyncTime(params, fn) {
        BaseCall.get('getSyncTime', {params: params}, ({type, data}, res) => {
            let list = data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
