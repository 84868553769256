import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";
import { apiRequest } from "../../router/apiRequest";

export default class Api extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {
                // label: '书本纪AI正在为您准备适合您的题目...'
            },
        }
    }

    static fetchData(params, url, fn, type = 'get') {
        switch (type) {
            case 'get':
                BaseCall.get(url, { params: params }, ({ type, data }, res) => {
                    let list = data.data
                    typeof fn === 'function' ? fn({ type, list }) : '';
                });

                break;
            case 'post':
                BaseCall.post(url, { params: params }, ({ type, data }, res) => {
                    let list = data.data
                    typeof fn === 'function' ? fn({ type, list }) : '';
                });
                break;
            default:
                break;
        }
    }
}
