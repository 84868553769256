<template>
    <div class="plan-class">
        <div>
            <div class="top-box">
                <span>某某课时教案</span>
                <i class="el-icon-close" style="font-size: 30px;cursor: pointer" @click="closePlanClick"></i>
            </div>

            <div class="content-box" v-html="plan"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        plan: {type: String}
    },
    name: "TeachPlan",
    methods: {
        closePlanClick() {
            this.$parent.teachPlanStatus = false;
        }
    }
}
</script>

<style lang="less" scoped>
.plan-class {
    width: 60%;
    height: 60vh;
    background: #f4f4f4;
    position: absolute;
    top: 20%;
    left: 18%;
    border-radius: 10px;
    box-shadow: 0px 5px 5px -4px #999999;

    .top-box {
        height: 3vh;
        padding: 14px;

        i {
            float: right;
        }
    }

    .content-box {
        padding: 0 14px;
    }
}
</style>
