<template>
    <div class="back-content">
        <!-- 星星 -->
        <!-- size 尺寸    top 距离顶部位置     left 距离左边位置
             right 距离右边位置    delay 延迟出现特效时间秒数
        -->
        <Star size="50" top="50" left="400" delay="0" />
        <Star size="70" top="150" right="250" delay="0.3" />
        <Star size="90" top="600" left="150" delay="1.1" />
        <Star size="100" top="340" right="400" delay="1.7" />
        <Star size="80" top="400" right="50" delay="2" />
        <!-- 流星 -->
        <Meteor size="5" top="30" left="250" delay="0" />
        <Meteor size="5" top="50" left="350" delay="2" />
        <Meteor size="5" top="80" left="450" delay="3" />
        <Meteor size="5" top="120" left="450" delay="4" />
        <Meteor size="5" top="60" right="550" delay="10" />
        <Meteor size="5" top="80" right="1050" delay="6" />
        <Meteor size="5" top="300" right="1050" delay="8" />
        <Meteor size="5" top="500" right="650" delay="0" />
        <Meteor size="5" top="200" right="1250" delay="0" />
    </div>
</template>

<script>
import Star from './star'
import Meteor from './meteor'
export default {
    components: {
        Star,
        Meteor,
    },
    data() {
        return {}
    },
    created() {},
    methods: {},
}
</script>

<style lang="less" scoped>
.back-content {
}
</style>
