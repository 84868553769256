<template>
    <div class="down-class">
        <div class="down-box-class">
            <div class="list">
                <div class="item" v-for="(item,key) in info">
                    <div class="h-box" :class="{ selectColor: key === selectIndex }" @click="toolClick(item.state, item.url, key)">
                        <img :src="item.img">
                        <div>{{item.name}}</div>
                    </div>
                </div>
            </div>


<!--            <div class="tool-class">-->
<!--                <div v-if="butState"-->
<!--                     v-for="(item, key) in info"-->
<!--                     :key="key" class="item">-->
<!--                    <div style="width: calc(100%);display: flex;flex-direction: column;background: #00DBB4">-->
<!--                        1231-->
<!--&lt;!&ndash;                        <img :src="item.img" class="menu-icon-class">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>{{ item.name }}</div>&ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
<!--                <el-row :gutter="20" style="text-align: center">-->
<!--                    <el-col-->
<!--                        v-if="butState"-->
<!--                        :span="4"-->
<!--                        v-for="(item, key) in info"-->
<!--                        :key="key"-->
<!--                        class="col-box"-->
<!--                    >-->
<!--                        <div style="display: flex;flex-direction: column">-->
<!--                            <img :src="item.img" class="menu-icon-class">-->
<!--                            <div>{{ item.name }}</div>-->
<!--                        </div>-->
                        <!--                        <div-->
                        <!--                            class="but-box"-->
                        <!--                            :class="{ selectColor: key === selectIndex }"-->
                        <!--                            @click="toolClick(item.state, item.url, key)"-->
                        <!--                        >-->
                        <!--                            <div>-->
                        <!--                                <el-image-->
                        <!--                                    class="menu-icon-class"-->
                        <!--                                    :src="item.img"-->
                        <!--                                    fit="cover"-->
                        <!--                                ></el-image>-->
                        <!--                            </div>-->
                        <!--                            <div class="text-class">{{ item.name }}</div>-->
                        <!--                        </div>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--            </div>-->
        </div>

        <div v-if="unlockedAlert" ref="treeWrap">
            <div class="mask-layer-class" @click="unlockedAlert = false"></div>
            <div class="not-lesson">
                <div class="title">
                    <h2>激活课时</h2>
                </div>

                <div class="main">
                    <div class="item" v-for="(item, index) in unlockedList" :key="index">
                        <div class="small-box">
                            <div class="lesson-img1">
                                <img :src="item.image_url" alt=""/>
                            </div>
                            <div class="lesson-name">
                                课时名:
                                <span>{{ item.lesson_name }}</span>
                            </div>
                            <div class="lesson-btn" @click="handleUamilisho(item)">激活</div>
                        </div>
                    </div>
                </div>
                <div class="block" style="text-align: center;">
                    <el-pagination background layout="prev, pager, next, total, jumper" :page-size="8"
                                   v-if="unlockedList !== undefined && unlockedList.length !== 0"
                                   :total="total" @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UnloadClass from "../../models/Home/UnloadClass";
import Back from "@/views/ipad/Home/components/back";

export default {
    name: "Tool",
    components: { Back },
    data() {
        return {
            butState: false,
            unlockedAlert: false,
            selectIndex: "",
            info: [

                {
                    img: "./img/scratch-but2.png",
                    name: "scratch编程",
                    state: 1,
                    url:
                        "/gbscratch/?group_id=" + this.$store.getters.getGroupData.group_id,
                },
                {
                    img: "./img/python-but2.png",
                    name: "python编程",
                    state: 1,
                    url: "/pythonide/",
                },
                {
                    img: "./img/python-but2.png",
                    name: "python3编程",
                    state: 1,
                    url: "/pygame/",
                },
                {
                    img: "./img/scratch-but2.png",
                    name: "原版scratch",
                    state: 1,
                    url: "/guobaoscratch/",
                },
                {
                    img: "./img/scratchjr-btn2.jpg",
                    name: "幼儿编程",
                    state: 1,
                    url: `/jr/`,
                },
                {
                    img: "./img/python-but2.png",
                    name: "python3",
                    state: 1,
                    url: "/pygame3/",
                }, {
                    img: "./img/blockly-game-img.png",
                    name: "blocklyGames",
                    state: 1,
                    url: "/blockly-games/zh-hans/index.html",
                }, {
                    img: "./img/blockly-guodong-img-1.png",
                    name: "果动编程",
                    state: 1,
                    url: "/guobaojr/",
                },
            ],
            unlockedList: [],
            total:0,
            page:1
        };
    },
    created() {
        if (this.$store.getters.getUserData.role === 2) {
            this.initTool();
        } else {
            this.butState = true;
        }
    },
    methods: {
        handleUamilisho(item) {
            this.unlockedAlert = false;
            window.open(
                this.$fileName+"/#/lessonDetail3?lesson_id=" +
                item.lesson_id +
                "&lesson_name=" +
                item.lesson_name +
                "&group_id=" +
                this.$store.getters.getGroupData.group_id +
                "&unlock_group_id=" +
                item.group_id +
                "&course_id=" +
                item.course_id +
                "&unlock=unlock" +
                "&text=" +
                item.lesson_text,

                "_blank"
            );
        },
        initTool() {
            //添加缓存 只有学生端的时候
            let _this = this;
            UnloadClass.getListUnlockedLessonNum({page:this.page,limit:8}, ({ type, list }) => {
                if (type === 200) {
                    _this.$store.commit("setUnloadGroupData", list.list.lesson_num);
                    this.total = list.count;
                    this.unlockedList = list.list.unlocked;
                    this.butState = true;
                }
            });

            UnloadClass.canUnlock({}, ({ type, list }) => {
                if (type === 200) {
                    if (list !== 0) {
                        this.$store.commit('setCanUnlock', list)
                    } else {
                        this.$store.commit('setCanUnlock', list)
                    }
                }
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.initTool();
        },
        toolClick(state, url, key) {
            this.selectIndex = key;
            if (state === 0) {
                this.$Dialog
                    .confirm({ message: "正在研发中...", confirmButtonColor: "#FF8D15" })
                    .then((r) => {
                    })
                    .catch(() => {
                        // on cancel
                    });
                return;
            }

            let status = false;
            if (this.$store.getters.getUserData.role === 2) {
                if ((
                    localStorage.getItem("unloadGroupData") !== null &&
                    localStorage.getItem("unloadGroupData") > 0
                ) || (localStorage.getItem("canUnlock") !== undefined && localStorage.getItem("canUnlock") > 0)) {
                    status = true;
                } else {
                    if (this.unlockedList.length === 0) {
                        this.$Dialog
                            .confirm({
                                message: "请先让老师解锁课程",
                                confirmButtonColor: "#FF8D15",
                            })
                            .then((r) => {
                            })
                            .catch(() => {
                                // on cancel
                            });
                    } else {
                        this.unlockedAlert = !this.unlockedAlert;
                    }
                }
            }

            if (this.$store.getters.getUserData.role === 1) {
                status = true;
            }
            if (status === true) {
                window.open(url);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.list{
    display: flex;
    //background: red;
    flex-wrap: wrap;
    .item{
        width:calc(100%/6);
        //background: palegreen;
        .h-box{
            width: 184px;
            //background: #1da4d6;
            margin: 20px auto;
            text-align: center;
            cursor: pointer;
            color: #666666;
            font-weight: bold;
            &:hover{
                color: #ff8b0c;
                img{
                    box-sizing: border-box;
                    border: 1px solid #ff8b0c;
                }
            }

            img{
                width: 184px;
                height: 184px;

                background: #f6f6fb;
                border-radius: 10px;
            }
        }
    }
}
.down-class {
    width: 100%;
    //height: calc(99.7vh - 120px);

    .down-box-class {
        width: calc(100% - 40px);
        margin: 12px auto;
        height: calc(99.7vh - 175px);
        background: #ffffff;
        border-radius: 10px;

        .tool-class {
            padding: 20px 0;
            display: flex;
        }
    }

    .col-box {
        display: flex !important;
        justify-content: center;
    }

    .but-box {
        width: 184px;
        height: 184px;
        cursor: pointer;
        background: #f6f6fb;
        border-radius: 10px;
    }

    .but-box:hover {
        border: 1px solid #ff8b0c;

        .text-class {
            color: #ff8b0c;
        }
    }



    .text-class {
        color: #666666;
        font-weight: bold;
    }
}

.mask-layer-class {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(18, 18, 18, 0.64);
}

.not-lesson {
    position: fixed;
    width: 1080px;
    height: 750px;
    border-radius: 10px;
    background: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /*向左向上分别平移自身的一半*/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    z-index: 2;

    .title {
        text-align: center;
        margin-top: 20px;
    }

    /* 设置滚动条的样式 */

    ::-webkit-scrollbar {
        width: 6px;
    }

    /* 滚动槽 */

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }

    /* 滚动条滑块 */

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(0, 0, 0, 0.1);
    }

    .main {
        margin: 16px 16px;
        display: flex;
        flex-wrap: wrap;
        height: 600px;
        overflow-y: scroll;

        .item {
            width: 25%;
            margin-bottom: 16px;

            .small-box {
                margin: 0 16px;
                background: #f6f6fb;
                border-radius: 10px;
                padding-bottom: 10px;

                .lesson-img1 {
                    img {
                        border-radius: 10px 10px 0 0;
                        width: 100%;
                        height: 150px;
                    }
                }

                .lesson-name {
                    margin: 0 10px;
                    font-size: 14px;
                    overflow: hidden; /*超出的部分隐藏起来。*/
                    white-space: nowrap; /*不显示的地方用省略号...代替*/
                    text-overflow: ellipsis; /* 支持 IE */

                    span {
                        font-weight: bold;
                        color: #666666;
                    }
                }

                .lesson-btn {
                    background: #ff8b0c;
                    margin: 10px 32px;
                    border-radius: 22.5px;
                    padding: 8px 0;
                    color: #ffffff;
                    text-align: center;
                    cursor: pointer;
                }

                .lesson-btn:hover {
                    background: #f3b370;
                }
            }
        }
    }
}

.menu-icon-class {
    width: 184px;
    height: 184px;
}

/deep/ .el-pagination.is-background .btn-next, /deep/ .el-pagination.is-background .btn-prev, /deep/ .el-pagination.is-background .el-pager li {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #FF8D0D;
    color: #FFF;
}

/deep/ .el-pagination.is-background .el-pager li.active {
    color: #fff;
    cursor: default;
}

/deep/ .el-pagination.is-background .el-pager li:hover {
    color: #FF8D0D;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #FF8D0D;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
    background-color: #FF8D0D;
    color: #FFF;
}
</style>
