<template>
    <div class="video-class" style="clear: both">
        <Top @topViewCut="topViewStatus" @videoList="topVideoList" @homeCourseList="homeCourseList"
             @transferData="transferData"
             :homeBasisData="homeBasisData"
             :homeClassData="homeClassData"
             :homeCourseData="homeCourseData" v-if="status"></Top>
        <Left v-if="status" @viewCut="viewStatus" :homeBasisData="homeBasisData" style="float: left;"
              class="clearfix"></Left>

        <template v-if="role === 1 && status === true">
            <VideoPlay v-if="flag === 0" style="float: left;" class="clearfix"></VideoPlay>
            <VideoList v-if="flag === 0" ref="refVideoList" style="float: right;" class="clearfix"></VideoList>

            <WorksList v-if="flag === '1-0'" ref="worksRef" @viewCut="viewStatus" @studentData="studentEmit"
                       style="float: left;"></WorksList>

            <WorksShow v-if="flag === '1-1'" ref="showWRef" :studentInfo="studentData" style="float: left;"></WorksShow>

            <StudentList v-if="flag === '2-0'" ref="studentRef" style="float: left;"></StudentList>

            <UnlockClass v-if="flag === '2-1'" ref="unlockRef" style="float: left;"></UnlockClass>

            <UnlockClass v-if="flag === '3-0'" ref="unlockRef" style="float: left;"></UnlockClass>

            <Tool v-if="flag === '4-0'" style="float: left;"></Tool>
            <ToolGame v-if="flag === '5-0'" style="float: left;"></ToolGame>
            <ExplainStaff v-if="flag === '6-0'" style="float: left;"></ExplainStaff>

            <PersonalCenter v-if="flag === '9-0'" :homeBasisData="homeBasisData" style="float: left;"></PersonalCenter>

            <ResourceDown v-if="flag === '10-0'" style="float: left;"></ResourceDown>

            <Query v-if="flag === '11-0'" style="float: left;"></Query>
        </template>

        <template v-if="role === 2 && status === true">
            <StudentVideoPlay v-if="flag === 0" style="float: left;" class="clearfix"></StudentVideoPlay>
            <StudentVideoList v-if="flag === 0" @viewCut="viewStatus" ref="refVideoList" style="float: right;"
                              class="clearfix"></StudentVideoList>

            <StudentWorks v-if="flag === '1-0'" @viewCut="viewStatus" ref="studentRef"
                          style="float: left;"></StudentWorks>
            <StudentWorksUpload v-if="flag === '1-1'" :homeCourseData="homeCourseData"
                                style="float: left;"></StudentWorksUpload>
            <StudentLessonText v-if="flag === '0-1'" style="float:left;"></StudentLessonText>

            <Tool v-if="flag === '2-0'" style="float: left;"></Tool>
            <ToolGame v-if="flag === '3-0'" style="float: left;"></ToolGame>
            <!-- <StudentVideoList ref="refVideoList" style="float: right;" class="clearfix"></StudentVideoList> -->
            <PersonalCenter v-if="flag === '9-0'" @onBasic="homeBasisInfo" :homeBasisData="homeBasisData"
                            style="float: left;"></PersonalCenter>
            <ResourceDown v-if="flag === '10-0'" style="float: left;"></ResourceDown>

            <Query v-if="flag === '11-0'" style="float: left;"></Query>
        </template>
    </div>
</template>

<script>
import Top from '../Top';
import Left from '../Left';
import VideoList from "../../components/video/VideoList";
import VideoPlay from "../../components/video/VideoPlay";
import WorksList from "../../components/works/WorksList";
import WorksShow from "../../components/works/WorksShow";
import StudentList from "../../components/student/StudentList";
import UnlockClass from "../../components/student/UnlockClass";
import ExplainStaff from "../../components/explain/ExplainStaff";
import PersonalCenter from "../../components/center/PersonalCenter";
import ResourceDown from "../../components/download/ResourceDown";
import Query from "../../components/query/Query";

import StudentVideoList from "../../components/studentHome/StudentVideoList";
import StudentVideoPlay from "../../components/studentHome/StudentVideoPlay";
import StudentWorks from '../../components/studentHome/StudentWorks';
import StudentWorksUpload from "../../components/studentHome/StudentWorksUpload";
import StudentLessonText from "../../components/studentHome/StudentLessonText";
import Tool from "../../components/creation/Tool";
import ToolGame from "../../components/creation/ToolGame";

// import Question from "../../models/Question"
import TeacherUser from "../../models/Home/TeacherUser"
import UnlockModel from "../../models/Home/UnloadClass";
import axios from "axios";

export default {
    name: "Video",
    computed: {
        userData() {
            return this.$store.state.userData;
        }

    },
    components: {
        Top,
        Left,
        VideoList,
        VideoPlay,
        WorksList,
        WorksShow,
        StudentList,
        UnlockClass,
        ExplainStaff,
        PersonalCenter,
        StudentVideoList,
        StudentVideoPlay,
        StudentWorks,
        StudentWorksUpload,
        ResourceDown,
        Query,
        StudentLessonText, Tool,
        ToolGame
    },
    data() {
        return {
            studentData: {},
            status: false,
            title: '',
            flag: 0,//0视频，1作品合集，2作品展示，500是默认
            role: 1,
            homeBasisData: {},//首页基本信息
            homeClassData: {},//首页班级信息
            homeCourseData: {},//首页视频课程标题
        }
    },
    created() {
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }

        if (this.$store.getters.getUserData === null) {
            this.$message({iconClass: 'not', message: '请先登录', center: true,});
            this.$router.push({path: '/', name: 'Login', params: {},})
        } else {
            let info = this.$store.getters.getUserData;
            this.role = this.$store.getters.getUserData.role;//看接口给返回什么数据
        }

        //首页基本信息
        this.homeBasisInfo();
        if (this.$route.query.flag) {
            this.flag = this.$route.query.flag;
        }
        // this.homeGroupList();//班级列表
        // this.homeCourseList();//视频课程标题
        if (this.$store.getters.getUserData.role === 2) {
            let self = this;
            self.getLockGamesByStudentInfo();
            setInterval(function () {
                self.getLockGamesByStudentInfo();
            }, 120000)//两分钟
        }
    },
    methods: {
        studentEmit(data) {
            this.studentData = data;
        },
        viewStatus(data) {
            this.flag = data;
        },
        topViewStatus(data) {
            console.log(data);
            this.flag = data;
        },
        transferData() {
            this.status = true;
            if (this.flag === 0) {
                if (this.$store.getters.getCourseData.length === 0) {
                    this.$refs.refVideoList.getLessonInfo(0, 2)
                    return;
                }
                console.log(this.$refs.refVideoList)
                if (this.$refs.refVideoList !== undefined) {
                    this.$refs.refVideoList.getLessonInfo(this.$store.getters.getCourseData[0].id, 2)
                }
            }
            if (this.flag === '1-0') {
                if (this.role === 1) {
                    this.$refs.worksRef.getWorksGatherList()
                } else {
                    this.$refs.studentRef.getWorksShowInfo(2)
                }

            }

            if (this.flag === '1-1') {
                this.$refs.showWRef.getStudentInfo();
                this.$refs.showWRef.getWorksTypeInfo();
                this.$refs.showWRef.getWorksShowInfo(2);
            }

            if (this.flag === '2-0') {
                this.$refs.studentRef.getStudentInfo();
            }

            if (this.flag === '3-0') {
                if (this.$store.getters.getCourseData.length === 0) {
                    this.$refs.unlockRef.getLessonInfo(-1, 2)
                } else {
                    this.$refs.unlockRef.getLessonInfo(this.$store.getters.getCourseData[0].id, 2)
                }
            }
        },
        //首页基本信息
        homeBasisInfo() {
            TeacherUser.getBasisInfo({}, ({type, list}) => {
                if (type === 200) {
                    this.homeBasisData = list;
                    this.homeGroupList();
                    localStorage.setItem("goodsCategory", list.bureau.goods_category);
                }
            })
        },
        //班级列表
        homeGroupList() {
            TeacherUser.getGroupList({}, ({type, list}) => {
                if (type === 200) {
                    console.log(list);
                    if (this.$store.getters.getGroupData === null) {
                        if (list.list.length !== 0) {
                            this.$store.commit('setGroupData', {
                                group_id: list.list[0].group_id,
                                group_name: list.list[0].group_name
                            });
                        }
                    }
                    this.homeClassData = list;
                    this.homeCourseList();
                }
            })
        },
        //首页课程标题
        homeCourseList() {
            const params = {
                group_id: this.$store.getters.getGroupData.group_id,
            }
            TeacherUser.getCourseList(params, ({type, list}) => {
                if (type === 200) {
                    this.homeCourseData = list;

                    // if (this.$store.getters.getCourseData === null) {
                    this.$store.commit('setCourseData', list.list);
                    // }
                    this.transferData();

                }
            })
        },
        //刷新视频接口
        topVideoList(course_id) {
            this.$refs.refVideoList.getLessonInfo(course_id, 3);
        },
        //每两分钟刷新一次游戏解锁
        getLockGamesByStudentInfo() {
            const params = {
                // group_id: this.$store.getters.getGroupData.group_id
            }
            UnlockModel.getLockGamesByStudent(params, ({type, list}) => {

                if (type === 200) {
                    localStorage.setItem('lockGameId', JSON.stringify(list));
                }
            })
        }
    },
    //作品展示
    worksStatus(data) {
        this.flag = data
    }
}
</script>

<style lang="less" scoped>
.video-class {
    background: #F4F4F4;
}

</style>
<style>
/*.van-overlay {*/
/*    z-index: 3000 !important;*/
/*}*/
.van-dialog {
    /*z-index: 3000 !important;*/
}

.el-dialog__wrapper {
    /*z-index: 2003 !important;*/
}
</style>
