import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class UnloadClass extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {
                // label: '书本纪AI正在为您准备适合您的题目...'
            },
        }
    }

    // 首页几班信息
    static getListUnlockedLessonNum(params, fn) {
        BaseCall.get('getListUnlockedLessonNum', {params: params}, ({type, data}, res) => {
            let list = data.data;
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }

    // 游戏列表
    static getListGames(params, fn) {
        BaseCall.get('getListGames', {params: params}, ({type, data}, res) => {
            let list = data.data;
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }

    // 游戏列表 /bureau/v1.2/getLockGamesByStudent
    static getLockGamesByStudent(params, fn) {
        BaseCall.get('getLockGamesByStudent', {params: params}, ({type, data}, res) => {
            let list = data.data;
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }

    // 查看是否可以打开编辑器
    static canUnlock(params, fn) {
        BaseCall.get('canUnlock', {params: params}, ({type, data}, res) => {
            let list = data.data;
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }
}
