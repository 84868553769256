<template>
  <header class="header_home user_reg" v-title data-title="登录">
    <div class="login-class">
      <div
        class="box-class"
        :style="{
          backgroundImage: 'url(' + loginBackImg + ')',
          backgroundRepeat: 'no-repeat',
        }"
      >
        <div class="back-class"></div>

        <div class="main-class">
          <div></div>
          <div class="login-box-class">
            <div class="h3-class">
              <h2>欢迎登录</h2>
            </div>
            <div class="login-msg-class">
              <div class="item-class">
                <span
                  style="
                    display: inline-block;
                    color: #b6b5b5;
                    font-size: 14px;
                    width: 100px;
                  "
                  >登录账号：</span
                >
                <el-input v-model="ruleForm.name"></el-input>
              </div>
              <div class="item-class">
                <span
                  style="
                    display: inline-block;
                    color: #b6b5b5;
                    font-size: 14px;
                    width: 100px;
                  "
                  >密<span style="opacity: 0">密码</span>码：</span
                >
                <el-input
                  type="password"
                  v-model="ruleForm.passwd"
                  @keyup.enter.native="loginClick()"
                ></el-input>
              </div>
              <div style="text-align: center; height: 16px; color: #ff8d15">
                {{ tipContent }}
              </div>
              <div
                class="item-class"
                style="
                  cursor: pointer;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                "
                @click="loginClick()"
              >
                登录
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Login from "../../models/Login/Login";

export default {
  name: "Login",
  data() {
    return {
      tipContent: "",
      loginBackImg: "./img/login/login-back6.png",
      institutionImg: "./img/login/institution.png",
      loginMesImg: "./img/login/login-message.png",
      ruleForm: {
        passwd: "",
        name: "",
      },
    };
  },
  beforeCreate() {
    console.log(navigator.userAgent);
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.$router.push({ path: "/ipad/login" });
      return false;
    }
  },
  computed: {
    getRole() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    // alert(navigator.userAgent)

    // console.log(this.getRole);
    // console.log('**');
    // console.log((this.$store.getters.getUserData));
    console.log(localStorage.getItem("adminInfoBoss"));
    if (localStorage.getItem("adminInfoBoss") != null) {
      window.location.href = "/new_boss/#/dashboard";
    }

    if (this.$store.getters.getUserData != null) {
      if (this.$store.getters.getUserData.role === 1) {
        this.$router.push({
          path: "/VideoList",
          name: "VideoList",
          params: {},
        });
      } else {
        this.$router.push({
          path: "/studentVideoList",
          name: "studentVideoList",
          params: {},
        });
      }
    }
  },
  methods: {
    loginClick() {
      //对象
      if (this.ruleForm.name === "") {
        this.tipContent = "请输入用户名";
        return false;
      }

      if (this.ruleForm.passwd === "") {
        this.tipContent = "请输入密码";
        return false;
      }

      const params = this.ruleForm;
      Login.postUserLogin(params, ({ type, list }) => {
        console.log(list);
        if (type === 200) {
          console.log(list);
          if (list.role === "teacher") {
            list.role = 1;
          } else if (list.role === "student") {
            list.role = 2;
          }

          console.log(list.role);
          if (list.role === "admin") {
            Login.getBasisInfo({ token: list.token }, ({ code, info }) => {
              console.log(code);
              if (code === 200) {
                localStorage.setItem(
                  "adminInfoBoss",
                  JSON.stringify({
                    ...list,
                    bureau_avatar: info.bureau.bureau_avatar,
                    bureau_id: info.bureau.bureau_id,
                    bureau_name: info.bureau.bureau_name,
                    goods_category: info.bureau.goods_category_array[0],
                    user_id: info.userinfo.user_id,
                  })
                );
                window.location.href = "/new_boss/#/dashboard";
              }
            });
          } else {
            this.$store.commit("setUserData", list);
            if (this.$store.getters.getUserData.role === 1) {
              this.$router.push({
                path: "/VideoList",
                name: "VideoList",
                params: {},
              });
            } else {
              this.$router.push({
                path: "/studentVideoList",
                name: "studentVideoList",
                params: {},
              });
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-class {
  width: 100%;

  .box-class {
    background-size: cover;
    width: 100%;
    height: 100vh;

    .back-class {
      margin-left: 36%;
      padding-top: 1%;
      color: #979797;

      span {
        margin-top: -10px;
      }

      .institution-class {
        width: 32px;
        height: 32px;
        vertical-align: middle;
      }
    }

    .main-class {
      position: relative;
      left: 64%;
      top: 26%;
      width: 34%;

      h1 {
        display: inline-block;
        margin-left: 20px;
        color: #ff8d15;
      }

      .institution-class {
        vertical-align: middle;
      }
    }

    .login-box-class {
      width: 84%;
      //height: 366px;
      background: #ffffff;
      box-shadow: 0px 0px 24px 0px rgba(255, 139, 12, 0.14);
      border-radius: 34px;
      //margin-top: 150px;
      padding-bottom: 30px;

      .h3-class {
        text-align: center;

        h2 {
          padding: 35px 0;
          color: #ffb159;
        }
      }

      .login-msg-class {
        width: 75%;
        margin: 0 auto;

        .item-class {
          background: #f4f4f4;
          padding: 6px 0 6px 10px;
          border-radius: 10px;
          display: flex;
          align-items: center;
        }

        .item-class:nth-child(2) {
          margin-top: 30px;
        }

        .item-class:nth-child(4) {
          color: #ffffff;
          margin-top: 30px;
          text-align: center;
          padding: 10px;
          background: linear-gradient(135deg, #ffbf1c 0%, #ff8b0c 100%);
          box-shadow: 0px 3px 3px 0px #fd9e36;
          border-radius: 32px;
        }
      }
    }
  }

  /deep/ .el-input__inner {
    border: 0;
    background: #f4f4f4;
    padding: 0;
  }

  /deep/ .el-input {
    //left: -14px
  }
}
</style>
