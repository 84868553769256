let env = process.env.NODE_ENV;
import { Dialog } from 'vant';
import store from '../store'

function $diaLog(text, status) {
    let JsonObj = '';
    if (status === 1) {
        JsonObj = { message: text, 'confirmButtonColor': '#FF8D15', 'showCancelButton': false }
    } else {
        JsonObj = { message: text, 'confirmButtonColor': '#FF8D15' }
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === false) {
        Dialog.confirm(JsonObj).then(r => {
            switch (status) {
                case 1:
                    localStorage.clear();
                    var cookies = document.cookie.split(";");
                    cookies.forEach(item => {
                        var eqPos = item.indexOf("=");
                        console.log(eqPos);
                        var name = eqPos > -1 ? item.substr(0, eqPos) : item;
                        console.log(name);
                        vueSetCookie(name, '', -1)
                    })

                    window.location.href = '/'
                    break;
            }
        }).catch(() => {
            // on cancel
        });
    } else {
        if (document.getElementById('error-tip-id') === null) {


            var divObj = document.createElement("div");
            divObj.id = "error-tip-id";

            divObj.innerHTML = `
            <style>
            #error-tip-id {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9999999;
                margin-top: -10%;
            }

            #error-z-index {
                width: 100%;
                height: 100vh;
                background: rgba(0, 0, 0, 0.3000);
                position: fixed;
                top: 0;
                left: 0;
                z-index: 3
            }

            #big-box-id {
                width: 40rem;
                background: #ff921d;
                border-radius: 2rem;
                position: relative;
                z-index: 12;
            }

            #big-box-id .info {
                margin: 1rem;
                background: #FFFFFF;
                border-radius: 2rem;
                padding: 2rem;
                position: relative;
            }

            #big-box-id .info .div1{
                font-size: 2.2rem;
                font-weight: bold;
                color: #FDA349;
                text-align: center;
                margin-top: 2rem;
            }
            #big-box-id .info .div2{
                margin: 5rem 0 2rem;
                display: flex;
                justify-content: space-around;
            }
            #big-box-id .info .div2 .error-btn{
                border-radius: 1.8rem;

                padding: 0.5rem 0.6rem;
            }

            #big-box-id .info .div2 .error-btn div{
                padding: 0.5rem 2.4rem;
                border-radius: 1.8rem;
                font-size: 2rem;
                font-weight: bold;
                transition: 0.2s all;
                position: relative;
                text-align: center;
            }

            #big-box-id .info .div2 .btn1{
                background: #FFFFFF;
                box-shadow: 0 0.2rem 0.1rem 0 #FA9860;
            }
            #big-box-id .info .div2 .btn1 div{
                background-image: linear-gradient(to right, #FEB169, #FB8000);
                box-shadow: 0 0.3rem 0.9rem 0.1rem rgba(251, 135, 16, 0.45);
                color: #FFFFFF;
            }

            #big-box-id .info .div2 .btn2{
                background: #FFFFFF;
                box-shadow: 0 0.2rem 0.1rem 0 #969594;
            }
            #big-box-id .info .div2 .btn2 div{
                background-image: linear-gradient(to right, #ffffff, #ffffff);
                box-shadow: 0 0.3rem 0.9rem 0.1rem rgba(161, 158, 156, 0.45);
                color: #999999;
            }
            </style>
            <div id="error-z-index"></div>
            <div id="big-box-id">
                <div class="info">
                    <div class="div1">
                        ${text}？
                    </div>
                    <div class="div2">
                        <div class="error-btn btn1" id="btnConfirm">
                            <div>确认</div>
                        </div>
                        <div class="error-btn btn2" id="btnClose">
                            <div>取消</div>
                        </div>
                    </div>
                </div>
            </div>
        `;
            document.body.appendChild(divObj)

            let btnConfirm = document.getElementById('btnConfirm');
            btnConfirm.onclick = () => {
                divObj.innerHTML = '';
                document.body.removeChild(document.getElementById('error-tip-id'));
                if (status === 1) {
                    localStorage.clear();
                    store.commit('setClearState')
                    window.location.href = '/#/ipad/login'
                }
            }

            let btnClose = document.getElementById('btnClose');
            btnClose.onclick = () => {
                divObj.innerHTML = '';
                document.body.removeChild(document.getElementById('error-tip-id'));
            }
        }
    }

}

function vueSetCookie(c_name, value, expire) {
    var date = new Date()
    date.setSeconds(date.getSeconds() + expire)
    document.cookie = c_name + "=" + escape(value) + "; expires=" + date.toGMTString()
    //console.log(document.cookie)
}

export default {
    appName: '',

    // 调试模式
    debug: false,

    // 运行环境
    env: env === "production" ? 'prod' : 'dev',

    // ui配置
    ui: {
        plugin: 'element',
    },

    // 图标配置
    icon: {
        // 默认大小
        defaultSize: '12px',
        // 默认左右两边间距
        defaultWeight: '5px',
        // 默认颜色
        defaultColor: '#99999'
    },

    // 接口配置
    api: {
        // 默认解析配置
        defaultMaps: [
            {
                type: 'success',
                data: {
                    message: 'data.message',
                    data: 'data.data',
                    item: 'data.data.item',
                    items: 'data.data.items',
                    page: 'data.data._meta',
                },
                items: {
                    'status': 200,
                    'data.status': 200,
                }
            },
            {
                type: 'error',
                data: {
                    message: 'data.message'
                },
                items: {
                    'status': 200,
                    'data.success': false,
                }
            },
        ],
        // 访问出错的处理函数
        // 这个时默认处理，如果在get或者post时传递了errorCb（第四个参数），则默认使用自定义处理，否则执行它
        errorCallBack(response, status) {
            console.log(status);
            switch (status) {
                case 500:
                    switch (response.data.code) {
                        case 499:
                            const status = 1;
                            $diaLog(response.data.message, status)
                            break;
                        default :
                            $diaLog(response.data.message)
                            break;
                    }
                    break;
            }
            // this.Dialog.confirm(
            //     {
            //         message: '<span style="font-size: 16px">确定退出吗？</span>',
            //         confirmButtonColor: '#FF8D15',
            //     })
            //     .then(() => {
            //         // on confirm
            //         localStorage.removeItem('info');
            //         this.$router.push({
            //             path: '/',
            //             name: 'Login',
            //             param: {}
            //         })
            //     })
            //     .catch(() => {
            //         // on cancel
            //     });
            // if(status === 401){
            // content.push('/site/login');
            // }
            // console.log( response, status );
        },

        // 授权信息
        // auth: {
        //   // 是否自动在header中添加授权校验信息
        //   autoAdd: true,
        //   // 授权header键名
        //   authName: 'Authorization',
        //   // 授权值模板，{token}表示具体的token值，将从store.state.user.getAccessToken()获取
        //   authTemplate: 'Bearer {token}',
        // },
    }
};
