<template>
    <div class="query-class">
        <div class="query-box-class">
            <div style="width: 94%;margin: 0 auto;padding: 20px 0;">
                <div style="padding: 40px 0;cursor: pointer" v-for="(item,index) in info">
                    <span style="color: #FF8E0D">{{ index + 1 }}</span>
                    <span style="color: #000000;margin-left: 20px;"
                          @click="videoUrlClick(item.video_url)">{{ item.name }}</span>
                    <span style="margin-left: 10px;">
                            <el-image class="code-but-class" :src="videoPlayIcon"
                                      fit="cover"
                                      style="width: 22px;height:22px;cursor: pointer;border-radius: 50%;"></el-image>
                        </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Query from "../../models/Query/Query";
import eventVue from "../../assets/js/event";

export default {
    name: "Query",
    data() {
        return {
            info: {},
            videoPlayIcon: "./img/bf-icon2.png"
        }
    },
    created() {
        this.getListFagsInfo();
        eventVue.$emit('topMenu', [{text: '系统帮助', type: 1, url: 'SystemHelp'}]);
    }, methods: {
        getListFagsInfo() {
            Query.getListFaqs({}, ({type, list}) => {
                if (type === 200) {
                    this.info = list;
                }
            })
        },
        videoUrlClick(url) {
            window.open(url)
        }
    }
}
</script>

<style lang="less" scoped>
.header_home {
    width: 86%;
}

.query-class {
    width: 100%;
    height: calc(99.7vh - 120px);

    .query-box-class::-webkit-scrollbar {
        width: 0 !important
    }

    .query-box-class {
        -ms-overflow-style: none;
    }

    .query-box-class {
        overflow: -moz-scrollbars-none;
    }

    .query-box-class {
        width: calc(100% - 40px);
        margin: 12px auto;
        height: calc(99.7vh - 175px);
        background: #FFFFFF;
        border-radius: 10px;
        font-size: 20px;
        //padding: 30px 0;
        overflow-y: scroll
    }

    .code-but-class {
        vertical-align: middle;
        margin-top: -2px;
    }
}
</style>
