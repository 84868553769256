<template>
  <div class="down-class">
    <div class="down-box-class">
      <div class="tool-class">
        <el-row :gutter="20" style="text-align: center">
          <el-col :span="4" v-for="(item, key) in info" :key="key">
            <div
              style="cursor: pointer"
              @click="toolClick(item.game_url, item.rec_id)"
            >
              <div>
                <el-image
                  class="menu-icon-class"
                  :src="item.game_img"
                  fit="cover"
                  style="width: 180px"
                ></el-image>
              </div>
              <div style="color: #818181">{{ item.game_name }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div v-if="unlockedAlert" ref="treeWrap">
      <div class="mask-layer-class" @click="unlockedAlert = false"></div>
      <div class="not-lesson">
        <div class="title">
          <h2>激活课时</h2>
        </div>

        <div class="main">
          <div class="item" v-for="(item, index) in unlockedList" :key="index">
            <div class="small-box">
              <div class="lesson-img">
                <img :src="item.image_url" alt="" />
              </div>
              <div class="lesson-name">
                课时名:
                <span>{{ item.lesson_name }}</span>
              </div>
              <div class="lesson-btn" @click="handleUamilisho(item)">激活</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import UnlockClass from "../../models/Home/UnloadClass";
import UnloadClass from "../../models/Home/UnloadClass";

export default {
  name: "Tool",
  data() {
    return {
      info: [
        {
          game_img: "https://storage.shubenji.cn/GuoBao/game/ico/tianping.png",
          game_name: "小天平",
          state: 1,
          game_url: "/miniGames/ShapeEquate/",
          rec_id: 1,
        },
        {
          game_img:
            "https://storage.shubenji.cn/GuoBao/game/ico/paopaogame.png",
          game_name: "泡泡打字",
          state: 1,
          game_url: "/miniGames/bubble-typing-game/",
          rec_id: 2,
        },
      ],
      unlockedAlert: false,
      unlockedList: [],
    };
  },
  created() {
    console.log(this.info);
    if (this.$store.getters.getUserData.role === 2) {
      this.initTool();
    }
    this.getListGamesInfo();
  },
  methods: {
    getListGamesInfo() {
      //添加缓存 只有学生端的时候
      UnlockClass.getListGames({}, ({ type, list }) => {
        if (type === 200) {
          list.forEach((item) => {
            item.game_url = item.game_url + "/";
          });
          this.info = list;
          if (this.$store.getters.getGameData !== null) {
            if (this.$store.getters.getGameData.length !== list.length) {
              this.$store.commit("setGameData", list);
            }
          } else {
            this.$store.commit("setGameData", list);
          }
        }
      });
    },
    toolClick(url, id) {
      let status = false;
      if (this.$store.getters.getUserData.role === 2) {
        if (
          this.$store.getters.getUnloadGroupData !== null &&
          this.$store.getters.getUnloadGroupData > 0
        ) {
          const params = {
            // group_id: this.$store.getters.getGroupData.group_id
          };
          UnlockClass.getLockGamesByStudent(params, ({ type, list }) => {
            if (type === 200) {
              status = true;
              localStorage.setItem("lockGameId", JSON.stringify(list));
              if (list.indexOf(id) > -1) {
                window.open(url);
              } else {
                this.$Dialog
                  .confirm({
                    message:
                      '<span style="font-size: 16px">请先解锁小游戏</span>',
                    confirmButtonColor: "#FF8D15",
                  })
                  .then(() => {});
              }
            }
          });
        } else {
          if (this.unlockedList.length !== 0) {
            this.unlockedAlert = true;
          }
        }
      }
      if (this.$store.getters.getUserData.role === 1) {
        console.log(url);
        console.log(135 + "测试");
        status = true;
        window.open(url);
      }
    },
    initTool() {
      //添加缓存 只有学生端的时候
      let _this = this;
      UnloadClass.getListUnlockedLessonNum({}, ({ type, list }) => {
        if (type === 200) {
          _this.$store.commit("setUnloadGroupData", list.list.lesson_num);
          this.unlockedList = list.list.unlocked;
          console.log(this.unlockedList);
        }
      });
    },
    /**激活*/
    handleUamilisho(item) {
      this.unlockedAlert = false;
      window.open(
        this.$fileName +
          "/#/lessonDetail3?lesson_id=" +
          item.lesson_id +
          "&lesson_name=" +
          item.lesson_name +
          "&group_id=" +
          this.$store.getters.getGroupData.group_id +
          "&unlock_group_id=" +
          item.group_id +
          "&course_id=" +
          item.course_id +
          "&unlock=unlock" +
          "&text=" +
          item.lesson_text,

        "_blank"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.down-class {
  width: 100%;
  //height: calc(99.7vh - 120px);

  .down-box-class {
    width: calc(100% - 40px);
    margin: 12px auto;
    height: calc(99.7vh - 175px);
    background: #ffffff;
    border-radius: 10px;
    overflow-y: scroll;

    .tool-class {
      padding: 20px 0;
    }
  }
}

.mask-layer-class {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(18, 18, 18, 0.64);
}

.not-lesson {
  position: fixed;
  width: 1080px;
  height: 680px;
  border-radius: 10px;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*向左向上分别平移自身的一半*/
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  z-index: 2;

  .title {
    text-align: center;
    margin-top: 20px;
  }

  /* 设置滚动条的样式 */

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* 滚动槽 */

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  /* 滚动条滑块 */

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.1);
  }

  .main {
    margin: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    height: 600px;
    overflow-y: scroll;

    .item {
      width: 25%;
      margin-bottom: 16px;

      .small-box {
        margin: 0 16px;
        background: #f6f6fb;
        border-radius: 10px;
        padding-bottom: 10px;

        .lesson-img {
          img {
            border-radius: 10px 10px 0 0;
            width: 100%;
            height: 150px;
          }
        }

        .lesson-name {
          margin: 0 10px;
          font-size: 14px;
          overflow: hidden;
          /*超出的部分隐藏起来。*/
          white-space: nowrap;
          /*不显示的地方用省略号...代替*/
          text-overflow: ellipsis;
          /* 支持 IE */

          span {
            font-weight: bold;
            color: #666666;
          }
        }

        .lesson-btn {
          background: #ff8b0c;
          margin: 10px 32px;
          border-radius: 22.5px;
          padding: 8px 0;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
        }

        .lesson-btn:hover {
          background: #f3b370;
        }
      }
    }
  }
}

/deep/ .el-dialog__wrapper student-list {
  z-index: 2030;
}

/deep/ .van-dialog {
  z-index: 2030;
}
</style>
