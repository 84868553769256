import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class Login extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {
                // label: '书本纪AI正在为您准备适合您的题目...'
            },
        }
    }

    // 分析报告
    static ReportInfo(params, fn) {
        BaseCall.get('ReportInfo', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 登录
    static getUserLogin(params, fn) {
        BaseCall.get('getUserLogin', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    static postUserLogin(params, fn) {
        BaseCall.post('getUserLogin', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 登录boss基础信息
    static getBasisInfo(params, fn) {
        BaseCall.get('getBasisInfo', {params: params}, ({type, data}, res) => {
            let info = data.data
            let code = type
            typeof fn === 'function' ? fn({code, info}) : '';
        });
    }

    // 修改密码
    static savePassword(params, fn) {
        BaseCall.post('savePassword', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    static saveUserInfo(params, fn) {
        BaseCall.post('saveUserInfo', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
