<template>
    <div class="list-class">
        <div class="list-box-class">
            <div class="table-class">
                <el-table
                    :data="worksList.list"
                    style="width: 100%"
                    :header-cell-style="setSellStyle"
                >
                    <el-table-column
                        label="作品信息"
                        width="180">
                        <template slot-scope="scope">
                            <span v-if="scope.row.class_name === '课后作业'">
                                    <el-image class="code-but-class" :src="worksImg1" fit="cover"
                                              style="width: 90%;height:90%;cursor: pointer;"></el-image>
                                </span>
                            <span v-else-if="scope.row.class_name === '课堂练习'">
                                    <el-image class="code-but-class" :src="worksImg2" fit="cover"
                                              style="width: 90%;height:90%;cursor: pointer;"></el-image>
                                </span>
                            <span v-else-if="scope.row.class_name === '自由编程'">
                                    <el-image class="code-but-class" :src="worksImg3" fit="cover"
                                              style="width: 90%;height:90%;cursor: pointer;"></el-image>
                                </span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="class_name">
                        <template slot="header" slot-scope="{}">
                            <div class="type_export">
                                {{ typeName }}<i class='el-icon-caret-bottom'></i>
                                <el-select v-model="typeName" @change="typeClick" placeholder="请选择">
                                    <el-option v-for="item in typeOptions" :key="item.class_id"
                                               :label="item.class_name"
                                               :value="item"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="lesson_name"
                        label="课时名称">
                        <template slot-scope="scope">
                            <div v-html="scope.row.lesson_name"></div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="work_name"
                        label="作品名">
                    </el-table-column>
                    <el-table-column
                        prop="issue_time"
                        label="最近发布时间"
                        width="360">
                        <template slot="header" slot-scope="{}">
                            <div class="month_export">
                                {{ timeName }}
                                <i class='el-icon-caret-bottom'></i>
                                <el-date-picker
                                    v-model="timeVal"
                                    type="date"
                                    @change="batchWithdrawalCommission"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                                </el-date-picker>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            {{ Number(scope.row.issue_time) * 1000 | formatDate3 }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="分享 | 查看作品 | 删除作品"
                        align="center">
                        <template slot-scope="scope">
                            <el-popover
                                placement="bottom"
                                width="322"
                                trigger="click">
                                <div style="padding: 10px;">
                                    <div
                                        :style="{backgroundImage:'url(' + shareBack + ')',backgroundRepeat:'no-repeat',cursor: 'pointer'}"
                                        style="background-size: 100% 100%;height: 40vh;">
                                        <div
                                            style="width:50px;height: 50px;border-radius: 50%;background: #FFFFFF;float: left;margin:16px 10px">
                                            <el-image round class="code-but-class" :src="qrData.bureau_avatar_url"
                                                      fit="cover"
                                                      style="width: 50px;height:50px;cursor: pointer;border-radius: 50%"></el-image>
                                        </div>
                                        <div style="width:calc(100% - 110px);float: left;margin:16px;color:#FFFFFF">
                                            {{ scope.row.nickname }}，在{{ qrData.bureau_name }}学习果宝编程课程。
                                        </div>
                                    </div>

                                    <el-row>
                                        <el-col :span="16" style="margin-top: 20px">
                                            <div
                                                style="padding: 10px 0;font-size: 18px;color: #FF8D15;font-weight: bold">
                                                {{ qrData.bureau_name }}
                                            </div>
                                            <div style="color: #FF8D15;font-weight: bold;padding-bottom: 10px">
                                                扫右边二维码看我的作品
                                            </div>
                                            <div style="padding-top: 10px;">
                                                <span><i class="el-icon-phone-outline"></i></span>
                                                &nbsp;
                                                <span>{{ qrData.bureau_phone }}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div style="margin-top:20px">
                                                <el-image slot="reference" class="code-but-class"
                                                          :src="qrData.qr_code"
                                                          fit="cover"
                                                          style="width: 100%;height:100%;"></el-image>
                                            </div>
                                        </el-col>
                                        <el-col :span="24">
                                            <div style="padding: 10px 0;">
                                                <span><i class="el-icon-map-location"></i></span>
                                                &nbsp;
                                                <span>{{ qrData.bureau_location }}</span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>

                                <el-image slot="reference" class="code-but-class" :src="shareIconImg" fit="cover"
                                          style="width: 20px;height:20px;cursor: pointer;"
                                          @click="shareQrClick(scope.row.work_id)"></el-image>
                            </el-popover>

                            &nbsp;&nbsp;
                            <span style="font-size: 24px;color: #FF8D15;cursor: pointer;position: relative;top:2px">
                                <el-image slot="reference" class="code-but-class" :src="checkIconImg" fit="cover"
                                          style="width: 26px;height:26px;cursor: pointer;"
                                          @click="unLockLessonNum > 0 ? downClick(scope.row.work_id) : alertLock()"></el-image>
                                <!--                                <i @click="downClick(scope.row.work_url)" class="el-icon-download"></i>-->
                            </span>
                            &nbsp;
                            <span style="font-size: 24px;color: #FF8D15;cursor: pointer;position: relative;">
                                <el-image slot="reference" class="code-but-class" :src="deleteIconImg" fit="cover"
                                          style="width: 20px;height:20px;cursor: pointer;"
                                          @click="deleteClick(scope.row.work_id,scope.$index)"></el-image>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="block" style="text-align: center">
                <el-pagination background layout="prev, pager, next,jumper, total"
                               :total="worksList.count" @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               :current-page.sync="currentPage3">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import eventVue from '../../assets/js/event.js'
import Works from "../../models/Home/Works";
import {formatDate} from "../../assets/js/date.js";
import Api from "../../models/Home/Api";

export default {
    name: "WorksList",
    data() {
        return {
            worksImg1: './img/works-1.png',
            worksImg2: './img/works-2.png',
            worksImg3: './img/works-3.png',
            shareBack: './img/share-back2.png',
            qrData: '',
            leaveStatus: true,
            textarea: '',
            shareIconImg: './img/share-icon.png',
            checkIconImg: './img/check-icon.png',
            leaveIconImg: './img/leave-icon.png',
            deleteIconImg: './img/delete-icon.png',
            typeOptions: [],
            timeVal: '',
            studentName: '学生姓名',
            typeName: '作品类型',
            timeName: '最近发布时间',
            currentPage3: 1,
            worksList: {},
            courseId: 0,
            page: 1,
            class_id: 0,
            issue_time: 0,
            worksImg: './img/work-img.jpg',
            unLockLessonNum: this.$store.getters.getUnloadGroupData
        }
    },
    created() {
        if (this.$store.getters.getCourseData.length !== 0) {
            this.courseId = this.$store.getters.getCourseData[0].id;
        }
        this.getWorksShowInfo();
        this.getWorksTypeInfo();
        // this.getUnLockLessonNum();
    },
    computed: {
        CourseData() {//监听store的数据
            return this.$store.getters.getCourseData
        }
    },
    watch: {
        CourseData() {
            this.getWorksShowInfo();
            this.getWorksTypeInfo();
        }
    },
    methods: {
        downClick(workId) {
            // console.log(this.unLockLessonNum);
            window.open("/gbscratch/?workId=" + workId);
            // const aTag = document.createElement('a')
            // aTag.href = workUrl //文件id
            // aTag.click()
        },
        getWorksTypeInfo() {
            Works.getWorksType({}, ({type, list}) => {
                if (type === 200) {
                    this.typeOptions = list.list;
                }
            })
        },
        //列表信息
        getWorksShowInfo(type = 1) {
            if (type === 2) {
                this.class_id = 0;
                this.issue_time = 0;
                this.timeVal = '';
                this.typeName = '类别';
                this.timeName = '最近发布时间';

            }
            let param;
            param = {
                class_id: this.class_id,
                issue_time: this.issue_time,
                group_id: this.$store.getters.getGroupData.group_id,
                page: this.page,
                limit: 10,
            }

            Works.getWorksShow(param, ({type, list}) => {
                if (type === 200) {
                    this.worksList = list;
                }
            })

        },
        //生成二维码中
        shareQrClick(work_id) {
            let param = {
                work_id: work_id,
            }
            Works.getWorksQrCode(param, ({type, list}) => {
                if (type === 200) {
                    this.qrData = list;
                }
            })
        },
        //留言按钮
        leaveClick(id) {
            this.$refs[`popover-` + id].doClose()

        },
        batchWithdrawalCommission(val) {
            this.timeVal = val;
            this.timeName = val;
            this.issue_time = new Date(val).getTime() / 1000;
            this.page = 1;
            this.getWorksShowInfo()
        },
        //类别点击
        typeClick(data) {
            this.typeName = data.class_name;
            this.class_id = data.class_id;
            this.page = 1;
            this.getWorksShowInfo()
        },
        //学生姓名点击
        studentClick(id) {
            this.studentName = this.studentOptions[id].label;
        },
        setSellStyle({row, column, rowIndex, columnIndex}) {
            if (column.label === '作品信息') {
                return "background:#FF8D0D;borderRadius: 5px 0 0 5px;color:#FFFFFF"
            } else if (column.label === '分享 | 源码下载') {
                return "background:#FF8D0D;borderRadius: 0 5px 5px 0;color:#FFFFFF"
            } else {
                return "background:#FF8D0D;color:#FFFFFF"
            }
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getWorksShowInfo();
            console.log(`当前页: ${val}`);
        },
        imgClick() {
            // eventVue.$emit('saveFontColor', 1)
            // //2 作品展示
            // this.$emit('viewCut', 2)
        },
        getUnLockLessonNum() {
            Api.fetchData({params: {}}, 'listUnlockedLessonNum', ({type, list}) => {
                if (type == 200) {
                    this.unLockLessonNum = list;
                }
            })
        },
        alertLock() {
            this.$message({iconClass: 'not', message: '请解锁课程', center: true});
        },
        //删除作品
        deleteClick(workId, key) {
            let _this = this;
            this.$Dialog.confirm(
                {
                    message: '确定删除此作品吗？',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    const params = {
                        work_id: workId
                    }
                    Works.deleteWork(params, ({type, list}) => {
                        if (type === 200) {
                            console.log(type)
                            this.getWorksShowInfo();
                            // this.worksList.list.forEach((item,index) => {
                            //     if(index === key){
                            //         delete this.worksList.list[index]
                            //     }
                            // })
                        }
                    });

                })
                .catch(() => {
                    // on cancel
                });
        }
    },
    filters: {
        /*
    时间格式自定义 只需把字符串里面的改成自己所需的格式
   */
        formatDate(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd");
        },
        formatDate2(time) {
            var date = new Date(time);
            return formatDate(date, "hh:mm:ss");
        },
        formatDate3(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd. hh:mm:ss");
        },
    },
}
</script>

<style lang="less" scoped>
.list-class {
    //width: 86%;
    height: calc(99.7vh - 120px);

    .list-box-class {
        width: 96%;
        margin: 15px auto;
        height: calc(99.7vh - 150px);
        background: #FFFFFF;
        border-radius: 30px;
        box-shadow: 0 15px 30px 0 #d7d7d7;

        .table-class::-webkit-scrollbar {
            width: 0 !important
        }

        .table-class {
            -ms-overflow-style: none;
        }

        .table-class {
            overflow: -moz-scrollbars-none;
        }

        .table-class {
            width: 96%;
            margin: 0 auto;
            padding: 2% 0;
            height: calc(99.7vh - 300px);
            overflow-y: scroll;
        }
    }

    /deep/ .el-pagination.is-background .btn-next, /deep/ .el-pagination.is-background .btn-prev, /deep/ .el-pagination.is-background .el-pager li {
        background-color: #FFFFFF;
        border: 1px solid #E3E3E3;
    }

    .month_export {
        position: relative !important;
        background-color: #FF8D15;
        border: none;
        cursor: pointer;

        .el-date-editor {
            background-color: darkblue !important;
            width: auto !important;
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            opacity: 0;
        }
    }

    .type_export {
        position: relative !important;
        background-color: #FF8D15;
        border: none;
        cursor: pointer;

        .el-select {
            background-color: darkblue !important;
            width: auto !important;
            position: absolute !important;
            top: -5px !important;
            left: 0 !important;
            opacity: 0;
        }
    }

    /deep/ .el-input__inner {
        cursor: pointer;
    }
}

/deep/ .el-textarea__inner {
    resize: none
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #FF8D0D;
    color: #FFF;
}

/deep/ .el-pagination.is-background .el-pager li.active {
    color: #fff;
    cursor: default;
}

/deep/ .el-pagination.is-background .el-pager li:hover {
    color: #FF8D0D;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #FF8D0D;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
    background-color: #FF8D0D;
    color: #FFF;
}
</style>
