import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class UploadFile extends BaseModel {

    /**
     * uploadfile
     */
    static getUploadFileConfig(params, fn) {
        BaseCall.get('getUploadFileConfig', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    static saveResource(params, fn) {
        BaseCall.post('saveResource', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
