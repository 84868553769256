import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class Student extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {

            },
        }
    }

    // 作品集合
    static getStudentList(params, fn) {
        BaseCall.get('getStudentList', { params: params }, ({ type, data }, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }

    //生成作品 学生
    static CreateWorks(params, fn) {
        BaseCall.post('createWorks', params, ({ type, data }, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }

    //生成作品 学生
    static stuLessonUnlock(params, fn) {
        BaseCall.get('stuLessonUnlock', { params: params }, ({ type, data }, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }

    //机构是否支持分配以及分配次数
    static getCartoonAmount(params, fn) {
        BaseCall.get('getCartoonAmount', { params: params }, ({ type, data }, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }
    //给孩子分配拍照权限
    static assignTicket(params, fn) {
        BaseCall.get('assignTicket', { params: params }, ({ type, data }, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }
    //撤销权限
    static cancelTicket(params, fn) {
        BaseCall.get('cancelTicket', { params: params }, ({ type, data }, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }
    //查看图片列表
    static getPhotoList(params, fn) {
        BaseCall.get('getPhotoList', { params: params }, ({ type, data }, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({ type, list }) : '';
        });
    }
    //老师上传图片
    static uploadCartoonTicketImage(params, fn) {
        BaseCall.post('uploadCartoonTicketImage', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
          });
    }
}
