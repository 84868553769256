<template>
    <div class="list-class">
        <div class="column">
            <div class="lesson-img" style="">&nbsp;&nbsp;&nbsp;&nbsp;封面</div>
            <div class="center subject-name">课程</div>
            <div class="center group-name">班级</div>
            <div class="center lesson-name">课时</div>
            <div class="center unlock-num">解锁学生人数</div>
            <div class="center edit">操作</div>
        </div>
        <div class="info-list">
            <div class="item-info" v-for="item in lessonData">
                <div class="lesson-img"><img :src="item.lesson_image" alt=""></div>
                <div class="center subject-name text-font-size">{{ $store.getters.getCourseData[0].title }}</div>
                <div class="center group-name text-font-size">{{ $store.getters.getGroupData.group_name }}</div>
                <div class="center lesson-name text-font-size">{{ item.lesson_name }}</div>
                <div class="center unlock-num text-font-size">{{ item.unlock_student_num }} / {{
                        item.student_num
                    }}
                </div>
                <div class="edit text-font-size">
                    <div class="unlock-btn" @click="handleUnlock(item)">
                        <span v-if="item.unlock_student_num === item.student_num">全部解锁</span>
                        <span v-else>解锁</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="block" style="text-align: center">
            <el-pagination
                background layout="prev, pager, next,jumper, total"
                :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
        </div>

        <el-dialog
            class="student-list"
            title="本班同学"
            :center="true"
            :visible.sync="studentListDialog"
            width="57%">
            <div class="overflow-class">
                <div style="display: flex">
                    <div class="tip-text-teacher">tip：默认全部选中</div>
                    <div class="checkbox-all">
                        <el-checkbox v-model="checked" @change="selectAllChange">{{
                                checked ? '取消全选' : '全选'
                            }}
                        </el-checkbox>
                    </div>
                </div>

                <div class="student-info">
                    <div class="item" v-for="item in lockStudentList" @click="handleSelectStu(item)"
                         :class="item.is_select ===true ? 'select-item' :''">
                        <div class="stu-avatar">
                            <img :src="item.student_avatar === '' ? headImg : item.student_avatar"
                                                     alt="">
                        </div>
                        <div class="stu-name">{{ item.student_name }}</div>
                        <div v-if="item.status === 1">剩余 <span style="color: #FF8B0C">{{
                                (item.left_duration / 60).toFixed(2)
                            }}</span>分钟
                        </div>
                        <div class="lesson-num" v-if="item.week_limit > 0">已解锁 <span style="color: #FF8D15;font-weight: bold">{{item.lesson_unlock_time}}</span> 次课时</div>
                        <div class="select-success" v-if="item.is_select ===true">✓</div>
                    </div>
                </div>
                <div class="fixed-box">
                    <div class="all-student" v-if="lockStudentList[0] !== undefined && lockStudentList[0].week_limit >0" style="margin-right: 20px">总剩余解锁 <span >{{ lockStudentList[0].lesson_unlock_time_limit}}</span>次</div>
                    <div class="all-student" >总人数 <span>{{ lockStudentList.length }}</span> 人</div>
                    <div class="all-student" style="margin-left: 20px">单个学生使用时长 <span>{{ unlockDuration }}</span> 分钟
                    </div>
                    <div class="all-student" style="margin-left: auto;margin-right: 20px">已选中<span>{{
                            selectStudentNum
                        }}</span> 人
                    </div>
                    <div class="all-student">总价<span>{{ totalMoney.toFixed(2) }}</span> 元</div>
                    <div class="button btn1" @click="handleUnlockConfirm">确定</div>
                </div>
            </div>
        </el-dialog>

        <el-dialog
            class="student-list"
            title="本次消耗"
            :center="true"
            :visible.sync="unlockConfirmState"
            width="28%">
            <div class="student-qr-text">
                    <span class="text-span left">本次消耗：<span
                        class="color-FF8B0C">{{ unlockMin }}</span>分钟</span>
                <span class="text-span right">价格：<span
                    class="color-FF8B0C">{{ totalMoney.toFixed(2) }}</span>元</span>
                <p style="clear:both;"></p>
            </div>
            <p class="student-qr-text">
                <span class="button btn2" @click="handleUnlockConfirm1">确定</span>
            </p>
        </el-dialog>
    </div>
</template>

<script>
import eventVue from '../../assets/js/event.js'
import LessonVideo from "../../models/Home/LessonVideo";
import Api from "../../models/Home/Api";
import Qs from "qs";

export default {
    props: {},
    name: "WorksList",
    data() {
        return {
            checked: true,
            page: 1,
            total: 0,
            lessonData: [],
            studentListDialog: false,
            lockStudentList: [],
            headImg: './img/default-head-img2.png',
            goodsCategory: localStorage.getItem("goodsCategory"),
            unlockPrice: 40,
            unlockDuration: 120,
            rec_id: 0,

            //总金额
            totalMoney: 0,
            selectStudentNum: 0,
            unlockMin: 0,

            unlockConfirmState: false,

            lesson_id: 0
        }
    },
    created() {
        this.getLessonInfo();
        if (this.goodsCategory === 'by_lesson_fee') {
            this.getLessonPrice(this.$store.getters.getCourseData[0].id);
        }
    },
    computed: {
        CourseData() {//监听store的数据
            return this.$store.getters.getCourseData
        }
    },
    watch: {
        CourseData() {
            this.getLessonInfo();
        }
    },
    methods: {
        getLessonInfo() {
            let params = {
                course_id: this.$store.getters.getCourseData[0].id,
                group_id: this.$store.getters.getGroupData.group_id,
                page: this.page,
                limit: 10,
            }
            LessonVideo.getLessonList(params, ({ type, list }) => {
                if (type === 200) {
                    this.total = list.count;
                    this.lessonData = list.list;
                }
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getLessonInfo(this.$store.getters.getCourseData[0].id);
            console.log(`当前页: ${val}`);
        },
        /**点击解锁*/
        handleUnlock(item) {
            console.log(item)
            this.studentListDialog = true;
            this.checked = true;
            this.lesson_id = item.lesson_id;
            const params = {
                lesson_id: item.lesson_id,
                group_id: this.$store.getters.getGroupData.group_id,
                page: 1,
                limit: 500
            }
            Api.fetchData(params, 'getLessonStatus', ({ type, list }) => {
                if (type === 200) {
                    list.list.forEach((item) => {
                        if (item.status === 0) {
                            item['is_select'] = true;
                        }
                    });
                    this.lockStudentList = list.list;
                    let totalMoney = 0;
                    let selectStudentNum = 0;
                    let unlockMin = 0;
                    this.lockStudentList.forEach(val => {
                        if (val['is_select'] === true) {
                            totalMoney += this.unlockPrice;
                            selectStudentNum += 1;
                            unlockMin += this.unlockDuration;
                        }
                    })
                    this.totalMoney = totalMoney;
                    this.selectStudentNum = selectStudentNum;
                    this.unlockMin = unlockMin;
                }
            })
        },
        // 查询价格
        getLessonPrice(course_id) {
            Api.fetchData({ 'course_id': course_id }, 'getLessonFee', ({ type, list }) => {
                if (type === 200) {
                    this.unlockPrice = list.fee;
                    this.unlockDuration = list.hours * 60;
                    this.rec_id = list.rec_id;
                }
            })
        },
        // 选中学生方法
        handleSelectStu(item) {
            if (item.status === 0) {
                item['is_select'] = !item['is_select'];
                let totalMoney = 0;
                let selectStudentNum = 0;
                let unlockMin = 0;
                this.lockStudentList.forEach(val => {
                    if (val['is_select'] === true) {
                        totalMoney += this.unlockPrice;
                        selectStudentNum += 1;
                        unlockMin += this.unlockDuration;
                    }
                })
                this.totalMoney = totalMoney;
                this.selectStudentNum = selectStudentNum;
                this.unlockMin = unlockMin;
            }
        },
        /**解锁弹窗*/
        handleUnlockConfirm() {
            if (this.selectStudentNum === 0) {
                this.studentListDialog = false;
            } else {
                this.unlockConfirmState = true;
            }
        },
        /**确定解锁*/
        handleUnlockConfirm1() {
            let student_ids = [];
            this.lockStudentList.forEach(val => {
                if (val['is_select'] === true) {
                    student_ids += val.student_id + ',';
                }
            })
            let stu_ids = student_ids.substring(0, student_ids.length - 1);

            let params = {
                'student_ids': stu_ids,
                'lesson_id': this.lesson_id,
                'group_id': this.$store.getters.getGroupData.group_id,
                'course_id': this.$store.getters.getCourseData[0].id,
                'rec_id': this.rec_id
            }
            LessonVideo.payUnlockLesson(params, ({ type, list }) => {
                console.log(type)
                if (type === 200) {
                    this.unlockConfirmState = false;
                    this.studentListDialog = false;
                    this.getLessonInfo();
                }else{
                    this.unlockConfirmState = false;
                    this.studentListDialog = false;
                    this.getLessonInfo();
                }
            })

            // Api.fetchData({
            //     'student_ids': stu_ids,
            //     'lesson_id': this.lesson_id,
            //     'group_id': this.$store.getters.getGroupData.group_id,
            //     'course_id': this.$store.getters.getCourseData[0].id,
            //     'rec_id': this.rec_id
            // }, 'payUnlockLesson', ({ type, list }) => {
            //     if (type === 200) {
            //         this.unlockConfirmState = false;
            //         this.studentListDialog = false;
            //         this.getLessonInfo();
            //     }
            // });
        },
        /**全选*/
        selectAllChange(val) {
            console.log(val)
            if (val === true) {
                let totalMoney = 0;
                let selectStudentNum = 0;
                let unlockMin = 0;
                console.log(this.lockStudentList)
                this.lockStudentList.forEach(item => {
                    if (item.status !== 1) {
                        item.is_select = true;
                        console.log(this.unlockPrice);
                        totalMoney += this.unlockPrice;
                        console.log(totalMoney);
                        selectStudentNum += 1;
                        unlockMin += this.unlockDuration;
                    }
                })
                this.totalMoney = totalMoney;
                this.selectStudentNum = selectStudentNum;
                this.unlockMin = unlockMin;
            } else {
                this.lockStudentList.forEach(item => {
                    if (item.status !== 1) {
                        item.is_select = false;
                    }
                })
                this.totalMoney = 0;
                this.selectStudentNum = 0;
                this.unlockMin = 0;
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "./unlockClass.less";
</style>
