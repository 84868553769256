<template>
    <div class="list-class">
        <div class="list-box-class">
            <div class="table-class" style="overflow-y: scroll">
                <el-table
                    :data="worksList.list"
                    style="width: 100%"
                    :header-cell-style="setSellStyle"
                >
                    <el-table-column
                        label="作品夹"
                        width="180">
                        <template slot-scope="scope">
                            <el-image class="code-but-class" :src="file" fit="cover"
                                      style="width: 90%;height:90%;cursor: pointer;"
                                      @click="imgClick(scope.row.student_name,scope.row.student_id)"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="num"
                        label="数量"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="nickname"
                        label="姓名">
                    </el-table-column>
                    <el-table-column
                        prop="student_name"
                        label="登录账号">
                    </el-table-column>
                    <el-table-column
                        prop="group_name"
                        label="班级">
                    </el-table-column>
                    <el-table-column
                        prop="issue_time"
                        label="最近发布时间">
                    </el-table-column>
                </el-table>
            </div>
            <div class="block" style="text-align: center">
                <el-pagination background layout="prev, pager, next,jumper, total"
                               :total="worksList.count" @size-change="handleSizeChange"
                               @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import eventVue from '../../assets/js/event.js'
import Works from "../../models/Home/Works";
import TeacherUser from "../../models/Home/TeacherUser";

export default {
    name: "WorksList",
    data() {
        return {
            file: './img/file-img.png',
            currentPage3: 5,
            worksList: {},
            tableData: {},
            page: 1,
        }
    },
    computed: {
        CourseData() {//监听store的数据
            return this.$store.getters.getCourseData
        }
    },
    created() {
        this.getWorksGatherList();
    },
    watch: {
        CourseData() {
            this.getWorksGatherList();
        }
    },
    methods: {
        setSellStyle({row, column, rowIndex, columnIndex}) {
            if (column.label === '作品夹') {
                return "background:#FF8D0D;borderRadius: 5px 0 0 5px;color:#FFFFFF"
            } else if (column.label === '最近发布时间') {
                return "background:#FF8D0D;borderRadius: 0 5px 5px 0;color:#FFFFFF"
            } else {
                return "background:#FF8D0D;color:#FFFFFF"
            }
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getWorksGatherList()
            console.log(`当前页: ${val}`);
        },
        imgClick(studentName, studentId) {
            window.location.href = this.localhost + '/#/WorksShow?student_name=' + studentName + '&student_id=' + studentId;

            // eventVue.$emit('saveFontColor', 1)
            // // //1.1 作品展示
            // this.$emit('studentData', {student_name: studentName, student_id: studentId})
            // console.log({student_name: studentName, student_id: studentId});
            // this.$emit('viewCut', '1-1')

        },

        //作品集合
        getWorksGatherList() {
            let param = {
                group_id: this.$store.getters.getGroupData.group_id,// 从缓存里面拿
                page: this.page,
                limit: 10,
            }

            Works.getWorksList(param, ({type, list}) => {
                if (type === 200) {
                    this.worksList = list;
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.list-class {
    width: 100%;
    //height: calc(99.5vh - 120px);

    .list-box-class {
        width: 96%;
        margin: 15px auto;
        height: calc(99.7vh - 150px);
        background: #FFFFFF;
        border-radius: 30px;
        box-shadow: 0 15px 30px 0 #d7d7d7;

        .table-class::-webkit-scrollbar {
            width: 0 !important
        }

        .table-class {
            -ms-overflow-style: none;
        }

        .table-class {
            overflow: -moz-scrollbars-none;
        }

        .table-class {
            width: 96%;
            height: calc(99.7vh - 300px);
            margin: 0 auto;
            padding: 2% 0;
        }
    }

    /deep/ .el-pagination.is-background .btn-next, /deep/ .el-pagination.is-background .btn-prev, /deep/ .el-pagination.is-background .el-pager li {
        background-color: #FFFFFF;
        border: 1px solid #E3E3E3;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #FF8D0D;
        color: #FFF;
    }

    /deep/ .el-pagination.is-background .el-pager li.active {
        color: #fff;
        cursor: default;
    }

    /deep/ .el-pagination.is-background .el-pager li:hover {
        color: #FF8D0D;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #FF8D0D;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
        background-color: #FF8D0D;
        color: #FFF;
    }
}
</style>
