<template>
    <div class="sidebar-class">
        <div class="sidebar-box-class">
            <div class="sidebar-top-class">
                <div class="head-img-class" v-if="info.userinfo.user_avatar !== ''"
                     @click="menuClick(0,'PersonalCenter')">
                    <el-image class="edit-header-class" :src="info.userinfo.user_avatar"
                              fit="cover"></el-image>
                </div>
                <div class="head-img-class" v-else @click="menuClick(0,'PersonalCenter')">
                    <el-image class="edit-header-class" :src="headBackImg"
                              fit="cover"></el-image>
                    <span>编辑</span>
                </div>
                <div class="nick-text-class">{{ info.userinfo.nickname }}</div>
                <div class="sign-text-class">{{ info.userinfo.slogan }}</div>
            </div>
            <div class="menu-class">
                <div v-for="(item,index) in menu" :key="index" class="menu-list-class"
                     :class="{'menu-color' : menuColor === index}" @click="menuClick(index,item.url)">
                    <div class="menu-cut-class">
                        <el-image v-if="item.type === 1" class="menu-icon-class" :src="item.cut_icon"
                                  fit="cover"></el-image>
                        <el-image v-else class="menu-icon-class" :src="item.icon" fit="cover"></el-image>

                        <span class="menu-text-class">{{ item.text }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import bus from "../common/bus";
import eventVue from '../../assets/js/event.js'
import Resources from "../../models/Resources";
import StudentTime from "../../models/Home/StudentTime";

export default {
    props: [
        'homeBasisData'],
    data() {
        return {
            menuColor: 0,
            headBackImg: './img/head-back.png',
            menu: Resources.SidebarData(this.$store.getters.getUserData.role),
            info: this.homeBasisData,
            lessonId: 0,
            lastLessonId: 0,
            timeNum: 0,
            theDuration: 0,
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace("/", "");
        },
        collapse() {
            return this.$store.state.collapse
        }
    },
    created() {
        let _this = this;
        eventVue.$on('onBasicInfo', function (message) {
            _this.info.userinfo.user_avatar = message.imageUrl;
            _this.info.userinfo.nickname = message.nickname;
            _this.info.userinfo.slogan = message.slogan;
        })

        eventVue.$on('headCutData', function (message) {
            _this.menu = message;
        })
        eventVue.$on('lessonId', function (message) {
            _this.lessonId = message;
            _this.syncTimeInfo();
        })

        if (this.info.userinfo === undefined) {
            this.info = {
                userinfo: {
                    user_avatar: './img/head-back.png',
                    nickname: '昵称',
                    slogan: '签名'
                }
            }
        }
        let router_name = '';
        if (this.$route.name === 'WorksShow') {
            router_name = 'WorksList';
        } else if (this.$route.name === 'studentWorksUpload') {
            router_name = 'studentWorksList';
        } else {
            router_name = this.$route.name;
        }

        this.menu.forEach(item => {
            if (item.url === 'VideoList') {
                item.top_menu = [{text: _this.$store.getters.getCourseData[0].title, type: 0, url: 'VideoList'}];
            }
            if (item.url === 'studentVideoList') {
                item.top_menu = [{text: _this.$store.getters.getCourseData[0].title, type: 0, url: 'studentVideoList'}];
            }
        })


        this.menu.forEach(item => {
            item.top_menu.forEach(val => {
                if (val.url === _this.$route.name) {
                    val.type = 1;
                } else {
                    val.type = 0;
                }
            })
        })
        this.menuType(this.menu, router_name)

        if (this.$store.getters.getUserData.role === 2) {
            this.syncTimeInfo();
            setInterval(function () {
                _this.syncTimeInfo();
            }, 300000) //60000 改成了5分钟
        }
    },
    methods: {
        syncTimeInfo() {
            const params = {
                time: parseInt((new Date()).getTime() / 1000),
                page_url: 'pageName',
                require_sync_time: 0,
                group_id: this.$store.getters.getGroupData.group_id,
                lesson_id: this.lessonId
            }

            if (params.lesson_id === 0) {
                return false;
            }

            StudentTime.getSyncTime(params, ({type, list}) => {
                if (type === 200) {
                    if (this.theDuration > 0 && list.data.duration <= 0 && this.lastLessonId == params.lesson_id) {
                        location.reload();
                    }
                    this.lastLessonId = params.lesson_id;

                    this.theDuration = list.data.duration;
                    this.timeNum = parseInt(list.data.duration * 60);
                    if (parseInt(list.data.duration * 60) >= 60) {
                        let h = parseInt(list.data.duration);
                        let m = parseInt(list.data.duration * 60) % 60;
                        this.timeNum = `${h}小时${m}`;
                    }
                }
            })
        },
        menuClick(index, url) {
            this.menuColor = index;
            // this.menuType(this.menu, url);

            const _this = this;
            let topMenu = [];
            let menuColor = '';

            this.menu.forEach(function (item, key) {
                if (url === item.url) {
                    _this.menu[key].type = 1;
                    menuColor = key;
                    item.top_menu.forEach(val => {
                        if (val.url === url) {
                            val.type = 1;
                        } else {
                            val.type = 0;
                        }
                    })
                    topMenu = item.top_menu;
                } else {
                    _this.menu[key].type = 0;
                }
            })

            this.menuColor = menuColor;
            eventVue.$emit('topMenu', topMenu);
            this.$router.push({path: url})
        },
        menuType(data, url) {
            const _this = this;
            let topMenu = [];
            let menuColor = '';
            data.forEach(function (item, key) {
                if (url === item.url) {
                    data[key].type = 1;
                    menuColor = key;
                    item.top_menu.forEach(val => {
                        // if (val.url === url) {
                        //     val.type = 1;
                        // } else {
                        //     val.type = 0;
                        // }
                    })
                    topMenu = item.top_menu;
                } else {
                    data[key].type = 0;
                }
            })
            this.menuColor = menuColor;
            this.menu = data;
            eventVue.$emit('topMenu', topMenu);
        },
    }
};
</script>

<style scoped lang="less">
.sidebar-class {
    .sidebar-box-class{
        width: 320px;
        background: #FFFFFF;
        margin-top: 20px;
    }
    .sidebar-top-class {
        border-bottom: 1px solid #F3F3F3;
        padding: 10% 0;
        text-align: center;
    }

    .head-img-class {
        position: relative;
        cursor: pointer;

        span {
            display: inline-block;
            position: absolute;
            left: 44%;
            bottom: 10%;
        }
    }

    .nick-text-class {
        font-size: 20px;
        font-weight: 600;
        padding-top: 20px;
    }

    .sign-text-class {
        color: #9C9C9C;
        padding-bottom: 10px;
    }

    .menu-class::-webkit-scrollbar {
        width: 0 !important
    }

    .menu-class {
        -ms-overflow-style: none;
    }

    .menu-class {
        overflow: -moz-scrollbars-none;
    }

    .menu-color {
        background: linear-gradient(135deg, #FFBF1C 0%, #FF8B0C 100%);
        border-radius: 30px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        color: #FFFFFF;
    }

    .menu-list-class:hover {
        background: linear-gradient(135deg, #f6dfa2 0%, #f8d1a7 100%);
        border-radius: 30px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        color: #FFFFFF;
    }

    .menu-class {
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        height: cale(100vh - 160px);

        .menu-list-class {
            margin-top: 20px;
            line-height: 32px;
            padding: 8px 0;
            cursor: pointer;
        }

        .menu-icon-class {
            vertical-align: middle;
            width: 32px;
            height: 32px;
            margin-left: 20%;
            //float: right;
        }

        .menu-text-class {
            margin-left: 5%;
        }
    }
}

.edit-header-class {
    width: 140px;
    height: 140px;
    border-radius: 50%;
}

.sidebar-class::-webkit-scrollbar {
    width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
</style>
