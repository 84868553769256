<template>
    <div class="staff-class">
        <div class="staff-box-class">
            <div class="min-box-class">
                <el-row :gutter="20" v-for="(item,index) in staffData" :key="index">
                    <el-col :span="3" style="text-align: center">
                        <div class="head-img">
                            <el-image class="header-class" :src="item.avatar" fit="cover"
                                      style="width: 100px;height:100px;border-radius: 50%"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <div ref="centers" class="staff-class">
                            <ul>
                                <li><h2>{{ item.name }}</h2></li>
                                <li style="color:#898989">
                                    {{ item.intro }}
                                </li>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div style="line-height: 15vh;text-align: right">
                            <el-popover
                                placement="bottom"
                                trigger="click">
                                <div class="code-share-class" style="text-align: center">
                                    <h3 style="color:#898989">电话：{{ item.phone }}</h3>
                                    <el-image slot="reference" class="code-but-class" :src="item.qr_code"
                                              fit="cover"
                                              style="width: 50%;height:50%;"></el-image>
                                    <div style="color:#c4c4c4;font-size: 18px">微信扫一扫</div>
                                </div>
                                <span
                                    style="font-size: 24px;font-weight: bold;line-height: 15vh;color:#898989;cursor: pointer;"
                                    slot="reference">联系我们吧</span>
                            </el-popover>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import Explain from "../../models/Home/Explain";
import Student from "../../models/Home/Student";

export default {
    name: "ExplainStaff",
    data() {
        return {
            qr: './img/qr-code.jpg',
            textarea: '',
            headImg: './img/login/institution.png',
            staffData: {},
        }
    },
    created() {
        this.getHelperStaffInfo();
    },
    mounted() {
    },
    methods: {
        getHelperStaffInfo() {
            Explain.getHelperStaffList({}, ({type, list}) => {
                if (type === 200) {
                    this.staffData = list;
                }
            })
        },
    }
}

</script>

<style lang="less" scoped>
.staff-class {
    width: 100%;
    //height: calc(99.7vh - 120px);

    .staff-box-class {
        width: calc(100% - 40px);
        margin: 12px auto;
        height: calc(99.7vh - 175px);
        background: #FFFFFF;
        border-radius: 10px;

    }

    .min-box-class {
        width: 90%;
        margin: 0 auto;
        padding: 20px 0;
    }

    .header-class {
        vertical-align: middle;
    }

    .head-img {
        line-height: 15vh
    }

    .staff-class {
        width: 100%;
        height: 15vh;
        overflow: hidden;
        display: flex;
        align-items: center;
    }
}
</style>
