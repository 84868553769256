export function setRemInit() {
    // postcss-px2rem的内容
    // 基准大小
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) === false) {
        const baseSize = 192;

        // 设置 rem 函数
        function setRem() {
            // 当前页面宽度相对于 1920 px(设计稿尺寸)的缩放比例，可根据自己需要修改。
            const scale = document.documentElement.clientWidth / 1920;
            // 设置页面根节点字体大小
            document.documentElement.style.fontSize = `${baseSize * scale}px`;
        }

        // 初始化
        setRem();
        // 改变窗口大小时重新设置 rem
        window.addEventListener("resize", setRem);

    } else {
        console.log(window.location.href.indexOf('shareWorksReport'))
        let baseSize = 10;
        if (window.location.href.indexOf('shareWorksReport') != -1) {
            const scale = document.documentElement.clientWidth / 1920;
            baseSize = 192 * scale;
        }

        // 设置 rem 函数
        function setRem() {
            // 当前页面宽度相对于 1920 px(设计稿尺寸)的缩放比例，可根据自己需要修改。
            const scale = document.documentElement.clientWidth / 1920;
            // 设置页面根节点字体大小
            document.documentElement.style.fontSize = `${baseSize}px`;
        }

        // 初始化
        setRem();
        // 改变窗口大小时重新设置 rem
        window.addEventListener("resize", setRem);
    }
}
