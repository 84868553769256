<template>
    <div class="upload-class">
        <div class="upload-box-class">
            <div class="main-box-class">
                <div style="color:#B2B2B2">当前班级{{ $store.getters.getGroupData.group_name }}</div>
                <div style="margin: 12px 0">
                    <el-checkbox v-model="checked"><span style="font-size: 20px">是否是课堂作品</span></el-checkbox>
                </div>

                <div style="margin: 30px 0;position: relative">
                    <span class="type_export">
                        {{ courseName }}<i class='el-icon-caret-bottom'></i>
                        <el-select v-model="courseName" :disabled="!checked" @change="typeClick"
                                   placeholder="请选择">
                            <el-option
                                v-for="(item,index) in courseOption"
                                :key="item.id"
                                :label="item.title"
                                :value="index">
                            </el-option>
                        </el-select>
                    </span>
                    <span class="type_export" style="margin-left:50px" v-if="lessonStatus">
                        {{ lessonName }}<i class='el-icon-caret-bottom'></i>
                        <el-select v-model="lessonName" :disabled="!checked" @change="lessonClick" placeholder="请选择">
                            <el-option
                                v-for="(item,index) in lessonOption"
                                :key="item.lesson_id"
                                :label="item.lesson_name"
                                :value="index">
                            </el-option>
                        </el-select>
                    </span>
                    <!--                    <div style="width: 100%;height:30px;background: pink;position: absolute;top: 0;opacity: 0.5"></div>-->
                </div>

                <div style="margin: 20px 0">
                    作品类型
                    <el-select v-model="typeName" :disabled="!checked" placeholder="请选择">
                        <el-option
                            v-for="(item,index) in typeOptions"
                            :key="item.class_id"
                            :label="item.class_name"
                            :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div style="margin: 20px 0">
                    作品名字
                    <el-input v-model="name" style="width: 197px" placeholder="请输入内容"></el-input>
                </div>
                <div style="width:300px">
                    <el-upload
                        class="upload-demo"
                        accept=".sb3"
                        :headers="headerObj"
                        action="/resource/v1/uploadFile"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :on-success="handleAvatarSuccess"
                        :before-remove="beforeRemove"
                        :limit="1"
                        :on-exceed="handleExceed"
                        :file-list="fileList">
                        <el-button size="small" type="primary">选择文件</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传sb3文件</div>
                    </el-upload>
                </div>
                <div style="margin: 30px 0">
                    <div @click="submitClick()"
                         style="cursor: pointer;width:100px;text-align: center;padding: 6px 0;border-radius: 22.5px;background: linear-gradient(135deg, #FFBF1C 0%, #FF8B0C 100%);color:#FFFFFF;">
                        上传作品
                    </div>
                </div>

                <div v-if="successUploadStatus" style="color: #07c160">上传成功</div>
            </div>
        </div>
    </div>
</template>

<script>
import LessonVideo from "../../models/Home/LessonVideo";
import Works from "../../models/Home/Works";
import Student from "../../models/Home/Student";
import Qs from 'qs';
import Login from "../../models/Login/Login";
import axios from "axios";

export default {
    name: "StudentWorksUpload",
    data() {
        return {
            // headerObj: {
            //     Token: token
            // },
            successUploadStatus: false,
            headerObj: {
                token: JSON.parse(localStorage.getItem('info')).token
            },
            name: '',
            courseName: '基本课程',
            courseId: 0,
            lessonStatus: false,
            lessonName: '第一课时',
            lessonId: 0,
            typeName: '',
            typeId: 0,
            textColor: 'red',
            checked: true,
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: '',
            fileList: [],
            courseOption: [],
            lessonOption: [],
            typeOptions: [],
            work_file_id: 0,
        }
    },
    watch: {
        checked(newVal, oldVal) {
            if (newVal === false) {
                this.typeName = 2;
                this.courseId = 0;
                this.courseName = '自由编程';
                this.lessonName = '自由编程';
            }
        }
    },
    created() {
        if (this.$store.getters.getCourseData.length !== 0) {
            this.courseOption = this.$store.getters.getCourseData;
            this.courseName = this.$store.getters.getCourseData[0].title;
            this.courseId = this.$store.getters.getCourseData[0].id;

            this.typeClick(0)
        }
        this.getWorksTypeInfo();
    },
    methods: {
        beforeAvatarUploadThree(params) {
            console.log(params);
            const file = params.file;
            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", params);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            // form.append("clientType", 'xxx');
            // 项目封装的请求方法，下面做简单介绍
            Works.getFileUpdate(form, ({type, list}) => {
                if (type === 200) {
                    // this.typeOptions = list.list;
                }
            })
        },
        getWorksTypeInfo() {
            Works.getWorksType({}, ({type, list}) => {
                if (type === 200) {
                    this.typeOptions = list.list;
                }
            })
        },
        //类别点击
        typeClick(index) {
            this.courseName = this.courseOption[index].title;
            this.courseId = this.courseOption[index].id;

            let params = {
                course_id: this.courseId,
                group_id: this.$store.getters.getGroupData.group_id,
                page: 1,
                limit: 500,
            }
            LessonVideo.getStudentLessonList(params, ({type, list}) => {
                if (type === 200) {
                    console.log(list);
                    if (list.list.length === 0) {
                        this.lessonStatus = false;
                        return this.$confirm('该课程下没有课时');
                    }

                    list.list.forEach(item => {
                        if (item.lesson_name.indexOf('<br>') > -1) {
                        item.lesson_name = item.lesson_name.replace("<br>", "-")
                        }
                    })

                    this.lessonOption = list.list;
                    console.log(list.list)
                    this.lessonStatus = true;
                    this.lessonName = list.list[0].lesson_name;
                    this.lessonId = list.list[0].lesson_id;
                }
            })
        },
        lessonClick(index) {
            this.lessonName = this.lessonOption[index].lesson_name;
            this.lessonId = this.lessonOption[index].lesson_id;
        },
        alertContent(text) {
            this.$Dialog.confirm(
                {
                    message: text,
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                })
                .catch(() => {
                    // on cancel
                });
        },
        submitClick() {
            this.successUploadStatus = false;
            if (this.typeName === "") {
                this.alertContent('<span style="font-size: 16px">请选择作品类型</span>')
                return;
            }

            if (this.lessonId === 0) {
                this.alertContent('<span style="font-size: 16px">该课程下没有课时，请切换课程</span>')
                return;
            }

            // console.log(this.checked);return;
            if (this.checked === false) {
                this.lessonId = 0;
            }
            // console.log(this.typeOptions);
            let categoryId = this.typeOptions[this.typeName].class_id;
            console.log(categoryId);
            const params = Qs.stringify({
                work_file_id: this.work_file_id,
                name: this.name,
                category: categoryId,
                lesson_id: this.lessonId,
                group_id: this.$store.getters.getGroupData.group_id,
                duration: 0,
                course_id: this.courseId,
            });
            console.log(params);
            Student.CreateWorks(params, ({type, list}) => {
                if (type === 200) {
                    this.successUploadStatus = true;
                    // this.$notify({
                    //     message: '上传作品成功',
                    //     offset: 100
                    // });
                }
            })
        },
        handleAvatarSuccess(res, file) {
            this.work_file_id = res.data.id;
        },
        handleRemove(file, fileList) {
        },
        handlePreview(file) {
        },
        handleExceed(files, fileList) {
            // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        }
    }
}
</script>

<style lang="less" scoped>
.upload-class {
    //width: 86%;
    height: calc(99.7vh - 120px);

    .upload-box-class {
        width: 96%;
        margin: 15px auto;
        //height: calc(99.7vh - 150px);
        background: #FFFFFF;
        border-radius: 30px;
        box-shadow: 0 15px 30px 0 #d7d7d7;


        .main-box-class {
            width: 96%;
            margin: 0 auto;
            padding: 30px 0;
            font-size: 18px;
        }

        .type_export {
            position: relative !important;
            //background-color: #FF8D15;
            //border: none;
            cursor: pointer;
            //float: left;
            padding: 10px 20px;
            border: 1px solid #DCDFE6;
            background: #F4F4F4;
            border-radius: 6px;

            .el-select {
                background-color: darkblue !important;
                width: auto !important;
                position: absolute !important;
                top: -5px !important;
                left: 0 !important;
                opacity: 0;
            }
        }

        /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #FFBA1A;
            border-color: #FFBA1A;
        }

        /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner :hover {
            background-color: #FFBA1A;
            border-color: #FFBA1A;
        }

        /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #606266;
        }

        /deep/ .el-input__inner {
            background: #F4F4F4;
        }
    }
}
</style>
