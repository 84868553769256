<template>
    <div class="down-class">
        <div class="down-box-class">
            <div style="width: 94%;margin: 0 auto;padding: 20px 0">
                <div v-for="item in info.list">
                    <h3>{{ item.category_name }}</h3>
                    <div style="margin:15px -30px;">
                           <span v-for="val in item.items" class="span-class">
                                <button @click="downloadZip(val.url)">{{ val.item_name }}</button>
                           </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import eventVue from '../../assets/js/event.js'
import ResourceDown from "../../models/Down/ResourceDown";

export default {
    name: "ResourceDown",
    data() {
        return {
            info: {}
        }
    },
    created() {
        this.getResourceDownInfo();
        eventVue.$emit('topMenu', [{text: '专属资源下载', type: 1, url: 'ResourceDown'}]);
    }, methods: {
        getResourceDownInfo() {
            const params = {
                page: 1,
                limit: 10,
            }
            ResourceDown.getExtraResource(params, ({type, list}) => {
                if (type === 200) {
                    this.info = list;
                }
            })
        },
        downloadZip(url) {
            const aTag = document.createElement('a')
            aTag.href = url //文件id
            aTag.click()
        }
    }
}
</script>

<style lang="less" scoped>
.header_home {
    width: 86%;
}

.down-class {
    width: 100%;
    height: calc(99.7vh - 120px);

    .down-box-class {
        width: calc(100% - 40px);
        margin: 12px auto;
        height: calc(99.7vh - 175px);
        background: #FFFFFF;
        border-radius: 10px;
        overflow-y: scroll;
        //padding: 30px 0;
        button {
            color: #FFFFFF;
            padding: 6px 16px;
            background: linear-gradient(129deg, #FFBE1C 0%, #FF8D0D 100%);
            border-radius: 36px;
            border: none;
            cursor: pointer;
        }

        .span-class {
            margin-left: 30px;
        }
    }
}
</style>
