<template>
    <div class="menu-class">
        <div class="menu-box-class">
            <div class="menu-top-class">
                <div class="user-class">
                    <span @click="handleAvatarClick()">
                        <el-image v-if="info.userinfo.user_avatar !==''" class="bureau-img-class"
                                  :src="info.userinfo.user_avatar"
                                  fit="cover">
                        </el-image>
                        <el-image v-else class="bureau-img-class" :src="avatarImg"
                                  fit="cover">
                        </el-image>
                    </span>


                    <div class="nick-name-class">
                        {{ info.userinfo.nickname }}
                    </div>
                </div>
                <CenterAlert v-if="alertState" class="alert-box-class" :homeBasisData="homeBasisData"
                             @alertState="alertState = alertState === false"></CenterAlert>
            </div>
            <div class="menu-main-class" style="color: black">
                <div class="left-menu-class" v-for="(item,index) in menu" :key="index"
                     :class="{'menu-color' :item.type === 1}" @click="menuClick(index,item.url)">
                    <div class="active-class"></div>
                    <div class="icon-class">
                        <svg-icon :icon-class="item.svg_icon" class="svg-icon-class"
                                  :class="{'menu-color' :item.type === 1}" />
                    </div>
                    <div class="title-class">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Resources from "../../models/Resources";
import eventVue from '../../assets/js/event.js'
import StudentTime from "../../models/Home/StudentTime";
import CenterAlert from "../../components/center/CenterAlert";

export default {
    props: [
        'homeBasisData'
    ],
    components: { CenterAlert },
    name: "Left",
    data() {
        return {
            avatarImg: './img/default-head-img2.png',
            groupIcon: './img/figure.png',
            pointIcon: './img/icon/point-icon.png',
            bureauIcon: './img/works-3.png',
            menu: Resources.SidebarData(this.$store.getters.getUserData.role),
            menuColor: 0,
            info: this.homeBasisData,
            lessonId: 0,
            alertState: false
        }
    },
    created() {
        // if(this.info.bureau.user_avatar === undefined){
        //     this.info.bureau.user_avatar = this.avatarImg;
        // }
        const _this = this;
        eventVue.$on('onBasicInfo', function (message) {
            _this.info.userinfo.user_avatar = message.imageUrl;
            _this.info.userinfo.nickname = message.nickname;
            _this.info.userinfo.slogan = message.slogan;
        })
        // eventVue.$on('lessonId', function (message) {
        //     _this.lessonId = message;
        //     _this.syncTimeInfo();
        // })

        let router_name = '';
        if (this.$route.name === 'WorksShow') {
            router_name = 'WorksList';
        } else if (this.$route.name === 'studentWorksUpload') {
            router_name = 'studentWorksList';
        } else if (this.$route.name === 'worksGather') {
            router_name = 'studentWorks';
        } else if (this.$route.name === 'singleStudentWorks' && this.$store.getters.getUserData.role === 1) {

            router_name = 'studentWorks';
        } else {
            router_name = this.$route.name;
        }

        // this.menu.forEach(item => {
        //     item.top_menu.forEach(val => {
        //         if (val.url === _this.$route.name) {
        //             val.type = 1;
        //         } else {
        //             val.type = 0;
        //         }
        //     })
        // })

        this.menuType(this.menu, router_name)

        // if (this.$store.getters.getUserData.role === 2) {
        //     this.syncTimeInfo();
        //     setInterval(function () {
        //         _this.syncTimeInfo();
        //     }, 300000) //60000 改成了5分钟
        // }
    },
    methods: {
        handleAvatarClick(){
            this.alertState = true;
        },
        syncTimeInfo() {
            const params = {
                time: parseInt((new Date()).getTime() / 1000),
                page_url: 'pageName',
                require_sync_time: 0,
                group_id: this.$store.getters.getGroupData.group_id,
                lesson_id: this.lessonId
            }

            if (params.lesson_id === 0) {
                return false;
            }

            StudentTime.getSyncTime(params, ({ type, list }) => {
                if (type === 200) {
                    if (this.theDuration > 0 && list.data.duration <= 0 && this.lastLessonId == params.lesson_id) {
                        location.reload();
                    }
                    this.lastLessonId = params.lesson_id;

                    this.theDuration = list.data.duration;
                    this.timeNum = parseInt(list.data.duration * 60);
                    if (parseInt(list.data.duration * 60) >= 60) {
                        let h = parseInt(list.data.duration);
                        let m = parseInt(list.data.duration * 60) % 60;
                        this.timeNum = `${h}小时${m}`;
                    }
                }
            })
        },
        menuClick(index, url) {
            this.menuColor = index;
            // this.menuType(this.menu, url);

            const _this = this;
            let topMenu = [];
            let menuColor = '';

            this.menu.forEach(function (item, key) {
                if (url === item.url) {
                    _this.menu[key].type = 1;
                    menuColor = key;
                    topMenu = item.top_menu;
                } else {
                    _this.menu[key].type = 0;
                }
            })

            this.menuColor = menuColor;
            this.$router.push({ path: url })
        },
        menuType(data, url) {
            console.log(url)
            const _this = this;
            let topMenu = [];
            let menuColor = '';
            data.forEach(function (item, key) {
                if (url === item.url) {
                    data[key].type = 1;
                    menuColor = key;
                    // item.top_menu.forEach(val => {
                    // if (val.url === url) {
                    //     val.type = 1;
                    // } else {
                    //     val.type = 0;
                    // }
                    // })
                    topMenu = item.top_menu;
                } else {
                    data[key].type = 0;
                }
            })
            console.log(data)
            this.menuColor = menuColor;
            this.menu = data;
        }
    }
}
</script>

<style lang="less" scoped>
.menu-class {
    .menu-box-class {
        display: flex;
        flex-direction: column;
        width: 320px;
        background: #FFFFFF;
        margin-top: 20px;
        border-radius: 10px 10px 0 0;
        height: calc(100vh - 160px);
        position: relative;

        .menu-top-class {
            width: 280px;
            margin: 0 auto;
            //flex-grow: 0.5;
            height: 120px;
            text-align: center;
            border-bottom: 1px solid rgba(18, 18, 18, 0.10196078431372549);

            .user-class {
                height: 120px;
                display: flex;
                justify-content: center;
                align-items: center;

                .bureau-img-class {
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    cursor: pointer;
                }

                .nick-name-class {
                    width: 100px;
                    color: #333333;
                    font-size: 20px;
                    font-weight: bold;
                    margin-left: 20px;
                }
            }

            .alert-box-class {
                position: absolute;
                top: 20px;
                left: 150px;
                overflow: -moz-scrollbars-none;
                z-index: 1;
            }
        }

        /* 设置滚动条的样式 */

        ::-webkit-scrollbar {
            width: 6px;
        }

        /* 滚动槽 */

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
            border-radius: 10px;
        }

        /* 滚动条滑块 */

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }

        ::-webkit-scrollbar-thumb:window-inactive {
            background: rgba(0, 0, 0, 0.1);
        }

        .menu-main-class {
            //margin: 10px 20px;
            flex: 4;
            height: calc(100vh - 420px);
            overflow-y: scroll;
            //background-color: red;
            //font-size: 15px;
            //color: #ffffff;
            text-align: center;
            //color: #FF8B0C;
            //font-size: 30px;
            margin-top: 10px;

            .left-menu-class {
                display: flex;
                height: 88px;
                align-items: center;

                .active-class {
                    width: 10px;
                    height: 50px;
                    //background: #FF8300;
                    opacity: 1;
                    border-radius: 0 10px 10px 0;
                }

                .icon-class {
                    width: 40px;
                    height: 40px;
                    //background: #80d577;
                    margin-left: 70px;
                    color: #666666;

                    .svg-icon {
                        width: 40px;
                        height: 40px;
                    }
                }

                .title-class {
                    margin-left: 26px;
                    font-size: 18px;
                    color: #666666;
                }
            }

            .left-menu-class:hover {
                cursor: pointer;
                color: #FFC17F;

                .title-class {
                    color: #FF8300;
                }
            }
        }
    }

    .menu-color {

        .active-class {
            background: #FF8B0C;
        }

        .svg-icon {
            color: #FF8300;
        }

        .title-class {
            color: #FF8300 !important;
        }
    }
}
</style>
