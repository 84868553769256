<template>
    <div class="share-alert-class" style="padding:10px;width: 322px;margin:0 auto;font-size: 14px;">
        <div class="mask-layer-class"></div>
        <div class="share-box-class" ref="treeWrap"
             :style="{backgroundImage:'url(' + shareBackImg + ')',backgroundRepeat:'no-repeat'}"
             style="border-radius: 10px;height: auto;padding: 10px">
            <Lottie :options="defaultOptions" class="lottie-class" />
            <div class="share-padding-class" style="
            display: flex;width: 320px;
            height: 30px;
            position: absolute;
            top: 10px;
            left: 10px;
">
                <!--                <i class="el-icon-close" @click="$emit('closeShareAlert')"></i>-->

                <div class="tip-text-class" style="
                font-weight: bold;
                width: 140px;
                height: 120px;
                color: #4D97FF;
                margin-left: auto;
                margin-top: 30px;
                display: flex;
                flex-flow: column;
                justify-content: end;">
                    <div class="tip-ok-class" style="letter-spacing:4px">我完成了</div>
                    <div class="work-name-class" style="letter-spacing:4px">{{ shareData.work_name }}作品</div>
                    <div class="hort-tip-class" style="
                    font-size: 20px;
                    margin-top: 4px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;">真的很棒
                    </div>
                </div>
            </div>
            <div class="work-show-img-class" style="
            width: 259.79px;
            height: 180px;
            position: absolute;
            top: 170px;
            right: 10px;">
                <div class="show-v-class"
                     style="
                width: 238.91px;
                height: 180px;
                background: #FFFFFF;
                margin: 0 auto;
                border-radius: 10px;">
                    <div class="works-look-class" style="
                    width: 231.81px;
                    height: 170px;
                    background: #FF8B0C;
                    position: relative;
                    left: 4px;
                    top: 5px;
                    border-radius: 10px;">
                        <img v-if="shareData.work_image_url !== '' && shareData.work_image_url != undefined"
                             :src="shareData.work_image_url" alt="" style="width: 100%;height: 100%;">
                        <img v-else :src="workImg" alt="" style="width: 100%;height: 100%;">
                    </div>
                </div>
                <div class="table-v-class" style=" width: 259.79px;
                height: 6px;
                background: #FFFFFF;
                border-radius: 10px;
                margin-top: -6px;"></div>
            </div>
            <div class="user-detail-class" style="width: 300px;
            height: 130px;
            background: #FFFFFF;
            border-radius: 10px;
            margin: 140px auto 10px auto;">
                <div class="user-box-class" style="width: 260px;
                height: 80px;
                margin: 0 auto;
                border-bottom: 1px solid rgba(18, 18, 18, 0.10196078431372549);">
                    <div class="main-class" style="display: flex;">
                        <div class="logo-class" style="width: 60px;
                        height: 60px;
                        margin-top: 10px;
                        border-radius: 10px;">
                            <img :src="shareData.bureau_avatar_url" alt="" style="width: 100%;height: 100%">
                        </div>
                        <div class="detail-class" style="width: 190px;
                        height: 60px;
                        margin-top: 10px;
                        margin-left: auto;
                        font-size: 12px;
                        color: #C4C4C4;">
                            <div class="name-class" style="font-weight: bold;
                            font-size: 16px;
                            color: #666666;
                            line-height: 24px;">{{ shareData.user_name }}
                            </div>
                            <div>在{{ shareData.bureau_name }}</div>
                            <div>学习果宝编程课程</div>
                        </div>
                    </div>
                    <div class="works-number-class" style="width: 260px;
                    height: 50px;
                    text-align: center;
                    font-size: 12px;
                    color: #C4C4C4;
                    margin-top: 10px;
                    line-height: 50px;">
                        累加完成作品 &nbsp;&nbsp;&nbsp;&nbsp;
                        <span style="font-size: 16px;
                        font-weight: bold;
                        color: #666666;">{{ shareData.work_num }}件</span>
                    </div>
                </div>
            </div>
            <div class="bureau-detail-class" style="
            width: 280px;
            height: 140px;
            background: #FFFFFF;
            border-radius: 10px;
            margin: 0 auto;
            padding: 10px;">
                <div class="basic-class" style="width: 100%;
                display: flex;
                margin-top: -10px;">
                    <div class="left-class" style="width: 194px;
                    height: 130px;
                    font-size: 12px;
                    color: #666666;">
                        <div class="bureau-class" style=" margin-top: 10px;">
                            <img :src="iconData.bureau" alt="" class="icon-img-class" style="width: 30px;
                        height: 30px;
                        vertical-align: middle;">
                            <span>{{ shareData.bureau_name }}</span>
                        </div>
                        <div class="phone-class" style=" margin-top: 10px;">
                            <img :src="iconData.phone" alt="" class="icon-img-class" style="width: 30px;
                        height: 30px;
                        vertical-align: middle;">
                            <span v-if="shareData.group_phone !==undefined && shareData.group_phone !==''">{{
                                    shareData.group_phone
                                }}</span>
                            <span v-else>{{ shareData.bureau_phone }}</span>
                        </div>
                        <div class="address-class" style=" margin-top: 10px;display: flex;">
                            <div>
                                <img :src="iconData.seat" alt="">
                            </div>
                            <!--                            <span>{{ // shareData.bureau_location }}</span>-->

                            <div class="text-class" style="">
                                {{ shareData.bureau_location }}
                            </div>
                        </div>
                    </div>
                    <div class="right-class" style="width: 70px;
                    height: 130px;">
                        <div class="but-class" style="width: 90px;
                        height: 30px;
                        line-height: 30px;
                        margin-top: 10px;
                        text-align: center;
                        font-size: 12px;
                        color: #FFFFFF;
                        background: #4D97FF;
                        border-radius: 10px;">
                            扫码查看作品
                        </div>
                        <div class="img-class" style="width: 90px;
                        height: 90px;
                        margin-top: 10px;">
                            <img :src="shareData.qr_code" alt="" style="width: 100%;height: 100%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as animationData from '../../assets/json/share-back-img.json';
import Lottie from 'vue-lottie/src/lottie.vue'

export default {
    props: ['shareData'],
    name: "shareAlert",
    components: {
        Lottie
    },
    mounted() {
        console.log(this.shareData)

        let _this = this;
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                if (!tree.contains(e.target)) {
                    _this.$emit('closeShareAlert')
                    // this.isShowTree =false
                }
            }
        })
    },
    data() {
        return {
            shareBackImg: './img/share-background.png',
            iconData: {
                bureau: './img/bureau-icon.png',
                phone: './img/phone-icon.png',
                seat: './img/seat-icon.png',
            },
            defaultImg: './img/file-img1.jpg',
            defaultOptions: {
                animationData: animationData.default
            },
            workImg: './img/bf-works.png'
        }
    }
}
</script>

<style lang="less" scoped>
//.share-alert-class {
//    width: 100%;
//    // 遮罩层
//    .mask-layer-class {
//        position: fixed;
//        left: 0;
//        top: 0;
//        width: 100%;
//        height: 100vh;
//        background: rgba(18, 18, 18, 0.64);
//    }
//
//    .share-box-class {
//        position: fixed;
//        width: 340px;
//        height: 680px;
//        border-radius: 10px;
//        background-image: url("../../assets/img/share-background.png");
//        top: 50%;
//        left: 50%;
//        transform: translate(-50%, -50%); /*向左向上分别平移自身的一半*/
//        -webkit-transform: translate(-50%, -50%);
//        -moz-transform: translate(-50%, -50%);
//        z-index: 2;
//
//        .lottie-class {
//            height: auto !important;
//            z-index: 3;
//        }
//
//        .share-padding-class {
//            width: 320px;
//            height: 30px;
//            position: absolute;
//            top: 10px;
//            left: 10px;
//            z-index: 1;
//            display: flex;
//
//            i {
//                font-size: 30px;
//                margin-left: 10px;
//                color: #C4C4C4;
//                cursor: pointer;
//            }
//
//            i:hover {
//                color: #4D97FF;
//            }
//
//            .tip-text-class {
//                font-weight: bold;
//                width: 140px;
//                height: 120px;
//                color: #4D97FF;
//                margin-left: auto;
//                margin-top: 30px;
//                display: flex;
//                flex-flow: column;
//                justify-content: end;
//
//                .tip-ok-class {
//                    letter-spacing: 4px;
//
//                }
//
//                .tip-ok-class {
//                    letter-spacing: 4px;
//                    white-space: nowrap;
//                    overflow: hidden;
//                    text-overflow: ellipsis;
//                }
//
//                .work-name-class {
//                    letter-spacing: 4px;
//                }
//
//                .hort-tip-class {
//                    font-size: 20px;
//                    margin-top: 4px;
//                    white-space: nowrap;
//                    overflow: hidden;
//                    text-overflow: ellipsis;
//                }
//            }
//        }
//
//        .work-show-img-class {
//            width: 259.79px;
//            height: 180px;
//            position: absolute;
//            top: 170px;
//            right: 10px;
//
//            .show-v-class {
//                width: 238.91px;
//                height: 180px;
//                background: #FFFFFF;
//                margin: 0 auto;
//                border-radius: 10px;
//
//                .works-look-class {
//                    width: 231.81px;
//                    height: 170px;
//                    background: #FF8B0C;
//                    position: relative;
//                    left: 4px;
//                    top: 5px;
//                    border-radius: 10px;
//
//                    img {
//                        width: 100%;
//                        height: 100%;
//                    }
//                }
//            }
//
//            .table-v-class {
//                width: 259.79px;
//                height: 6px;
//                background: #FFFFFF;
//                border-radius: 10px;
//                margin-top: -6px;
//            }
//        }
//
//        .user-detail-class {
//            width: 300px;
//            height: 130px;
//            background: #FFFFFF;
//            border-radius: 10px;
//            margin: 135px auto 10px auto;
//
//            .user-box-class {
//                width: 260px;
//                height: 80px;
//                margin: 0 auto;
//                border-bottom: 1px solid rgba(18, 18, 18, 0.10196078431372549);
//
//                .main-class {
//                    display: flex;
//
//                    .logo-class {
//                        width: 60px;
//                        height: 60px;
//                        margin-top: 10px;
//                        border-radius: 10px;
//
//                        img {
//                            width: 100%;
//                            height: 100%;
//                        }
//                    }
//
//                    .detail-class {
//                        width: 190px;
//                        height: 60px;
//                        margin-top: 10px;
//                        margin-left: auto;
//                        font-size: 12px;
//                        color: #C4C4C4;
//
//                        div {
//                            white-space: nowrap;
//                            overflow: hidden;
//                            text-overflow: ellipsis;
//                        }
//
//                        .name-class {
//                            font-weight: bold;
//                            font-size: 16px;
//                            color: #666666;
//                            line-height: 24px;
//                        }
//                    }
//                }
//
//                .works-number-class {
//                    width: 260px;
//                    height: 50px;
//                    text-align: center;
//                    font-size: 12px;
//                    color: #C4C4C4;
//                    margin-top: 10px;
//                    line-height: 50px;
//
//                    span {
//                        font-size: 16px;
//                        font-weight: bold;
//                        color: #666666;
//                    }
//                }
//            }
//        }
//
//        .bureau-detail-class {
//            width: 280px;
//            height: 140px;
//            background: #FFFFFF;
//            border-radius: 10px;
//            margin: 0 auto;
//            padding: 10px;
//
//            .basic-class {
//                //width: 194px;
//                width: 100%;
//                display: flex;
//                margin-top: -10px;
//
//                .left-class {
//                    width: 194px;
//                    height: 130px;
//                    font-size: 12px;
//                    color: #666666;
//
//                    .bureau-class, .phone-class, .address-class {
//                        margin-top: 10px;
//
//                        span {
//                            margin-left: 5px;
//                        }
//                    }
//
//                    .address-class {
//                        display: flex;
//                        //background: #FF8B0C;
//                        .text-class {
//                            margin-top: 5px;
//                            word-break: break-all;
//                            text-overflow: ellipsis;
//                            overflow: hidden;
//                            display: -webkit-box;
//                            -webkit-line-clamp: 4;
//                            -webkit-box-orient: vertical;
//                            margin-left: 5px;
//                        }
//                    }
//
//                    .icon-img-class {
//                        width: 30px;
//                        height: 30px;
//                        vertical-align: middle;
//                    }
//                }
//
//                .right-class {
//                    width: 70px;
//                    height: 130px;
//                    margin-left: 8px;
//
//                    .but-class {
//                        width: 90px;
//                        height: 30px;
//                        line-height: 30px;
//                        margin-left: -10px;
//                        margin-top: 10px;
//                        text-align: center;
//                        font-size: 12px;
//                        color: #FFFFFF;
//                        //padding: 10px 20px;
//                        background: #4D97FF;
//                        border-radius: 10px;
//                    }
//
//                    .img-class {
//                        width: 70px;
//                        height: 70px;
//                        background: #FF8B0C;
//                        margin-top: 10px;
//
//                        img {
//                            width: 100%;
//                            height: 100%;
//                        }
//                    }
//                }
//            }
//        }
//    }
//}

</style>
