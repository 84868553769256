import router from "./index";
import qs from 'qs';

const USE_IFRAME = true;

/**
 * Resolve the path according to current context
 * @param {string} path Path to be resolved
 * @returns Resolved path
 */
export function resolvePath(path = "/") {
  return new URL(path, window.location.href).href
}

export const cdnUrl = resolvePath()

/**
 * Navigate to target editor
 * @typedef GuobaoJrOptions
 * @property {"guobaojr"|"jr"} target
 * @property {number|undefined} stuLessonId
 * @property {number|undefined} workId
 * @param {GuobaoJrOptions} options
 */
export function navToEditor(options) {
  const { target, stuLessonId, workId } = options;
  const url = resolvePath("/" + target + "/");
  if (USE_IFRAME) {
    router.push({
      path: "/scratchJr",
      query: { url, stuLessonId, workId },
    });
  } else {
    let query = qs.stringify({ stuLessonId, workId })
    if (query.length > 0) query = `?${query}&aa=1`
    window.open(url + query, "_blank")
  }
}
