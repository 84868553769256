import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { setRemInit } from './assets/js/rem';
import VideoPlayer from 'vue-video-player'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vant from 'vant';
import 'vant/lib/index.css';
import { Dialog } from "vant";
import './assets/font/icon/iconfont.css'
import './icons';
// import './registerServiceWorker'

// import lottie from "vue-lottie";
import Vconsole from 'vconsole';
import { cdnUrl } from './router/navs'

// import './registerServiceWorker'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.use(Vant);
Vue.use(VueAxios, axios);
Vue.use(VideoPlayer)
// Vue.component("lottie", lottie);
setRemInit();
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.isExploit = "test";
Vue.prototype.$Dialog = Dialog;
// Vue.prototype.localhost = 'http://192.168.50.29:39090';
// Vue.prototype.localhost = '/pro';

// Vue.prototype.$ipAddress    = "http://192.168.50.45:20000";

// Vue.prototype.$cdnUrl = "https://service.guocode.com";
Vue.prototype.$cdnUrl = cdnUrl
Vue.prototype.$fileName = '/pro';

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
