<template>
    <header class="header_home user_reg" v-title data-title="课时教案">
        <div class="plan-class">
            <div class="main-class" v-html="planText"></div>
        </div>
    </header>
</template>

<script>
import LessonVideo from "../../models/Home/LessonVideo";
import axios from "axios";

export default {
    name: "PlanView",
    data() {
        return {
            planText: '',
        }
    },
    created() {
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }
        LessonVideo.getInfoLesson({lesson_id: this.$route.query.lesson_id}, ({type, list}) => {
            if (type === 200) {
                console.log(list)
                this.planText = list.lesson_plan;
            }
        })
    }
}
</script>

<style lang="less" scoped>
.plan-class {
    width: 100%;

    .main-class {
        width: 80%;
        margin: 0 auto;
    }
}
</style>
