import { apiRequest } from "./apiRequest";
let url;
if (process.env.VUE_APP_FLAG == "production") {
    url = 'https://api.shubenji.cn/';
} else if (process.env.VUE_APP_FLAG == "test") {
    url = "http://mh.noo.ink:39090/";//正式
} else {
    url = "";
}
let cndTestUrl = apiRequest();
export default {
    getFileUpdate: {
        url: 'http://192.168.50.216:3000/resource/v1/uploadFile'
    },
    test: {
        url: cndTestUrl + '/bureau/v1/test'
    },
    //首页基础信息
    getUserLogin: {
        url: cndTestUrl + '/bureau/v1/user/login'
    },
    //首页基础信息
    getBasisInfo: {
        url: cndTestUrl + '/bureau/v1/home/basisInfo'
    },
    //首页班级信息
    getGroupList: {
        url: cndTestUrl + '/bureau/v1/teacher/groupList'
    },
    //首页课程信息
    getCourseList: {
        url: cndTestUrl + '/edu/v1/user/courseList'
    },
    //课时列表信息
    getLessonList: {
        url: cndTestUrl + '/bureau/v1/teacher/lessonList'
    },
    //课时列表信息
    getWorksList: {
        url: cndTestUrl + '/bureau/v1/teacher/workGather'
    },
    //作品展示
    getWorksShow: {
        url: cndTestUrl + '/bureau/v1/teacher/workList'
    },
    //生成二维码
    getWorksQrCode: {
        url: cndTestUrl + '/bureau/v1/getWorkQrCode'
    },
    //学生列表
    getStudentList: {
        url: cndTestUrl + '/bureau/v1/teacher/studentList'
    },
    //课时列表 上锁
    saveLessonLock: {
        url: cndTestUrl + '/bureau/v1/teacher/lessonLock'
    },
    //课时列表 上锁
    getHelperStaffList: {
        url: cndTestUrl + '/bureau/v1/helper'
    },
    //学生课时视频列表
    getStudentLessonList: {
        url: cndTestUrl + '/bureau/v1/student/lessonList'
    },
    //学生课时视频列表
    savePassword: {
        url: cndTestUrl + '/bureau/v1/teacher/resetPassword'
    },
    //学生课时视频列表
    getWorksType: {
        url: cndTestUrl + '/bureau/v1/workClassList'
    },
    //学生课时视频列表
    saveEvaluateWork: {
        url: cndTestUrl + '/bureau/v1/teacher/evaluateWork'
    },
    //学生课时视频列表
    saveUserInfo: {
        url: cndTestUrl + '/bureau/v1/user/update'
    },
    //学生课时视频列表
    createWorks: {
        url: cndTestUrl + '/bureau/v1/createWork'
    },
    //学生课时视频列表
    getShareReport: {
        url: cndTestUrl + '/bureau/v1/report'
    },
    //学生课时视频列表
    updateDuration: {
        url: cndTestUrl + '/bureau/v1/updateDuration'
    },
    //学生课时视频列表
    getScratchBase: {
        url: cndTestUrl + '/bureau/v1/getWorks'
    },
    //学生课时视频列表
    getScratchBase2: {
        url: cndTestUrl + '/bureau/v1/getWorks2'
    },
    //学生课时视频列表
    getInfoLesson: {
        url: cndTestUrl + '/edu/v1/infoLesson'
    },
    //学生课时视频列表
    getExtraResource: {
        url: cndTestUrl + '/bureau/v1/listExtraResource'
    },
    //学生课时视频列表
    getListFaqs: {
        url: cndTestUrl + '/bureau/v1/listFaqs'
    },//获取同步时间
    getSyncTime: {
        url: cndTestUrl + '/bureau/v1.2/syncTime'
    },
    //获取解锁课程数
    listUnlockedLessonNum: {
        url: cndTestUrl + '/bureau/v1.2/listUnlockedLessonNum'
    },
    // 获取班级下学生解锁状态列表
    getLessonStatus: {
        url: cndTestUrl + '/bureau/v1.2/teacher/getLessonStatus'
    },
    // 给学生解锁课时
    payUnlockLesson: {
        url: cndTestUrl + '/bureau/v1.2/payUnlockLesson'
    },
    // 给学生解锁课时
    getLessonFee: {
        url: cndTestUrl + '/bureau/v1.2/getLessonFee'
    },
    // 给学生解锁课时
    getListUnlockedLessonNum: {
        url: cndTestUrl + '/bureau/v1.2/listUnlockedLessonNum'
    },
    // 给学生解锁课时
    getListGames: {
        url: cndTestUrl + '/bureau/v1.2/listGames'
    },
    // 删除作品
    deleteWork: {
        url: cndTestUrl + '/bureau/v1/student/workDel'
    },
    // 学生解锁游戏
    getLockGamesByStudent: {
        url: cndTestUrl + '/bureau/v1.2/getLockGamesByStudent'
    },
    // 学生解锁游戏
    getUploadFileConfig: {
        url: cndTestUrl + '/bureau/v1/upload'
    },
    // 课时步骤列表
    getListLessonStep: {
        url: cndTestUrl + '/admin/v1/listLessonStep'
    },
    // 课时步骤内容详情
    getListLessonTemCon: {
        url: cndTestUrl + '/admin/v1/listLessonTemCon'
    },
    // 课时步骤内容详情
    updateStepHistory: {
        url: cndTestUrl + '/bureau/v1.2/updateStepHistory'
    },
    // 课时步骤内容详情
    getLatestStep: {
        url: cndTestUrl + '/bureau/v1.2/getLatestStep'
    },// 课时步骤内容详情
    getActivateLesson: {
        url: cndTestUrl + '/bureau/v1.2/activateLesson'
    },
    // 展示评论默认标签
    getListCommentTags: {
        url: cndTestUrl + '/bureau/v1.2/listCommentTags'
    },
    // 创建新的标签
    createCommentTag: {
        url: cndTestUrl + '/bureau/v1.2/createCommentTag'
    },
    // 删除标签
    deleteCommentTag: {
        url: cndTestUrl + '/bureau/v1.2/deleteCommentTag'
    },
    // 新的创建评论
    createComment: {
        url: cndTestUrl + '/bureau/v1.2/createComment'
    },
    // 展示评论内容
    getListComment: {
        url: cndTestUrl + '/bureau/v1.2/listComment'
    },
    // 评论绑定tag
    bindCommentTag: {
        url: cndTestUrl + '/bureau/v1.2/bindCommentTag'
    },
    // 修改评论
    editComment: {
        url: cndTestUrl + '/bureau/v1.2/editComment'
    },
    // 评论图片
    getListWorksResource: {
        url: cndTestUrl + '/bureau/v1.2/listWorksResource'
    },
    // 修改作品
    editWork: {
        url: cndTestUrl + '/bureau/v1/editWork'
    },
    // 修改作品
    unbindCommentTag: {
        url: cndTestUrl + '/bureau/v1.2/unbindCommentTag'
    },
    // 修改评论标签
    editCommentTag: {
        url: cndTestUrl + '/bureau/v1.2/editCommentTag'
    },
    // 修改资源
    saveResource: {
        url: cndTestUrl + '/bureau/v1/saveResource'
    },
    // 修改资源
    getSharedUrl: cndTestUrl + {
        url: cndTestUrl + '/off/getSharedUrl'
    },
    // 修改资源
    getListBodies: {
        url: cndTestUrl + '/act/v1/listBodies'
    },
    // 修改资源
    getListEduLessonRawCode: {
        url: cndTestUrl + '/edu/v1.2/listEduLessonRawCode'
    },// 查询课程类型
    getAdminListCourse: {
        url: cndTestUrl + '/admin/v1/listCourse'
    },
    // 查看是否可以打开编辑器
    canUnlock: {
        url: cndTestUrl + '/bureau/v1.2/canUnlock'
    },
    // 查看是否可以打开编辑器
    stuLessonUnlock: {
        url: cndTestUrl + '/bureau/v1/getLessonUnlock'
    },
    // 得到机构是否可分配以及分配次数
    getCartoonAmount: {
        url: cndTestUrl + '/bureau/v1/getCartoonAmount'
    },
    // 给孩子分配拍照权限
    assignTicket: {
        url: cndTestUrl + '/bureau/v1/assignTicket'
    },
    // 撤销权限
    cancelTicket: {
        url: cndTestUrl + '/bureau/v1/cancelTicket'
    },
    // 撤销权限
    getPhotoList: {
        url: cndTestUrl + '/bureau/v1/listTicketDetail'
    },
    // 老师上传图片
    uploadCartoonTicketImage: {
        url: cndTestUrl + '/bureau/v1/uploadCartoonTicketImage'
    },
    listCourseCartoonAsset: {
        url: cndTestUrl + '/bureau/v1/listCourseCartoonAsset',//获取角色素材'
    }
}
