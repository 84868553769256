import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/login/Login";
import Home from "../views/home/Home";
import Bof from "../views/video/Bof";
import ShareWorksReport from "../views/share/ShareWorksReportH";
import MyWorks from "../views/share/MyWorks";
import PlanView from "../components/video/PlanView";
import LessonText from "../views/home/LessonText";
import Upload from "../views/video/Upload"
import TestHome from "../views/Test/TestHome";
import TestTab from "../views/Test/TestTab";
import Ceshi from "../views/video/Ceshi";
import lessonDetail from "../views/video/lessonDetail";

Vue.use(VueRouter)

const routes = [
    {
        path: '/TestTab',
        name: 'TestTab',
        component: TestTab
    },
    {
        path: '/ipad/lessonDetail',
        name: 'lesson-detail',
        meta: {
            title: '课时详情'
        },
        component: () => import (
            /* webpackChunkName: "dashboard" */
            "../views/ipad/LessonDetail/index"),
    },{
        path: '/ipad-index',
        name: 'ipad-index',
        component: () => import (
            /* webpackChunkName: "dashboard" */
            "../views/ipad/index"),
        children: [
            {

                path: '/ipad/home',
                name: 'ipad-home',
                meta: {
                    title: '首页'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/ipad/Home/index")
            },
            {
                path: '/ipad/download',
                name: 'ipad-download',
                meta: {
                    title: '下载'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/ipad/DownLoad/index")
            },
            {
                path: '/ipad/work',
                name: 'ipad-work',
                meta: {
                    title: '我的作品'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/ipad/Work/index")
            },
            {
                path: '/ipad/creative_tools',
                name: 'ipad-creative_tools',
                meta: {
                    title: '创作工具'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/ipad/CreativeTools/index")
            },{
                path: '/ipad/game',
                name: 'ipad-game',
                meta: {
                    title: '益智小游戏'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/ipad/Game/index")
            },
            {
                path: '/ipad/course',
                name: 'ipad-home',
                meta: {
                    title: '课程列表'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/ipad/Course/index")
            },
        ]
    },{
        path: '/ipad/login',
        name: 'ipad-login',
        meta: {
            title: '登录'
        },
        component: () => import (
            /* webpackChunkName: "dashboard" */
            "../views/ipad/Login/index")
    },
    {
        path: '/Home',
        name: 'Home',
        component: TestHome,
        children: [
            {
                path: "/Bof",
                name: "Bof",
                meta: {
                    title: '视频列表'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/video/Bof")
            }, {
                path: "/WorksList",
                name: "WorksList",
                meta: {
                    title: '作品集合'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/works/WorksList")
            }, {
                path: "/WorksShow",
                name: "WorksShow",
                meta: {
                    title: '作品展示'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/works/WorksShow")
            }, {
                path: "/StudentManage",
                name: "StudentManage",
                meta: {
                    title: '学生管理'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/student/StudentMange")
            }, {
                path: "/UnlockClass",
                name: "UnlockClass",
                meta: {
                    title: '课程管理'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/student/UnlockClass.vue")
            }, {
                path: "/Tool",
                name: "Tool",
                meta: {
                    title: '创作工具'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/creation/Tool")
            }, {
                path: "/ToolGame",
                name: "ToolGame",
                meta: {
                    title: '益智小工具'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/creation/ToolGame")
            }, {
                path: "/ExplainStaff",
                name: "ExplainStaff",
                meta: {
                    title: '1对1解答'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/explain/ExplainStaff")
            }, {
                path: "/ResourceDown",
                name: "ResourceDown",
                meta: {
                    title: '专属资源下载'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/download/ResourceDown")
            }, {
                path: "/SystemHelp",
                name: "SystemHelp",
                meta: {
                    title: '系统帮助'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/query/Query")
            }, {
                path: "/PersonalCenter",
                name: "PersonalCenter",
                meta: {
                    title: '个人中心'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/center/PersonalCenter")
            }, {
                path: "/VideoList",
                name: "VideoList",
                meta: {
                    title: '视频列表'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/video/VideoList")
            }, {
                path: "/studentVideoList",
                name: "studentVideoList",
                meta: {
                    title: '视频列表'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/video/StudentVideoList")
            }, {
                path: "/studentWorksList",
                name: "studentWorksList",
                meta: {
                    title: '作品列表'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/studentHome/StudentWorks")
            }, {
                path: "/studentWorksUpload",
                name: "studentWorksUpload",
                meta: {
                    title: '上传作品'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../components/studentHome/StudentWorksUpload")
            }, {
                path: "/studentWorks",
                name: "studentWorks",
                meta: {
                    title: '作品库'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/works/StudentWorks")
            }, {
                path: "/worksGather",
                name: "worksGather",
                meta: {
                    title: '作品集合'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/works/WorksGather")
            }, {
                path: "/singleStudentWorks",
                name: "singleStudentWorks",
                meta: {
                    title: '单人作品'
                },
                component: () => import (
                    /* webpackChunkName: "dashboard" */
                    "../views/works/SingleStudentWorks")
            }
        ]
    },
    {
        path: '/lessonDetail',
        name: 'lessonDetail',
        meta: {
            title: '课时详情'
        },
        component: lessonDetail
    },
    {
        path: '/lessonDetail3',
        name: 'lessonDetail3',
        meta: {
            title: '课时详情'
        },
        component: () => import (
            /* webpackChunkName: "dashboard" */
            "../views/video/lessonDetail3")
    },
    {
        path: '/lessonDetail2',
        name: 'lessonDetail2',
        meta: {
            title: '课时详情'
        },
        component: () => import (
            /* webpackChunkName: "dashboard" */
            "../views/video/lessonDetail2")
    },
    {
        path: '/lessonPlan',
        name: 'lessonPlan',
        meta: {
            title: '课时教案'
        },
        component: () => import (
            /* webpackChunkName: "dashboard" */
            "../views/video/lessonPlan")
    },
    {
        path: '/planView',
        name: 'PlanView',
        component: PlanView
    },
    {
        path: '/myWorks',
        name: 'MyWorks',
        component: MyWorks
    },
    {
        path: '/shareWorksReport',
        name: 'ShareWorksReport',
        meta: {
            title: '成果展示'
        },
        component: () => import (
            "../views/share/ShareWorksReport"
            )
    },
    {
        path: '/shareWorksReportH',
        name: 'ShareWorksReportH',
        meta: {
            title: '成果展示'
        },
        component: () => import (
            "../views/share/ShareWorksReportH"
            )
    },
    {
        path: '/scratchJr',
        name: 'scratchJr',
        meta: {
            title: '果动编程'
        },
        component: () => import (
            "../views/ipad/CreativeTools/ScratchJrIframe"
            )
    },
    {
        path: '/Bof',
        name: 'Bof',
        component: Bof
    },
    {
        path: '/Ceshi',
        name: 'Ceshi',
        component: Ceshi
    },
    {
        path: '/Upload',
        name: 'Upload',
        component: Upload
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home
    },
    {
        path: '/LessonText',
        name: 'LessonText',
        component: LessonText
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'index',
        component: () => import (
            "../views/index"
            )
    },{
        path: '/MpPlay',
        name: 'MpPlay',
        component: () => import (
            "../views/Test/MpPlay"
            )
    },

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
