<template>
    <div class="list-class">
        <div class="main-class" id="main-class">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <div class="list-item" v-for="(item,index) in info" :key="index" style="position: relative">
                    <div class="lesson-img-class"
                         @click="videoImgClick(item.video_url,item.video_image,item.lesson_id,item.lesson_status)">
                        <el-image class="lesson-class" :src="item.lesson_image" style="width: 100%;height: 100%"
                                  fit="cover"></el-image>
                    </div>

                    <div style="position: absolute;margin-top: -54%;left:10px;" class="pos-label">
                        <span class="label-class" style="color:#44D7B6" v-if="item.lesson_status === 2">已学习</span>
                        <span class="label-class" style="color:#FA6400" v-else-if="item.lesson_status === 1">未学习
                            </span>
                        <span class="label-class" style="color:#E02020" v-else>未解锁</span>
                    </div>
                    <div class="lesson_name-class">
                        <span v-html="item.lesson_name"></span>
                    </div>
                    <div class="lesson_name-class">
                        <span v-if="item.extra_code.length < 2">
                            <span v-if="item.extra_code.length === 0">
                                <span class="but-cla"
                                      v-if="$store.getters.getCourseData[0].title.toLowerCase().indexOf('scratch') !== -1"
                                      size="medium" type="success" round
                                      @click="item.lesson_status !== 0 ? downloadAnnex(item.lesson_id, item.lesson_status) : alertLock()">自由编程
                                </span>
                            </span>
                            <span v-else>
                                <span class="but-cla"
                                      v-if="$store.getters.getCourseData[0].title.toLowerCase().indexOf('scratch') !== -1"
                                      size="medium" type="success" round
                                      @click="item.lesson_status !== 0 ? downloadAnnex(item.lesson_id, item.lesson_status,item.extra_code[0].id) : alertLock()">亲自出码
                                </span>
                            </span>
                        </span>
                        <span v-else>
                            <el-popover
                                placement="left"
                                trigger="hover"
                                popper-class="popperClass"
                            >
                                <ul class="extraCodeList">
                                    <li v-for="(val, index) in item.extra_code" :key="index"
                                        @click="item.lesson_status !== 0 ? downloadAnnex(item.lesson_id,item.lesson_status,val.id) : alertLock()">{{
                                            val.code_title
                                        }}</li>
                                </ul>
                                <template #reference>
                                <span class="but-cla"
                                      size="medium" type="success" round
                                >亲自出码
                                </span>
                                </template>
                            </el-popover>
                        </span>
                        <span class="but-cla but-cla2" size="medium" type="success" round
                              @click="showLessonText(item.lesson_id, item.lesson_text)">课时文字
                        </span>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import eventVue from "../../assets/js/event"
import LessonVideo from "../../models/Home/LessonVideo";
import Api from "../../models/Home/Api";

export default {
    props: {
        homeBasisData: {type: Object}
    },
    name: "VideoList",
    data() {
        return {
            offset: 0,
            loading: false,
            finished: false,
            count: 5,
            lessonData: {},
            page: 1,
            list: [],
            info: [],
            courseId: 0,
            unLockLessonNum: 0
        }
    },
    computed: {
        noMore() {
            return this.count >= 10;
        },
        disabled() {
            return this.loading || this.noMore
        }
    },
    created() {
        // let courseId;
        if (this.$store.getters.getCourseData.length === 0) {
            this.courseId = 0;
        } else {
            this.courseId = this.$store.getters.getCourseData[0].id;//默认传第一个
        }
        this.getLessonInfo(this.courseId);//默认传第一个
        eventVue.$on("getGroupId", function (message) {
            console.log(message)
        });
        // this.getUnLockLessonNum();
    },
    methods: {
        downloadAnnex(lessonId, lesson_status, rec_id = 0) {
            window.open('/gbscratch/?lesson_id=' + rec_id + '&rec_id=' + lessonId);

            eventVue.$emit('lessonStatus', lesson_status);
            localStorage.setItem("lessonStatus", lesson_status);
        },
        onLoad() {
            //上拉加载
            this.page++;
            // let courseId;
            // if (this.$store.getters.getCourseData.length === 0) {
            //     courseId = 0;
            // } else {
            //     courseId = this.$store.getters.getCourseData[0].id;//默认传第一个
            // }

            this.getLessonInfo(this.courseId);
        },
        videoImgClick(videoUrl, videoImg, lesson_id, lesson_status) {
            eventVue.$emit('videoPlay', {video_url: videoUrl, video_img: videoImg});
            eventVue.$emit('lessonId', lesson_id);

            eventVue.$emit('lessonStatus', lesson_status);
            localStorage.setItem("lessonStatus", lesson_status);
        },
        //课时列表
        getLessonInfo(course_id, type = 1) {
            const infoType = type;
            if (type === 3) {
                this.page = 1;
                this.courseId = course_id;
                // this.finished = false;
                // this.loading = false;
                let top = document.getElementById('main-class').scrollTop;
                let self = this;
                // 实现滚动效果
                const timeTop = setInterval(() => {
                    document.body.scrollTop = document.getElementById('main-class').scrollTop = top -= 50;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 0);
            }
            if (type === 2) {
                if (this.$store.getters.getCourseData.length === 0) {
                    this.$Dialog.confirm(
                        {
                            message: '<span style="font-size: 16px">请让机构给该班级分配课程</span>',
                            confirmButtonColor: '#FF8D15',
                        })
                        .then(() => {
                        })
                        .catch(() => {
                        });

                    this.info = [];
                    eventVue.$emit('videoPlay', '');
                    this.finished = true;
                    return false;
                }
                let top = document.getElementById('main-class').scrollTop;
                console.log('yyy', top)
                let self = this;
                // 实现滚动效果
                const timeTop = setInterval(() => {
                    document.body.scrollTop = document.getElementById('main-class').scrollTop = top -= 50;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 0);
                this.page = 1;
                this.courseId = course_id;
            }
            let params = {
                course_id: this.courseId,
                group_id: this.$store.getters.getGroupData.group_id,
                page: this.page,
                limit: 5,
                goods_category: localStorage.getItem('goodsCategory'),
            }
            console.log(params);
            this.loading = true;
            LessonVideo.getStudentLessonList(params, ({type, list}) => {
                if (type === 200) {
                    if (this.page === 1) {
                        this.list = [];
                        list.list.forEach((element) => {
                            this.list.push(element);
                        });
                    } else {
                        list.list.forEach((element) => {
                            this.list.push(element);
                        });
                    }

                    this.info = this.list;
                    if (list.list.length < 5) {
                        this.finished = true;
                    }
                    this.loading = false;
                    this.lessonData = list;
                    let video_url;
                    let video_img;
                    if (this.info.length === 0) {
                        video_url = ''
                        video_img = ''
                    } else {
                        video_url = this.info[0].video_url
                        video_img = this.info[0].video_image
                    }
                    eventVue.$emit('videoPlay', {video_url: video_url, video_img: video_img})
                    eventVue.$emit('courseId', course_id)
                    if (this.page === 1) {
                        eventVue.$emit('lessonId', this.info[0].lesson_id)
                    }
                    eventVue.$emit('courseIntro', list.course_info)


                    eventVue.$emit('lessonStatus', this.info[0].lesson_status);
                    localStorage.setItem("lessonStatus", this.info[0].lesson_status);

                    if (infoType === 2 || infoType === 3) {
                        this.finished = false;
                        this.loading = false;
                    }
                }
            })
        },
        // 展示课时文字  id 课时id
        showLessonText(id, text) {
            // eventVue.$emit('getValue', 0);
            // this.$emit('viewCut', '0-1');
            if (text === null) {
                this.$message({
                    message: '暂无文字',
                    type: 'error',
                    iconClass: 'iconClass',
                });
            } else {
                window.open(this.$fileName+'/#/LessonText?flag=0-1&textData=' + text, '_blank');
            }
        },
        alertLock() {
            this.$message({iconClass: 'not', message: '请解锁课程', center: true});
        },
        getUnLockLessonNum() {
            Api.fetchData({params: {}}, 'listUnlockedLessonNum', ({type, list}) => {
                if (type == 200) {
                    this.unLockLessonNum = list;
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.extraCodeList {
    text-align: center;
    line-height: 30px;

    li {
        border-bottom: 1px solid #f1f1f1;

        &:hover {
            background-color: #FF8B0C;
            color: #fff;
            cursor: pointer;
            border-radius: 4px;
        }
    }
}

.list-class {
    float: right;
    width: 16%;
    height: calc(99.5vh - 120px);

    .main-class::-webkit-scrollbar {
        width: 0 !important
    }

    .main-class {
        -ms-overflow-style: none;
    }

    .main-class {
        overflow: -moz-scrollbars-none;
    }

    .main-class {
        width: 90%;
        height: 96%;
        margin: 6% auto;
        background: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 0px 24px 0px #e3e3e3;

        /*设置在竖直方向上可滚动*/
        overflow-y: scroll;
        /*overflow:hidden样式可以不写，因为scroll会自动隐藏超出content高度的部分*/


        .lesson-class {
            width: 90%;
            height: 180px;
            border-radius: 10px;
        }

        .lesson_name-class {
            width: 90%;
            margin: 10px auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;

        }

        .lesson-but-class {
            width: 90%;
            margin: 10px auto;
        }

        .list-item:nth-child(1) {
            margin-top: 10px;
        }

        .list-item:not(:first-child) {
            padding-top: 10px;
        }

        ul {
            padding: 0;
            //text-align: center;
        }
    }

    .lesson-img-class {
        height: 150px;
        background-size: 100% 100%;
        width: 90%;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 0 1px 5px 0 #d7d7d7;
    }

    .pos-label:nth-child(1) {
        top: 2% !important;
    }

    .label-class {
        width: 54px;
        background: #FFFFFF;
        padding: 6px 12px;
        border-radius: 10px 0 10px 0;
    }

    .but-cla {
        padding: 10px 10px;
        cursor: pointer;
        border-radius: 22.5px;
        background: #FF8B0C;
        display: inline-block;
        color: #FFFFFF;
    }

    .but-cla:hover {
        background: #f8ae5d;
    }

    .but-cla2 {
        margin-left: 12px;
    }

    .el-button--success, .el-button--success:focus, .el-button--success.is-active, .el-button--success:active {
        background: #FF8B0C;
        border: 1px solid #FF8B0C;
    }

    .el-button--success:hover {
        background: #f8ae5d;
        color: #FFF;
    }

    .el-button--primary, .el-button--primary:focus, .el-button--primary.is-active, .el-button--primary:active {
        background: #C5C5C5;
        border: 1px solid #C5C5C5;
    }

    .el-button--primary:hover {
        color: #FFF;
    }

    /deep/ .el-image__inner {
        position: relative;
    }
}

.iconClass {
    display: none;
}
</style>
