<template>
    <div class="top-class">
        <el-row>
            <el-col :span="4">
                <div class="Logo-class">
                    <!--                                        <el-image class="institution-class" :src="basicData.bureau.bureau_avatar" fit="fill" style="width: 30%;height: 30%"></el-image>-->
                    <img :src="basicData.bureau.bureau_avatar" alt="" class="institution-class">
                    <span :title="basicData.bureau.bureau_name">{{ basicData.bureau.bureau_name }}</span>
                </div>
            </el-col>
            <el-col :span="14">
                <div class="type-class" v-if="topData.length !== 0">
                    <div v-for="(item,index) in topData" :key="index" class="button-class">
                        <!--                        <span :class="{'cssColor' : fontColor === index}" @click="typeClick(index)">{{ item }}</span>-->
                        <span :class="{'cssColor' : fontColor === index}" @click="typeClick(index)">{{
                                item.title
                            }}</span>
                    </div>
                </div>
                <div v-else>
                    <span style="opacity:0;">1</span>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="setting-class">
                    <span class="type_export">
                        <span slot="reference" class="cut-class">{{ className }}
                            </span>
                        <el-select v-model="className" @change="cutClassClick"
                                   placeholder="请选择">
                            <el-option v-for="(item,index) in classOptions" :key="index"
                                       :label="item.group_name"
                                       :value="index">
                            </el-option>
                        </el-select>
                    </span>
                    <span class="type_export1" style="">
                        <i slot="reference" class="el-icon-arrow-down iconDown"
                           style=""></i>
                    </span>
                    <span class="query-class" v-if="this.$store.getters.getUserData.role === 1">
                        <!--                        <span class="icon-class" @click="typeClick(0,11)">?</span>-->
                                                <span @click="typeClick(0,11)">帮助</span>
                                            </span>
                    <span class="icon-up type_export3">
                    <el-popover
                        ref="popover"
                        width="70"
                        trigger="click">
                            <div style="text-align: center">
                                <ul>
                                    <li style="border-bottom:1px solid #F4F4F4;padding: 10px 0 ;cursor: pointer;"
                                        @click="savePasswordClick()">修改密码</li>
                                    <li style="border-bottom:1px solid #F4F4F4;padding: 10px 0 ;cursor: pointer;"
                                        @click="typeClick(0,10)">下载</li>
                                    <li style="border-bottom:1px solid #F4F4F4;padding: 10px 0 ;cursor: pointer;"
                                        @click="exitClick()">退出</li>
                                </ul>
                            </div>
                        <i slot="reference" class="el-icon-arrow-down"></i>
                        </el-popover>
                </span>
                </div>
            </el-col>
        </el-row>
        <div v-show="savePassStatus">
            <SavePassword @alertPassword="alertPasswordStatus"></SavePassword>
        </div>
    </div>
</template>

<script>
import eventVue from "../assets/js/event"
import SavePassword from "./common/SavePassword";
import UnloadClass from "../models/Home/UnloadClass";

export default {
    props: {
        homeBasisData: {type: Object},
        homeClassData: {type: Object},
        homeCourseData: {type: Object}
    },
    name: "Top",
    components: {
        SavePassword
    },
    data() {
        return {
            basicData: this.homeBasisData,
            savePassStatus: false,
            className: '切换班级',
            fontColor: 0,
            institutionImg: 'https://storage.shubenji.cn/GuoBao/resource/aa016318ae39d3a9.jpg?OSSAccessKeyId=LTAI4Fdpcm5Gwue9tEaZfiGS&Expires=1625124880&Signature=5AiNm9gb5em%2BNDnkglJGKT6w2aY%3D',
            topData: this.homeCourseData.list,//默认课程信息
            leftNum: 0,
            classOptions: this.homeClassData.list,
            userData: JSON.parse(localStorage.getItem('info')),
            menuShow: 1,
            newVal: [],
        }
    },
    watch: {
        homeCourseData(newVal, oldVal) {
            if (this.leftNum === 0) {
                this.topData = newVal.list;
                this.newVal = newVal;
            }
        }
    },
    created() {
        console.log(this.classOptions);
        console.log(this.$store.getters.getGroupData);
        this.menuShow = this.$store.getters.getUserData.role;

        let self = this;
        this.className = this.$store.getters.getGroupData.group_name;

        eventVue.$on("getValue", function (message) {
            self.fontColor = 0;
            self.leftNum = message;
            if (self.menuShow === 1) {
                switch (message) {
                    case 0:
                        self.topData = self.$store.getters.getCourseData;
                        break;
                    case 1:
                        self.topData = [{id: 1, title: "作品集合"}, {id: 2, title: "作品展示"}];
                        break;
                    case 2:
                        self.topData = [{id: 1, title: "学生管理"}];
                        break;
                    case 3:
                        self.topData = [{id: 1, title: "解锁课程"}];
                        break;
                    case 4:
                        self.topData = [{id: 1, title: "创作工具"}];
                        break;
                    case 5:
                        self.topData = [{id: 1, title: "来开动脑筋吧"}];
                        break;
                    case 6:
                        self.topData = [{id: 1, title: "您的专属服务"}];
                        break;

                    case 9:
                        self.topData = [{id: 1, title: "个人中心"}];
                        break;
                }
            } else {
                switch (message) {
                    case 0:
                        self.topData = self.$store.getters.getCourseData;
                        break;
                    case 1:
                        self.topData = [{id: 1, title: "小小创作家"}, {id: 2, title: "上传作品"}];
                        break;
                    case 2:
                        self.topData = [{id: 1, title: "创作工具"}];
                        break;
                    case 3:
                        self.topData = [{id: 1, title: "快来开动脑筋吧"}];
                        break;
                    case 9:
                        self.topData = [{id: 1, title: "个人中心"}];
                        break;
                }
            }
        })
        eventVue.$on("saveFontColor", function (message) {
            self.fontColor = message
        })

        //缓存区分班级课程类型
        if (this.$store.getters.getUserData.role === 2) {
            self.listUnlockedLessonNumInfo();
            setInterval(function () {
                self.listUnlockedLessonNumInfo();
            }, 300000) //60000 改成了5分钟
        }
    },
    mounted() {
    },
    methods: {
        typeClick(index, left = null) {
            if (left !== null) {
                if (left === 10) {
                    this.leftNum = left;
                    this.topData = [{id: 1, title: "专属资源下载"}];
                }
                if (left === 11) {
                    this.leftNum = left;
                    this.topData = [{id: 1, title: "系统帮助"}];
                }
            }
            this.fontColor = index;
            if (this.leftNum === 0) {
                this.$emit('topViewCut', this.leftNum)
                this.$emit('videoList', this.topData[index].id);//视频菜单
            } else {
                this.$emit('topViewCut', this.leftNum + '-' + index)
            }
        },

        //切换班级
        cutClassClick(id) {
            this.className = this.classOptions[id].group_name;
            let groupId = this.classOptions[id].group_id;
            this.$store.commit('setGroupData', {group_id: groupId, group_name: this.className});
            eventVue.$emit('getGroupId', groupId)
            eventVue.$emit('saveSyncTime', groupId);

            this.$emit('homeCourseList');

            // this.$emit('transferData');
            // eventVue.$emit('classClick')
        },

        //修改密码
        savePasswordClick() {
            this.$refs.popover.doClose();
            this.savePassStatus = true;
        },

        //修改密码弹窗
        alertPasswordStatus() {
            this.savePassStatus = false;
        },

        //退出按钮
        exitClick() {
            this.$Dialog.confirm(
                {
                    message: '<span style="font-size: 16px">确定退出吗？</span>',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    localStorage.clear();
                    // on confirm
                    // localStorage.removeItem('info');
                    // localStorage.removeItem('courseData');
                    // localStorage.removeItem('groupData');
                    // localStorage.removeItem('spriteState')
                    // localStorage.removeItem('blockList')
                    // localStorage.removeItem('lessCode')
                    // localStorage.removeItem('deleteState')
                    // localStorage.removeItem('test')
                    // localStorage.removeItem('freeState')
                    // localStorage.removeItem('initialState')
                    // localStorage.removeItem('unloadGroupData')
                    // localStorage.removeItem('lockGameId')
                    // localStorage.removeItem('gameState')

                    this.isRouterAlive = false
                    this.$nextTick(function () {
                        this.isRouterAlive = true
                    })
                    this.$router.push({
                        path: '/',
                        name: 'Login',
                        params: {}
                    })
                    window.location.reload()

                })
                .catch(() => {
                    // on cancel
                });

        },
        //添加缓存 只有学生端的时候
        async listUnlockedLessonNumInfo() {
            let _this = this;
            UnloadClass.getListUnlockedLessonNum({}, ({type, list}) => {
                if (type === 200) {
                    _this.$store.commit('setUnloadGroupData', list.list.lesson_num)
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.top-class {
    width: 100%;
    height: 120px;
    box-shadow: 0px 0px 24px 0px #d7d7d7;
    background: #FFFFFF;

    .Logo-class {
        text-align: center;
        margin-top: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        span {
            font-size: 28px;
            font-weight: bold;
            margin-left: 20px;
            color: #FF8D15;
            line-height: 90px;
        }

        h1 {
            display: inline-block;
            margin-left: 20px;
            color: #FF8D15;
        }

        .institution-class {
            width: 80px;
            height: 80px;
            vertical-align: middle;
            border-radius: 12px;
        }
    }

    .type-class {
        width: 100%;

        .button-class {
            float: left;
            line-height: 120px;

            span {
                margin-left: 80px;
                font-size: 20px;
                cursor: pointer;
                font-weight: 400;
                color: #4A4A4A
            }
        }
    }

    .setting-class {
        width: 100%;
        line-height: 122px;
        text-align: right;
        //margin-right:15px;
        span {
            font-size: 20px;
            cursor: pointer;
            font-weight: 400;
        }

        .cut-class {
            padding: 10px 20px;
            border-radius: 8px 0 0 8px;
            background: #FF8B0C;
            color: #FFFFFF;
            //display: inline-flex;

            //width: 200px;
            //height: 80px;
            //text-overflow: ellipsis;
            //overflow: hidden;
            //white-space: nowrap;
        }

        .hide-cut-class {
            display: inline-block;
            padding: 10px 20px;
            width: 60px;
            color: pink;
        }

        .query-class {
            float: left;
            display: inline-flex;
            margin-left: 15%;

            .icon-class {
                width: 20px;
                height: 20px;
                font-size: 18px;
                border-radius: 50%;
                border: 1px solid #989898;
                line-height: 20px;
                text-align: center;
                color: #989898;
            }
        }

        .icon-up {
            float: left;
            font-size: 24px;
            margin-left: 15%;
            margin-right: 5%;
        }
    }

    .cssColor {
        color: #FF8E0D !important;
    }

    .type_export {
        //position: relative !important;
        border: none;
        cursor: pointer;
        display: inline-block;
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        float: left;
        color: #FFFFFF;

        .el-select {
            background-color: darkblue !important;
            width: 190px !important;
            position: absolute !important;
            top: -40px !important;
            left: calc(66vw + 170px) !important;
            opacity: 0;
        }
    }

    .type_export1 {
        display: inline-block;
        float: left;
        font-size: 20px;
    }

    .type_export3 {
        position: relative;
        z-index: 100;
    }

    .iconDown {
        padding: 11px 10px 11px 10px;
        border-radius: 0 8px 8px 0;
        color: #FFFFFF;
        background: #FF8D15
    }

    .el-select-dropdown {
        top: 80px !important;
    }
}


</style>
<style>
.el-popover {
    min-width: 70px !important;
    padding: 0 10px !important;
}

</style>
