<template>
    <div class="play-class">
        <p>
            <span class="theBtn" @click="cutType">控制按钮：{{isShadeText}}</span>
        </p>
        <div class="play-box-class">
            <video-player class="video-player vjs-custom-skin"
                          ref="videoPlayer"
                          :playsinline="true"
                          @play="onPlayerPlay($event)"
                          @pause="onPlayerPause($event)"
                          @ready="playerReadied"
                          :options="playerOptions">
            </video-player>
        </div>
    </div>
</template>

<script>
import {videoPlayer} from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import eventVue from "../../assets/js/event";
import LessonVideo from "../../models/Home/LessonVideo";
import websocket from "../../utils/websocket";

export default {
    name: "VideoPlay",
    components: {
        videoPlayer
    },
    data() {
        return {
            isShade: '',
            isShadeText: '没遮住',
            playTime: '',
            current: '',
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: true, // 是否视频一结束就重新开始。
                controls: false,
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src:
                            "" // url地址
                    }
                ],
                poster: "", // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: false, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: false // 是否显示全屏按钮
                },
                videoButton: '../../assets/lock-icon.png',
            },
        };
    },
    created() {
        let self = this;
        eventVue.$on('videoPlay', function (message) {
            if (message.video_url === undefined) {
                let buttonClass = document.getElementsByClassName('vjs-big-play-button');
                buttonClass[0].style.display = 'none'
            }

            self.playerOptions.sources[0].src = message.video_url;
            self.playerOptions.poster = message.video_img;
        });
        websocket.Send({
            // token: JSON.parse(localStorage.getItem('info')).token,
            token:  this.$store.getters.getUserData.token,
            conn_id: this.theConnId,
            data: '',
            group_id: this.$store.getters.getGroupData.group_id,
            action: 'group_state'
        });
    },
    inject:["conn_id"],
    computed: {
        theConnId() {
            return this.conn_id()
        }
    },
    watch: {
        theConnId() {
            console.log('conn_id改变')
        }
    },
    beforeRouteLeave() {
        this.$refs.videoPlayer.player.pause()
    },
    methods: {
        // 播放回调
        onPlayerPlay(player) {
            // console.log("player play!", player);
        },
        // 暂停回调
        onPlayerPause(player) {
            // console.log("player pause!", player);
        },
        // 视频播完回调
        onPlayerEnded($event) {
            // console.log(player);
        },
        // DOM元素上的readyState更改导致播放停止
        onPlayerWaiting(player) {
            let time = localStorage.getItem("cacheTime")
            if (player.cache_.currentTime - Number(time) > 0.1) {
                this.current = Number(time)
                this.playerReadied(player)
            } else {
                this.current = player.cache_.currentTime
            }
        },
        // 已开始播放回调
        onPlayerPlaying($event) {
            // console.log($event)
        },
        // 当播放器在当前播放位置下载数据时触发
        onPlayerLoadeddata($event) {
            // console.log($event)
        },
        // // 当前播放位置发生变化时触发。
        onPlayerTimeupdate(player) {
            this.playTime = player.cache_.currentTime
            let playTime = player.cache_.currentTime
            setTimeout(function () {
                localStorage.setItem("cacheTime", playTime)
            }, 500)


            let time = localStorage.getItem("cacheTime")
            if (player.cache_.currentTime - Number(time) > 2) {
                this.current = Number(time)
                this.playerReadied(player)
            } else {
                this.current = player.cache_.currentTime
            }
        },
        //媒体的readyState为HAVE_FUTURE_DATA或更高
        onPlayerCanplay(player) {
            // // console.log('player Canplay!', player)
        },
        //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
        onPlayerCanplaythrough(player) {
            // // console.log('player Canplaythrough!', player)
        },
        //播放状态改变回调
        playerStateChanged(playerCurrentState) {
            // // console.log('player current update state', playerCurrentState)
        },
        //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
        playerReadied(player) {
            // console.log('example player 1 readied', player);
            player.currentTime(this.current)
        },
        cutType() {
            this.isShadeText = this.isShadeText === '没遮住' ? '遮住啦' : '没遮住';

            websocket.Send({
                // token: JSON.parse(localStorage.getItem('info')).token,
                token:  this.$store.getters.getUserData.token,
                conn_id: this.theConnId,
                data: '',
                group_id: this.$store.getters.getGroupData.group_id,
                action: 'flip'
            });
        }
    }
}
</script>

<style lang="less" scoped>







// .play-class {
//     width: 70%;
//     height: calc(99.5vh - 120px);
//     .play-box-class {
//         width: 98%;
//         height: calc(99.5vh - 150px);
//         //background: #9C9C9C;
//         border-radius: 10px;
//         box-shadow: 0px 0px 24px 0px #e3e3e3;
//         margin-top: 15px;
//         margin-left: 1.5%;
//     }

//     .vjs-custom-skin > .video-js .vjs-big-play-button {
//         background-color: rgba(0, 0, 0, 0.45);
//         font-size: 3.0em;
//         /* border-radius: 50%; */
//         height: 1.5em !important;
//         line-height: 1.5em !important;
//         margin-top: -1em !important;
//         margin-left: 10px;
//     }

//     /*这里用了第三方vue-video-player插件，但这个插件有bug，设置globalSetting:{controls:true}隐藏进度条不生效，故可设置插件样式进行隐藏vjs-progress-contro*/

//     .vjs-progress-control {
//         /*visibility:hidden;       // 隐藏进度条*/
//     }

//     /deep/ .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3 {
//         height: calc(100vh - 160px);
//         width: 100%;
//         margin: 0 auto;
//     }

//     /deep/ .video-js .vjs-big-play-button { /*对播放按钮的样式进行设置*/
//         //display: none;
//     }

//     /deep/ .vjs-error .vjs-error-display:before {
//         //content:url("../../assets/lock-icon.png");
//         //background-image: url("/public/img/lock-icon.png");
//         content: '';
//         //background: url('../../assets/lock-icon.png') no-repeat;
//         //background-image: url("../../assets/lock-icon.png");
//         //background-size: 1rem;
//         //display: inline-block;
//         //vertical-align: -2px;
//         //width: 150px;
//         //height: 150px;
//         ////text-align: center;
//         //margin-left: 44%;
//         //margin-top: -5%;
//     }
//     .theBtn{
//         position: absolute;
//         border: 1px solid;
//         background-color: pink;
//         padding: 10px 16px;
//         border-radius: 12px;
//         color: rgb(255, 255, 255);
//         cursor: pointer;
//         top: 104px;
//         z-index: 20;
//     }
// }
</style>
