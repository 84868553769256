<template>
    <header class="header_home user_reg" v-title data-title="课时列表">
        <div class="list-class">
            <div class="main-class" id="main-class">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :offset="offset"
                    @load="onLoad"
                >
                    <div class="list-item" v-for="(item,index) in info" :key="index">
                        <div class="lesson-img-class"
                             @click="videoImgClick(item.video_url,item.video_image,item.lesson_id,item.point_in_time)">
                            <el-image class="lesson-class" :src="item.lesson_image" fit="cover"></el-image>
                        </div>
                        <div class="lesson_name-class">
                            <span v-html="item.lesson_name"></span>
                        </div>
                        <div class="lesson-but-class">
                            <el-row :gutter="0">
                                <el-col :span="12" style="text-align: center;">
                                    <span
                                        v-if="$store.getters.getCourseData[0].title.toLowerCase().indexOf('scratch') !== -1 ">
                                        <span v-if="item.extra_code.length < 2">
                                            <el-button v-if="item.code_url !== ''" size="medium" type="success" round
                                                       @click="downloadAnnex(item.code_url,item.lesson_id,item.lesson_id)">源码
                                            </el-button>
                                            <el-button v-else size="medium" type="success" round
                                                       @click="downloadAnnex(item.code_url,item.lesson_id,item.lesson_id)">无源码
                                            </el-button>
                                        </span>
                                        <span v-else>
                                            <el-popover
                                                placement="left"
                                                trigger="hover"
                                                popper-class="popperClass"
                                            >
                                                <ul class="extraCodeList">
                                                    <li v-for="(val, index) in item.extra_code" :key="index"
                                                        @click="downloadAnnex(val.code_url,item.lesson_id,val.id)">{{
                                                            val.code_title
                                                        }}</li>
                                                </ul>
                                                <template #reference>
												<el-button
                                                    style="background: #FF8B0C; border: 0.00521rem solid #FF8B0C;border-radius: 20px;padding: 10px 20px;color:#fff;">源码</el-button>
                                                </template>
                                            </el-popover>
                                        </span>
                                    </span>
                                </el-col>
                                <el-col :span="12" style="text-align: center;">
                                    <el-button size="medium" type="success" id="buttonPlan"
                                               style="background: #00DBB4;border: 1px solid #00DBB4;" round
                                               @click="teachPlanClick(item.lesson_id)">教案
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </van-list>
            </div>

            <div class="teach-plan-class" v-if="teachPlanStatus">
                <!--            <div style="float: right;cursor: pointer" @click="closeLeaveClick"><i class="el-icon-close"></i></div>-->
                <TeachPlan :plan="plan"></TeachPlan>
            </div>
        </div>
    </header>
</template>

<script>
import eventVue from "../../assets/js/event"
import LessonVideo from "../../models/Home/LessonVideo";
import TeachPlan from "./TeachPlan";

export default {
    name: "VideoList",
    data() {
        return {
            teachPlanStatus: false,
            offset: 100,
            loading: false,
            finished: false,
            count: 5,
            lessonData: {},
            page: 1,
            list: [],
            info: [],
            plan: "",
            courseId: 0,
        }
    },
    components: {
        TeachPlan
    },
    computed: {
        noMore() {
            return this.count >= 10;
        },
        disabled() {
            return this.loading || this.noMore
        }
    },
    created() {

        if (this.$store.getters.getCourseData === null || this.$store.getters.getCourseData.length === 0) {
            this.courseId = 0;
        } else {
            this.courseId = this.$store.getters.getCourseData[0].id;//默认传第一个
        }
        this.getLessonInfo(this.courseId);//默认传第一个
    },
    methods: {
        downloadAnnex(url, lesson_id, rec_id) {
            if (url === '') {
                window.open("/gbscratch/");
            }
            window.open("/gbscratch/?loadUrlId=" + lesson_id + '&recUrlId=' + rec_id);
            // const aTag = document.createElement('a')
            // aTag.href = url //文件id
            // aTag.click()
        },
        teachPlanClick(lesson_id) {
            // this.teachPlanStatus = true;
            // this.plan = plan;
            window.open(this.$fileName+'/#/planView?lesson_id=' + lesson_id);
        },
        onLoad() {
            //上拉加载
            this.page++;
            // let courseId;
            // if (this.$store.getters.getCourseData.length === 0) {
            //     courseId = 0;
            // } else {
            //     courseId = this.$store.getters.getCourseData[0].id;//默认传第一个
            // }

            this.getLessonInfo(this.courseId);

        },
        videoImgClick(videoUrl, videoImage, lesson_id, point_in_time) {
            eventVue.$emit('videoPlay', {video_url: videoUrl, video_img: videoImage, pointInTime:point_in_time});
            eventVue.$emit('lessonId', lesson_id);
        },
        //课时列表
        getLessonInfo(course_id, type = 1) {
            const infoType = type;
            if (type === 3) {
                let top = document.getElementById('main-class').scrollTop;
                console.log('yyy', top)
                let self = this;
                // 实现滚动效果
                const timeTop = setInterval(() => {
                    document.body.scrollTop = document.getElementById('main-class').scrollTop = top -= 50;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 0);
                this.page = 1;
                this.courseId = course_id;
                // this.finished = false;
                // this.loading = false;
            }

            if (type === 2) {
                if (this.$store.getters.getCourseData.length === 0) {
                    this.$Dialog.confirm(
                        {
                            message: '<span style="font-size: 16px">请让机构给该班级分配课程</span>',
                            confirmButtonColor: '#FF8D15',
                        })
                        .then(() => {
                        })
                        .catch(() => {
                        });

                    this.info = [];
                    eventVue.$emit('videoPlay', '');
                    this.finished = true;
                    return false;
                }

                let top = document.getElementById('main-class').scrollTop;
                console.log('yyy', top)
                let self = this;
                // 实现滚动效果
                const timeTop = setInterval(() => {
                    document.body.scrollTop = document.getElementById('main-class').scrollTop = top -= 50;
                    if (top <= 0) {
                        clearInterval(timeTop);
                    }
                }, 0);
                this.page = 1;
                this.courseId = course_id;


            }
            let params = {
                course_id: this.courseId,
                group_id: this.$store.getters.getGroupData.group_id,
                page: this.page,
                limit: 5,
            }
            this.loading = true;
            LessonVideo.getLessonList(params, ({type, list}) => {
                if (type === 200) {
                    if (this.page === 1) {
                        this.list = [];
                        list.list.forEach((element) => {
                            if (element.extra_code.length == 1) {
                                element.code_url = element.extra_code[0].code_url;
                            }
                            this.list.push(element);
                        });
                    } else {
                        list.list.forEach((element) => {
                            if (element.extra_code.length == 1) {
                                element.code_url = element.extra_code[0].code_url;
                            }
                            this.list.push(element);
                        });
                    }

                    this.info = this.list;
                    if (list.list.length < 5) {
                        this.finished = true;
                    }
                    this.loading = false;
                    this.lessonData = list;
                    let video_url;
                    let video_img;
                    if (this.info.length === 0) {
                        video_url = ''
                        video_img = ''
                    } else {
                        video_url = this.info[0].video_url
                        video_img = this.info[0].video_image
                    }
                    eventVue.$emit('videoPlay', {video_url: video_url, video_img: video_img})

                    if (infoType === 2 || infoType === 3) {
                        this.finished = false;
                        this.loading = false;
                    }
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.extraCodeList {
    text-align: center;
    line-height: 30px;

    li {
        border-bottom: 1px solid #f1f1f1;

        &:hover {
            background-color: #FF8B0C;
            color: #fff;
            cursor: pointer;
            border-radius: 4px;
        }
    }
}

.header_home {
    float: none !important;
}

.list-class {
    float: right;
    width: 16%;
    height: calc(99.5vh - 120px);

    .main-class::-webkit-scrollbar {
        width: 0 !important
    }

    .main-class {
        -ms-overflow-style: none;
    }

    .main-class {
        overflow: -moz-scrollbars-none;
    }

    .main-class {
        width: 90%;
        height: 96%;
        margin: 6% auto;
        background: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 0px 24px 0px #e3e3e3;

        /*设置在竖直方向上可滚动*/
        overflow-y: scroll;
        /*overflow:hidden样式可以不写，因为scroll会自动隐藏超出content高度的部分*/

        .lesson-img-class {
            text-align: center;
        }

        .lesson-class {
            width: 90%;
            height: 180px;
            border-radius: 10px;
        }

        .lesson_name-class {
            width: 90%;
            margin: 10px auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;

        }

        .lesson-but-class {
            width: 90%;
            margin: 10px auto;
        }

        .list-item:nth-child(1) {
            margin-top: 10px;
        }

        .list-item:not(:first-child) {
            padding-top: 10px;
        }

        ul {
            padding: 0;
            //text-align: center;
        }
    }

    .el-button--success, .el-button--success:focus, .el-button--success.is-active, .el-button--success:active {
        background: #FF8B0C;
        border: 1px solid #FF8B0C;
    }

    //.el-button--success:hover {
    //    background: #f8ae5d;
    //    color: #FFF;
    //}

    .el-button--primary, .el-button--primary:focus, .el-button--primary.is-active, .el-button--primary:active {
        background: #C5C5C5;
        border: 1px solid #C5C5C5;
    }

    .el-button--primary:hover {
        color: #FFF;
    }

    .buttonPlan:hover {
        background: red;
        color: #FFF;
    }
}
</style>
