<template>
    <div class="detail-box-class">
        <div class="title-class">
           <span class="span-1-class">
               <span class="span-2-class">{{ $route.query.lesson_name }}</span>
           </span>
        </div>
        <div style="position: relative;height: 100vh">
            <div class="aaa-class">
                <div id="wrap" :style="{ height: screenHeight + 'px' }">
                    <div id="main" :style="{ top: nowTop + 'px' }">
                        <!--                        <div style="background-color: #FF8B0C" v-for="(item,index) in stepData" :id="item.anchorId"-->
                        <!--                             class="pageccc" v-html="item.content">-->
                        <!--                        </div>-->
                        <div id="anchor-2"
                             class="page">
                            <img :src="img1" alt="" width="100%">
                        </div>
                        <div id="anchor-3"
                             class="page"><img :src="img2" width="100%" alt=""></div>
                        <div id="anchor-4"
                             class="page"></div>
                        <div id="anchor-5"
                             class="page"></div>
                    </div>
                </div>
            </div>
            <div class="type-box-class">
                <ul id="pageUl" type="circle">
                    <li :id="item.pageUlLi" class="pageUlLi" v-for="(item,index) in stepData"
                        @click="goAnchor(item.anchor)"
                        :class="{'active': curIndex == index+1}">&nbsp;{{ item.title }}
                    </li>
                    <!--                                        <li id="pageUlLi2" class="pageUlLi" @click="goAnchor('#anchor-2')"-->
                    <!--                                            :class="{'active': curIndex == 2}">&nbsp;文字-->
                    <!--                                        </li>-->
                    <!--                                        <li id="pageUlLi3" class="pageUlLi" @click="goAnchor('#anchor-3')"-->
                    <!--                                            :class="{'active': curIndex == 3}">&nbsp;作品-->
                    <!--                                        </li>-->
                    <!--                                        <li id="pageUlLi4" class="pageUlLi" @click="goAnchor('#anchor-4')"-->
                    <!--                                            :class="{'active': curIndex == 4}">&nbsp;课题-->
                    <!--                                        </li>-->
                    <!--                                        <li id="pageUlLi5" class="pageUlLi" @click="goAnchor('#anchor-5')"-->
                    <!--                                            :class="{'active': curIndex == 5}">&nbsp;课题-->
                    <!--                                        </li>-->
                </ul>
            </div>
        </div>


    </div>
</template>

<script>

import LessonVideo from "../../models/Home/LessonVideo";

export default {
    name: 'Home',
    data() {
        return {
            img1:'./img/lesson/1.png',
            img2:'./img/lesson/2.png',
            seen: false,
            screenWeight: 0,        // 屏幕宽度
            screenHeight: 0,        // 屏幕高度
            index: 1,               // 用于判断翻页
            curIndex: 1,            // 当前页的index
            startTime: 0,           // 翻屏起始时间
            endTime: 0,             // 上一次翻屏结束时间
            nowTop: 0,              // 翻屏后top的位置
            pageNum: 0,             // 一共有多少页
            main: Object,
            obj: Object,
            stepData: [],
            textContent: ''
        }
    },
    created() {
        this.getLessonDetailInfo();
        this.getListLessonTemConInfo();
    },
    mounted() {
        this.screenWeight = document.documentElement.clientWidth;
        this.screenHeight = document.documentElement.clientHeight / 100 * 78;
        this.main = document.getElementById("main");
        this.obj = document.getElementsByTagName("div");
        for (let i = 0; i < this.obj.length; i++) {
            console.log(this.obj[i].className)
            if (this.obj[i].className == 'page') {

                this.obj[i].style.height = this.screenHeight + "px";
            }
        }
        this.pageNum = document.querySelectorAll(".page").length;
        // console.log(this.pageNum);
        // 浏览器兼容
        if ((navigator.userAgent.toLowerCase().indexOf("firefox") != -1)) {
            document.addEventListener("DOMMouseScroll", this.scrollFun, false);
        } else if (document.addEventListener) {
            document.addEventListener("mousewheel", this.scrollFun, false);
        } else if (document.attachEvent) {
            document.attachEvent("onmousewheel", this.scrollFun);
        } else {
            document.onmousewheel = this.scrollFun;
        }
    },
    methods: {
        //步骤查询
        getLessonDetailInfo() {
            const lesson_id = this.$route.query.lesson_id;
            // console.log(lesson_id)
            LessonVideo.getListLessonStep({lesson_id: lesson_id}, ({type, list}) => {
                if (type === 200) {
                    list.list.forEach((item, index) => {
                        item.anchor = '#anchor-' + (index + 1);
                        item.anchorId = 'anchor-' + (index + 1);
                        item.pageUlLi = 'pageUlLi' + (index + 1);
                    })
                    this.stepData = list.list;
                    console.log(this.stepData);
                    this.getListLessonTemConInfo();
                }
            })
        },
        //步骤查询
        getListLessonTemConInfo() {
            const lesson_id = this.$route.query.lesson_id;

            this.stepData.forEach(item => {
                LessonVideo.getListLessonTemCon({step_id: item.id}, ({type, list}) => {
                    if (type === 200) {
                        console.log(list);

                        let textString = '';
                        list.forEach(val => {
                            textString += val.content;
                        })
                        if (list.length === 0) {
                            item.content = '暂时没上传内容';
                        } else {
                            item.content = textString;
                        }
                    }
                })
            })
            // this.nextTick(function(){
            //     alert('数据已经更新')
            // });
            let _this = this;
            this.$nextTick(function () {
                console.log('v-for渲染已经完成')
                _this.screenWeight = document.documentElement.clientWidth;
                _this.screenHeight = document.documentElement.clientHeight / 100 * 78;
                _this.main = document.getElementById("main");
                _this.obj = document.getElementsByTagName("div");
                for (let i = 0; i < this.obj.length; i++) {
                    console.log(this.obj[i].className)
                    if (this.obj[i].className == 'pageccc') {

                        _this.obj[i].style.height = this.screenHeight + "px";
                    }
                }
                _this.pageNum = document.querySelectorAll(".pageccc").length;
                // console.log(this.pageNum);
                // 浏览器兼容
                if ((navigator.userAgent.toLowerCase().indexOf("firefox") != -1)) {
                    document.addEventListener("DOMMouseScroll", _this.scrollFun, false);
                } else if (document.addEventListener) {
                    document.addEventListener("mousewheel", _this.scrollFun, false);
                } else if (document.attachEvent) {
                    document.attachEvent("onmousewheel", _this.scrollFun);
                } else {
                    document.onmousewheel = this.scrollFun;
                }
            })
            console.log(this.stepData)
        },
        enter() {
            this.seen = true;
        },
        leave() {
            this.seen = false;
        },
        scrollFun(event) {
            this.startTime = new Date().getTime();
            // mousewheel事件中的 “event.wheelDelta” 属性值：返回的如果是正值说明滚轮是向上滚动
            // DOMMouseScroll事件中的 “event.detail” 属性值：返回的如果是负值说明滚轮是向上滚动
            let delta = event.detail || (-event.wheelDelta);
            // 如果当前滚动开始时间和上次滚动结束时间的差值小于1.5s，则不执行翻页动作，这样做是为了实现类似节流的效果
            if ((this.startTime - this.endTime) > 1500) {
                if (delta > 0 && parseInt(this.main.offsetTop) >= -(this.screenHeight * (this.pageNum - 2))) {
                    // 向下滚动
                    this.index++;
                    console.log(this.index);
                    if (this.index <= this.pageNum) {
                        this.toPage(this.index);
                    }

                } else if (delta < 0 && parseInt(this.main.offsetTop) < 0) {
                    // 向上滚动
                    this.index--;
                    // console.log(this.index);
                    if (this.index != 0) {
                        this.toPage(this.index);
                    }

                }
                // 本次翻页结束，记录结束时间，用于下次判断
                this.endTime = new Date().getTime();
            }
        },
        // 翻页
        toPage(index) {
            // console.log(index, this.curIndex);
            if (index != this.curIndex) {
                let delta = index - this.curIndex;
                this.nowTop = this.nowTop - delta * this.screenHeight;
                this.curIndex = index;
                this.index = index;
            }
        },
        /**
         * 锚点
         */
        goAnchor(selector) {
            let company = selector.split("-")
            let index = company[1] * 1;
            this.toPage(index);
            // let delta = index - this.curIndex;
            // console.log(-(this.screenHeight * (this.pageNum - 2)),this.pageNum,this.nowTop,delta,this.screenHeight);
            // this.nowTop = this.nowTop - delta * this.screenHeight;
            // console.log(this.nowTop)
            // this.curIndex = index;
            // console.log(delta,);
            // console.log(this.nowTop);
            // console.log(this.curIndex);
            // this.curIndex = company[1] *1;
            // this.index = company[1] * 1;
            // let delta = (company[1] *1 +1) - this.curIndex;
            // this.nowTop = this.nowTop - delta * this.screenHeight;
            // console.log(anchor.offsetTop)
            // var anchor = this.$el.querySelector(selector);
            // console.log(document.documentElement.scrollTop)
            // document.body.scrollTop = anchor.offsetTop; // chrome
            // document.getElementById('wrap').scrollTop = anchor.offsetTop; // firefox
            // window.addEventListener('scroll',anchor.offsetTop)
        },
    }
}
</script>
<style scoped lang="less">
.title-class {
    width: 72%;
    height: 78vh;
    color: white;
    border-radius: 10px;
    padding: 10px 10px;
    position: absolute;
    top: 45%;
    left: 48%;
    transform: translate(-50%, -50%); /*向左向上分别平移自身的一半*/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    text-align: center;

    .span-1-class {
        font-size: 28px;
        font-weight: bold;
        color: hsla(0, 0%, 29%, 1);
        padding: 10px 6px;
        background: hsla(42, 100%, 84%, 1);
        border-radius: 20px;
        border: 3px solid #605A84;
    }

    .span-2-class {
        padding: 6px 78px;
        border-radius: 20px;
        border: 2px dashed hsla(43, 65%, 66%, 1);
    }
}

.detail-box-class {
    width: 100%;
    height: 100vh;
    background: url("../../assets/img/new-back.png");
    background-size: 100%;
}

#wrap {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
}

#main {
    position: relative;
    transition: top 1.5s;
}

.page {
    /*谨删*/
    width: 100%;
    margin: 0;
}

.type-box-class {
    position: absolute;
    right: 4%;
    top: 17%;
}

#pageUl {
    //position: absolute;
    //right: 4%;
    //top: 56%;
}

.active {
    color: red !important;
}

.pageUlLi {
    width: 140px;
    height: 20px;
    background: linear-gradient(119deg, #FDCD65 0%, #FF9316 100%);
    color: #FFFFFF;
    padding: 10px 20px;
    margin-top: 15px;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
}

.pageUlLi:hover {
    background: linear-gradient(119deg, #f6d181 0%, #fab76a 100%);
}

.aaa-class {
    width: 72%;
    height: 78vh;
    color: white;
    border-radius: 10px;
    padding: 10px 10px;
    background: #FFFFFF;
    position: absolute;
    top: 56%;
    left: 48%;
    transform: translate(-50%, -50%); /*向左向上分别平移自身的一半*/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    text-align: center;
    transition: 1s;
    z-index: 1002;
}
</style>
