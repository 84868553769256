import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class Works extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {
                // label: '书本纪AI正在为您准备适合您的题目...'
            },
        }
    }

    // 作品集合
    static getWorksList(params, fn) {
        BaseCall.get('getWorksList', {params: params}, ({type, data}, res) => {
            let list = data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
    // 作品展示
    static getWorksShow(params, fn) {
        BaseCall.get('getWorksShow', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //生成二维码
    static getWorksQrCode(params, fn) {
        BaseCall.get('getWorksQrCode', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //作品类型
    static getWorksType(params, fn) {
        BaseCall.get('getWorksType', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //作品类型
    static saveEvaluateWork(params, fn) {
        BaseCall.post('saveEvaluateWork', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //作品类型
    static getFileUpdate(params, fn) {
        BaseCall.post('getFileUpdate', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //删除作品
    static deleteWork(params, fn) {
        BaseCall.post('deleteWork', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 新的创建评论
    static createComment(params, fn) {
        BaseCall.post('createComment', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 新的创建评论
    static getListWorksResource(params, fn) {
        BaseCall.post('getListWorksResource', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 修改作品
    static editWork(params, fn) {
        BaseCall.post('editWork', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
