<template>
    <div>
        <el-upload
            action="https://bookperiod.oss-cn-shanghai.aliyuncs.com"
            :data="dataObj"
            list-type="picture"
            :multiple="false"
            :show-file-list="showFileList"
            :file-list="fileList"
            :before-upload="beforeUpload"
            :on-remove="handleRemove"
            :on-success="handleUploadSuccess"
            :on-preview="handlePreview"
        >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10MB</div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="fileList[0].url" alt="">
        </el-dialog>
    </div>
</template>

<script>
import Upload from "../../models/Upload/UploadFile";
import axios from "axios";

export default {
    name: 'SingleUpload',
    props: {
        value: String
    },
    data() {
        return {
            dataObj: {
                policy: '',
                signature: '',
                key: '',
                ossaccessKeyId: '',
                dir: '',
                host: ''
            },
            dialogVisible: false
        }
    },
    computed: {
        imageUrl() {
            return this.value
        },
        imageName() {
            if (this.value != null && this.value !== '') {
                return this.value.substr(this.value.lastIndexOf('/') + 1)
            } else {
                return null
            }
        },
        fileList() {
            return [{
                name: this.imageName,
                url: this.imageUrl
            }]
        },
        showFileList: {
            get: function () {
                return this.value !== null && this.value !== '' && this.value !== undefined
            },
            set: function (newValue) {
            }
        }
    },
    created() {
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }

        const _self = this
        Upload.getUploadFileConfig({}, ({type, list}) => {
            if (type === 200) {
                // this.typeOptions = list.list;
                _self.dataObj.policy = list.policy
                _self.dataObj.signature = list.signature
                _self.dataObj.ossaccessKeyId = list.accessid
                _self.dataObj.key = list.dir + this.getUUID() + '_${filename}'
                _self.dataObj.dir = list.dir
                _self.dataObj.host = list.host
            }
        })
    },
    methods: {
        emitInput(val) {
            this.$emit('input', val)
        },
        handleRemove(file, fileList) {
            this.emitInput('')
        },
        handlePreview(file) {
            this.dialogVisible = true
        },
        getUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
                return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
            })
        },
        beforeUpload(file) {
            console.log(file)

            // return new Promise((resolve, reject) => {
            //     // 前后端提交post异步请求获取签名信息
            //     this.postRequest('/oss/policy')
            //         .then((response) => {
            //             _self.dataObj.policy = response.policy
            //             _self.dataObj.signature = response.signature
            //             _self.dataObj.ossaccessKeyId = response.accessid
            //             _self.dataObj.key = response.dir + this.getUUID() + '_${filename}'
            //             _self.dataObj.dir = response.dir
            //             _self.dataObj.host = response.host
            //             resolve(true)
            //         }).catch(err => {
            //         reject(false)
            //     })
            // })
        },
        handleUploadSuccess(res, file) {
            console.log('上传成功...',file,res)
            this.showFileList = true
            this.fileList.pop()
            this.fileList.push({
                name: file.name,
                url: this.dataObj.host + '/' + this.dataObj.key.replace('${filename}', file.name)
            })
            console.log(this.fileList)
            this.emitInput(this.fileList[0].url)
        }
    }
}
</script>

<style scoped>

</style>
