import { render, staticRenderFns } from "./MyWorks.vue?vue&type=template&id=432a898e&scoped=true&"
import script from "./MyWorks.vue?vue&type=script&lang=js&"
export * from "./MyWorks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "432a898e",
  null
  
)

export default component.exports