<template>
    <div class="alert-class" ref="treeWrap">

        <div class="box-class">
            <div style="text-align: center">修改密码</div>
            <div class="pass-class">
                <span style="display: inline-block;">旧密码：</span>
                <span style="display: inline-block;width:60%;">
                                <el-input type="password" v-model="ruleForm.oldPassword"></el-input>
                            </span>
            </div>
            <div class="pass-class">
                <span style="display: inline-block;">新密码：</span>
                <span style="display: inline-block;width:60%">
                                <el-input type="password" v-model="ruleForm.newPassword"></el-input>
                            </span>
            </div>
            <div class="pass-class">
                <span @click="savePasswordClick(1)">修改密码</span>
                <span @click="savePasswordClick(2)">取消</span>
            </div>
        </div>
    </div>
</template>

<script>
import Qs from 'qs';
import Login from '../../models/Login/Login';

export default {
    name: "SavePassword",
    data() {
        return {
            dialogVisible: true,
            input: '',
            input1: '',
            ruleForm: {
                oldPassword: '',
                newPassword: '',
            },
        };
    },
    mounted() {
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                console.log(tree);
                if (tree !== undefined) {
                    if (!tree.contains(e.target)) {
                        // this.isShowTree =false
                        this.$emit('alertPassword', '')
                    }
                }
            }
        })
    },
    methods: {
        handleClose(done) {

        },
        savePasswordClick(type) {
            if (type === 1) {
                this.$Dialog.confirm(
                    {
                        message: '<span style="font-size: 16px">修改密码成功后会重新登录，确认修改吗？</span>',
                        confirmButtonColor: '#FF8D15',
                    })
                    .then(() => {
                        // on confirm
                        const request = Qs.stringify({
                            oldpassword: this.ruleForm.oldPassword,
                            newpassword: this.ruleForm.newPassword,
                            repassword: this.ruleForm.newPassword,
                        });
                        Login.savePassword(request, ({type, list}) => {
                            if (type === 200) {
                                localStorage.clear();
                                // localStorage.removeItem('info');
                                // localStorage.removeItem('courseData');
                                // localStorage.removeItem('groupData');
                                // localStorage.removeItem('spriteState')
                                // localStorage.removeItem('blockList')
                                // localStorage.removeItem('lessCode')
                                // localStorage.removeItem('deleteState')
                                // localStorage.removeItem('test')
                                // localStorage.removeItem('freeState')
                                // localStorage.removeItem('initialState')
                                // localStorage.removeItem('unloadGroupData')
                                // localStorage.removeItem('lockGameId')
                                // localStorage.removeItem('gameState')
                                // localStorage.removeItem('covertState')

                                this.$router.push({
                                    path: '/',
                                    name: 'Login',
                                    params: {reloadStatus: 1}
                                })
                                window.location.reload()
                            }
                        })
                        return false;

                    })
                    .catch(() => {
                        // on cancel
                    });
            }
            this.$emit('alertPassword', '')
        }
    },

}
</script>

<style lang="less" scoped>
.alert-class {
    //.box-class{
    //    text-align: center;
    //    .title-class{
    //        font-size: 18px;
    //    }
    //}
    .box-class {
        background: #ffffff;
        position: absolute;
        z-index: 1;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0px 1px 12px 0 #d9d9d9;
        right: 60px;
        top: 100px
    }

    .pass-class {
        background: #F4F4F4;
        padding: 0px 0 0px 14px;
        border-radius: 10px;
        margin-top: 15px
    }

    .pass-class:nth-child(2) {
        //margin-top: 30px
    }

    .pass-class:nth-child(4) {
        background: #FFFFFF;

        span {
            display: inline-block;
            width: 100px;
            margin: 16px auto 0;
            color: #FFFFFF;
            text-align: center;
            padding: 5px;
            background: linear-gradient(135deg, #FFBE5B 0%, #FF8A0C 100%);
            box-shadow: 0px 9px 19px 0px rgba(255, 141, 16, 0.32);
            border-radius: 32px;
            cursor: pointer;
        }

        span:nth-child(2) {
            margin-left: 30px;
        }
    }
}

/deep/ .el-dialog__header {
    display: none !important;
}

/deep/ .el-dialog {
    margin: 10px 0 50px auto;
    top: -40px;
}

/deep/ .el-dialog__body {
    padding: 10px 20px;
}

/deep/ .el-input__inner {
    border: 0 !important;
    background-color: #F4F4F4 !important;
}
</style>
