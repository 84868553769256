<template>
    <div>
        <div class="question-content">
            <template>
                <iframe v-once name="child" id="child" class="ifa" :src="url" ref="iframe"
                        style="width: 100%; height: 100vh" frameborder="0" scrolling="no"></iframe>
            </template>
        </div>
    </div>
</template>

<script>
import Scratch from "../../models/Scratch/Scratch";

export default {
    name: "MyWorks",
    data(){
        return {
            url: '/scratchWeb/scratchWorks.php',
            base: '',
            iframeWin: {},
        }
    },
    mounted() {
        let self = this;
        window.addEventListener("message", (event) => {

            // console.log(self.base)
            // 根据上面制定的结构来解析iframe内部发回来的数据
            const data = event.data;
            const params = data.params;
            switch (data.cmd) {
                case "scratchIframe":
                    console.log(params)
                    self.sendScratch();
                    break;
            }
        })
        this.iframeWin = this.$refs.iframe.contentWindow;
    },methods:{
        sendScratch() {
            Scratch.getScratchBase2({report_id: this.$route.query.target}, ({type, list}) => {
                if (type === 200) {
                    this.base = list;
                    this.sendMessage("handData", {base: this.base});
                }
            })

        },
        sendMessage(cmd, params) {
            // 外部vue向iframe内部传数据
            this.iframeWin.postMessage(
                {
                    cmd: cmd,
                    params: params,
                },
                "*"
            );
        },
    }
}
</script>

<style scoped>

</style>
