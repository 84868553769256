<template>
    <StudentLessonText></StudentLessonText>
</template>

<script>
import StudentLessonText from "../../components/studentHome/StudentLessonText";

export default {
    name: "Video",
    computed: {
    },
    components: {
        StudentLessonText
    },
    data() {
    },
    created() {
    },
    methods: {
    },
}
</script>

<style lang="less" scoped>
</style>
