<template>
  <div id="app">
    <router-view />
    <div class="share-covert-box" v-show="isShade">
      <div class="black-class"></div>
      <div class="cover-box-class" v-if="isIpad === false">
        <div class="text-class">请认真听讲哦</div>
        <div class="superman-class"><img :src="supermanImg" alt=""></div>
      </div>
      <div class="cover-box-class-1" v-if="isIpad === true">
        <div class="text-class-1">请认真听讲哦</div>
        <div class="superman-class-1"><img :src="supermanImg" alt=""></div>
      </div>
    </div>
    <div class="share-covert-box" v-show="isClassState">
      <div class="black-class"></div>
      <div class="cover-box-class" v-if="isIpad === false">
        <div class="text-class">屏蔽播放声音中</div>
        <div class="superman-class"><img :src="supermanImg" alt=""></div>
      </div>
      <div class="cover-box-class-1" v-if="isIpad === true">
        <div class="text-class-1">屏蔽播放声音中</div>
        <div class="superman-class-1"><img :src="supermanImg" alt=""></div>
      </div>
    </div>
  </div>
</template>
<script>
import "./assets/font/font.css";
import "./assets/css/main.css";
import websocket from "./utils/websocket";

export default {
  data() {
    return {
      ws: "",
      conn_id: "",
      isShade: false,
      supermanImg: './img/superman.png',
      isIpad: false,
      isClassState: false,
    };
  },
  provide() {
    return {
      conn_id: () => this.conn_id,
      isClassStateFun: () => this.isClassState
    };
  },
  created() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });

    // this.$router.push({ path: '/login' })
    websocket.Init();
    this.getNewSocketObj();
    // console.log(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Macintosh/i.test(navigator.userAgent))
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isIpad = true;
    }
  },
  methods: {
    // 重新获取对象
    getNewSocketObj() {
      this.ws = websocket.getWebSocket();
      let firstInterval = setInterval(() => {
        if (this.ws.readyState === 1) {
          this.getShadeStateNow();
          clearInterval(firstInterval);
        }
      }, 1000);
      this.ws.onmessage = (e) => {
        console.log("接收消息:" + e.data);
        websocket.heartCheck.start();
        this.messageHandle(e.data);
      };
      this.ws.onerror = () => {
        console.log("数据传输发生错误");
        this.rebinding();
      };
      this.ws.onclose = () => {
        // console.log("连接已关闭");
        this.rebinding();
      };
    },
    // 重新连接
    rebinding() {
      websocket.reconnect();
      var theSetInterval = setInterval(() => {
        let ws = websocket.getWebSocket();
        if (ws.readyState === 1) {
          this.getNewSocketObj();
          this.conn_id = websocket.getConnId();
          this.messageHandle(websocket.getMessage());
          this.getShadeStateNow();
          clearInterval(theSetInterval);
        }
      }, 1000);
    },
    //根据消息标识做不同的处理
    messageHandle(message) {
      let msg = JSON.parse(message);
      console.log(msg)
      switch (msg.action) {
        case "sync":
          // console.log("首次消息");
          this.conn_id = msg.conn_id;
          break;
        case "group_state":
          switch (this.$store.getters.getUserData.role) {
            case 1:
              if (msg.data) {
                // 老师
                // this.isShade =
                //     msg.data.state === "on" ? false : true;
                if (msg.data.state === "on") {
                  localStorage.setItem('covertState', 'on')
                } else {
                  localStorage.setItem('covertState', 'close')
                }

                if (msg.data.class_state === true) {
                  localStorage.setItem('isClassState', 'on')
                } else {
                  localStorage.setItem('isClassState', 'close')
                }
              }

              break;
            case 2:
              // 学生
              this.isShade =
                msg.data.state === "on" ? false : true;


              console.log(msg.data)
              if (window.location.href.indexOf('lessonDetail') > -1) {
                this.isClassState =
                  msg.data.class_state === true ? false : true;
              } else {
                this.isClassState = false;
              }

              break;

            default:
              break;
          }
          // console.log("状态通知");
          break;
        default:
        // console.log("未知消息类型");
      }
    },
    // 获取当前遮罩状态
    getShadeStateNow() {
      if (this.$store.getters.getUserData != null) {
        websocket.Send({
          // token: JSON.parse(localStorage.getItem('info')).token,
          token: this.$store.getters.getUserData.token,
          conn_id: this.conn_id,
          data: "",
          group_id: this.$store.getters.getGroupData.group_id,
          action: "group_state",
        });
      }
    },
  },
  mounted: function () {
  },
};
</script>
<style lang="less">
#app {
  //font-family: PingFang, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
  font-size: 16px;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  //-webkit-user-select:none;
  //-moz-user-select:none;
  //-ms-user-select:none;
  //user-select:none;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

.black-class {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  background: #1b1b1b;
  opacity: 0.5;
  left: 0;
  z-index: 1004;
}

.share-covert-box {
  .shade {
    position: absolute;
    width: 100%;
    background: #000;
    height: 100%;
    top: 0;
    opacity: 0.5;
  }

  .cover-box-class {
    width: 300px;
    height: 200px;
    color: white;
    border-radius: 10px;
    padding: 0 10px;
    background: -webkit-linear-gradient(top, #1da4d6, wheat);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*向左向上分别平移自身的一半*/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    text-align: center;
    transition: 1s;
    z-index: 3000;
    font-size: 20px;
  }

  .superman-class img {
    width: 100px;
    position: absolute;
    top: 10%;
    left: -15%;
  }

  .text-class {
    margin-top: 80px;
  }

  .cover-box-class-1 {
    width: 30rem;
    height: 20rem;
    color: white;
    border-radius: 1rem;
    padding: 0 1rem;
    background: -webkit-linear-gradient(top, #1da4d6, wheat);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*向左向上分别平移自身的一半*/
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    text-align: center;
    transition: 1s;
    z-index: 3000;
    font-size: 2rem;
  }

  .superman-class-1 img {
    width: 10rem;
    position: absolute;
    top: 10%;
    left: -15%;
  }

  .text-class-1 {
    margin-top: 8rem;
  }
}
</style>
