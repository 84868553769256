<template>
    <header class="header_home user_reg" v-title data-title="课时教案">
        <div class="main-class">
            <el-row :gutter="12">
                <el-col
                    :span="24"
                    v-for="(item, index) in textData"
                    :key="index"
                    :style="{ 'margin-bottom': '50px', 'font-size': '36px' }"
                >
                    <el-card shadow="always">
                        <span style=""
                            v-text="(index + 1 < 10 ? '0'+ (index + 1) : index + 1) + '.    ' + item.value"
                        ></span>
                        <span
                            class="copyBtn"
                            @click="copyText(item.value)"
                            >复制</span>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </header>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import eventVue from "../../assets/js/event";

export default {
    name: "VideoPlay",
    components: {
        videoPlayer,
    },
    data() {
        return {
            bfImg: "./img/bf-icon.png",
            lessonId: 0,
            courseId: 0,
            course_Intro: "",
            playTime: "",
            current: "",
            playerOptions: {
                //播放速度
                playbackRates: [0.5, 1.0, 1.5, 2.0],
                //如果true,浏览器准备好时开始回放。
                autoplay: false,
                // 默认情况下将会消除任何音频。
                muted: false,
                // 导致视频一结束就重新开始。
                loop: false,
                // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                preload: "auto",
                language: "zh-CN",
                // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                aspectRatio: "16:9",
                // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                fluid: true,
                sources: [
                    {
                        //类型
                        type: "video/mp4",
                        //url地址
                        src: "",
                    },
                ],
                //你的封面地址
                poster: "",
                //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                notSupportedMessage: "课程还未开始",
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    //全屏按钮
                    fullscreenToggle: true,
                },
            },
            status: false,
            num: 1,
            textData: [],
        };
    },
    created() {
        let self = this;
        eventVue.$on("videoPlay", function (message) {
            console.log(message.video_url);
            if (message.video_url === undefined) {
                if (self === 2) {
                    let buttonClass = document.getElementsByClassName(
                        "vjs-big-play-button"
                    );
                    buttonClass[0].style.display = "none";
                    console.log(buttonClass[0]);
                }
                self.num++;
            }
            self.playerOptions.sources[0].src = message.video_url;
            self.playerOptions.poster = message.video_img;
        });
        eventVue.$on("courseIntro", function (message) {
            self.course_Intro = message;
        });

        eventVue.$on("courseId", function (message) {
            self.courseId = message;
        });

        eventVue.$on("lessonId", function (message) {
            self.lessonId = message;
        });
        this.$route.query.textData.split("，").forEach((element) => {
            this.textData.push({
                value: element,
            });
        });
    },
    beforeRouteLeave() {
        this.$refs.videoPlayer.player.pause();
    },
    methods: {
        copyText(content, message) {
            // text: 要复制的内容， callback: 回调
            var aux = document.createElement("input");
            aux.setAttribute("value", content);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand("copy");
            document.body.removeChild(aux);
            if (message == null) {
                this.$message({
                    message: "复制成功",
                    type: "success",
                    iconClass: "iconClass",
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.main-class::-webkit-scrollbar {
    width: 0 !important
}

.main-class {
    -ms-overflow-style: none;
}

.main-class {
    overflow: -moz-scrollbars-none;
}
.main-class {
    width: 96%;
    margin: 0 auto 80px;
    height: 100vh;
    overflow-y: scroll;
    //overflow: hidden;

    h2 {
        padding: 18px 0;
    }
}
.header_home {
    width: 80%;
    margin: 31px auto;
}

.play-class {
    width: 100%;
    height: calc(99.5vh - 120px);

    .play-box-class {
        width: 98%;
        height: calc(99.5vh - 150px);
        //background: #9C9C9C;
        border-radius: 10px;
        box-shadow: 0px 0px 24px 0px #e3e3e3;
        margin-top: 15px;
        margin-left: 1.5%;
        overflow-y: scroll;
    }

    .play-box-class::-webkit-scrollbar {
        width: 0 !important;
    }

    .play-box-class {
        -ms-overflow-style: none;
    }

    .play-box-class {
        overflow: -moz-scrollbars-none;
    }



    .vjs-custom-skin > .video-js .vjs-big-play-button {
        background-color: rgba(0, 0, 0, 0.45);
        font-size: 3em;
        /* border-radius: 50%; */
        height: 1.5em !important;
        line-height: 1.5em !important;
        margin-top: -1em !important;
        margin-left: 10px;
    }

    /*这里用了第三方vue-video-player插件，但这个插件有bug，设置globalSetting:{controls:true}隐藏进度条不生效，故可设置插件样式进行隐藏vjs-progress-contro*/

    .vjs-progress-control {
        /*visibility:hidden;       // 隐藏进度条*/
    }

    /deep/ .video-js.vjs-fluid,
    .video-js.vjs-16-9,
    .video-js.vjs-4-3 {
        //height:calc(100vh - 160px);
        width: 100%;
        margin: 0 auto;
    }

    /deep/ .vjs-workinghover {
        border-radius: 15px;
    }

    /deep/ .video-js .vjs-big-play-button {
        /*对播放按钮的样式进行设置*/
        //display: none;
    }

    /deep/ .vjs-error .vjs-error-display:before {
        //content:url("../../assets/lock-icon.png");
        //background-image: url("/public/img/lock-icon.png");
        content: "";
        //background: url('../../assets/lock-icon.png') no-repeat;
        background-image: url("../../assets/lock-icon.png");
        background-size: 60px;
        display: inline-block;
        vertical-align: -2px;
        width: 60px;
        height: 60px;
        //text-align: center;
        margin-left: 47.5%;
        margin-top: -2.5%;
    }
}
.iconClass {
    display: none;
}
/deep/ .el-card {
    color: #aaa;
    border-radius: 15px;
}
.copyBtn {
    float: right;
    background: #FFBC1B;
    color: #fff;
    border-radius: 0.21354rem;
    padding: 0.04167rem 0.14583rem;
    font-size: 0.14583rem;
    cursor: pointer;
}
/deep/ .el-card__body {
    padding: 34px 60px;
}
</style>
