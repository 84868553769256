<template>
    <div class="box-class" ref="treeWrap">
        <div class="main-class">
            <div class="img-class">
                <img :src="avatarImg" alt="">
                <div class="cover-class">
                    <el-upload
                        class="avatar-uploader"
                        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                        action="/resource/v1/uploadFile"
                        :headers="headerObj"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <div class="head-default-class" >
                            <svg-icon icon-class="photograph-icon" class="svg-icon-class"/>
                        </div>
                    </el-upload>
                </div>
            </div>
            <div class="text-class">
                更换头像
            </div>
            <div class="input-class">
                <el-input v-model="nickname" placeholder="输入你的昵称..."></el-input>
            </div>
            <div class="but-class">
                <div @click="submitForm">确认修改</div>
                <div>重置</div>
            </div>
        </div>
    </div>
</template>

<script>
import Login from '../../models/Login/Login';
import Qs from 'qs';
import eventVue from '../../assets/js/event.js';

export default {
    props: {
        homeBasisData: {type: Object}
    },
    name: "CenterAlert",
    data() {
        return {
            headerObj: {
                token: JSON.parse(localStorage.getItem('info')).token
            },
            avatarImg: './img/default-head-img2.png',
            nickname: '',
            avatarId: 0
        }
    },
    created() {
        console.log(this.homeBasisData);
        if (this.homeBasisData !== undefined && this.homeBasisData.userinfo !== undefined && this.homeBasisData.userinfo.user_avatar !== '') {
            this.avatarImg = this.homeBasisData.userinfo.user_avatar;
            this.nickname = this.homeBasisData.userinfo.nickname;
            this.avatarId = this.homeBasisData.userinfo.user_avatar_id;
        }
    },
    mounted() {
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                if (!tree.contains(e.target)) {
                    this.$emit('alertState')
                    // this.isShowTree =false
                }
            }
        })
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.avatarId = res.data.id;
            this.avatarImg = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            console.log(file);
        },
        submitForm(formName) {
            this.$Dialog.confirm(
                {
                    message: '<span style="font-size: 16px">确定修改吗？</span>',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    const request = Qs.stringify({
                        slogan: ' ',
                        nickname: this.nickname,
                        avatar_id: this.avatarId,
                    });
                    Login.saveUserInfo(request, ({type, list}) => {
                        if (type === 200) {
                            //修改父级id
                            // this.$emit('onBasic')
                            eventVue.$emit('onBasicInfo', {
                                nickname: this.nickname,
                                imageUrl: this.avatarImg
                            })
                            this.$emit('alertState')
                            // console.log();
                        }
                    })
                })
                .catch(() => {
                    // on cancel
                });

        },
    }
}
</script>

<style lang="less" scoped>
.box-class {
    width: 310px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    opacity: 1;
    border-radius: 10px;

    .main-class {
        display: flex;
        flex-direction: column;
        justify-content: start;
        text-align: start;

        .img-class {
            width: 80px;
            height: 80px;
            margin-top: 30px;
            margin-left: 30px;
            border-radius: 10px;
            position: relative;
            overflow: hidden;

            .cover-class {
                width: 80px;
                height: 80px;
                background: rgba(18, 18, 18, 0.25);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                cursor: pointer;
                line-height: 100px;
                text-align: center;

                .svg-icon {
                    width: 30px;
                    height: 30px;
                }
            }

            img {
                width: 80px;
                height: 80px;
            }
        }

        .text-class {
            padding: 10px 40px;
            color: #666666;
            font-size: 14px;
        }

        .input-class {
            width: 250px;
            margin: 10px auto;

            /deep/ .el-input__inner {
                background: #F5F6F7 !important;
                border: none;
                border-radius: 10px !important;
            }
        }

        .but-class {
            display: flex;
            margin: 10px 30px 30px;
            justify-content: space-between;
            text-align: center;
            color: #ffffff;

            div {
                width: 110px;
                background: #C4C4C4;
                height: 40px;
                line-height: 40px;
                border-radius: 10px;
                font-size: 14px;
                cursor: pointer;
            }

            div:nth-child(1) {
                background: #FF8B0C;
            }
        }
    }
}
</style>
