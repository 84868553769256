import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class LessonVideo extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {
                // label: '书本纪AI正在为您准备适合您的题目...'
            },
        }
    }

    // 课时列表
    static getLessonList(params, fn) {
        BaseCall.get('getLessonList', {params: params}, ({type, data}, res) => {
            let list = data.data;
            console.log(res)
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
    // 课时列表
    static getListBodies(params, fn) {
        BaseCall.get('getListBodies', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
    // 课时列表
    static saveLessonLock(params, fn) {
        BaseCall.post('saveLessonLock', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 课时列表
    static getStudentLessonList(params, fn) {
        BaseCall.get('getStudentLessonList', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 课时列表
    static getInfoLesson(params, fn) {
        BaseCall.get('getInfoLesson', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 课时列表
    static updateDuration(params, fn) {
        BaseCall.post('updateDuration', params, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 课时步骤
    static getListLessonStep(params, fn) {
        BaseCall.get('getListLessonStep', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 课时内容详情
    static getListLessonTemCon(params, fn) {
        BaseCall.get('getListLessonTemCon', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }// 解锁学生课程
    static payUnlockLesson(params, fn) {
        BaseCall.get('payUnlockLesson', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //更新步骤历史
    static updateStepHistory(params, fn) {
        BaseCall.get('updateStepHistory', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
    //更新步骤历史
    static getLatestStep(params, fn) {
        BaseCall.get('getLatestStep', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //激活课时
    static getActivateLesson(params, fn) {
        BaseCall.get('getActivateLesson', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //激活课时
    static getSharedUrl(params, fn) {
        BaseCall.get('getSharedUrl', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
    //激活课时
    static getListEduLessonRawCode(params, fn) {
        BaseCall.get('getListEduLessonRawCode', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
    // 查询课程getAdminListCourse
    static getAdminListCourse(params, fn) {
        BaseCall.get('getAdminListCourse', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
    // 查询课程getAdminListCourse
    static listCourseCartoonAsset(params, fn) {
        BaseCall.get('listCourseCartoonAsset', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
