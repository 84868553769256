<template>
    <div>
        <div class="top">
            <button @click="leftScreen()">左边全屏</button>
            <button @click="rightScreen()">右边全屏</button>
        </div>
        <div class="main">
            <div class="left">
                <button @click="redScreen()">红色全屏</button>
                <button @click="exitScreen()">退出全屏</button>
                <div class="left-son">
                    <button @click="exitScreen()">红色退出全屏</button>
                    <span>左边的内容</span>
                </div>
            </div>
            <div class="right">右边的内容</div>
        </div>
    </div>

</template>
<script>
import fullScreen from "./test.js";

export default {
    data() {
        return {}
    },
    mounted() {
//         let full = new fullScreen(() => {
//             console.log("不支持");
//         });
//         full.screenError(e => {
//             console.log("进去全屏失败:", e);
//         });
// // 全屏请求必须在事件处理函数中调用，否则将会被拒绝。
// //         full.Fullscreen(".left"); // 触发进去全屏失败回调
//         const obj = {
//             enter: e => {
//                 // 如果退出全屏 退出的还是全屏状态，将会触发进入全屏的回调，这种情况比较少 注意一下
//                 console.log("进入全屏", e);
//             },
//             quit: e => {
//                 console.log("退出全屏", e);
//                 // 通常不会出现嵌套的情况
//             }
//         };
//         full.screenChange(obj.enter, obj.quit);

    },
    methods: {
        leftScreen() {
            let full = new fullScreen(()=>{});
            full.Fullscreen(".left");
        },
        rightScreen() {
            let full = new fullScreen(()=>{});
            full.Fullscreen(".right");
        },
        redScreen() {
            let full = new fullScreen(()=>{});
            full.Fullscreen(".left-son");
        },
        exitScreen() {
            let full = new fullScreen(()=>{});
            full.exitFullscreen();
        },
    },
    destroyed() {
    }
}
</script>

<style lang="less" scoped>
*{
    font-size: 1.6rem;
}

.top {
    margin: 15px;
}

.main {
    width: 100%;
    height: 100vh;
    display: flex;
    background: yellow;
}

.left {
    width: 50%;
    height: 60%;
    background: gray;
    padding: 2rem;
}

.left-son {
    width: 80%;
    height: 50%;
    margin: 1.5rem;
    background: red;
}

.right {
    width: 50%;
    height: 60%;
    background: #dddddd;
}

/* 针对dom的全屏设置 */
.left:-webkit-full-screen {
    background: #fff;
}

/* 全屏属性 */
:-webkit-full-screen {
}

:-moz-full-screen {
}

:-ms-fullscreen {
}

/* 全屏伪类 当前chrome:70 不支持 */
:full-screen {
}

:fullscreen {
    /* IE11支持 */
}

</style>
