<template>
    <div class="select-alert-class" ref="treeWrap">
        <ul>
            <li v-for="(item,index) in basicData.data" :key="index">
                <div class="main-class" @click="selectButClick(item)" :title="item.name">{{ item.name }}</div>
            </li>
        </ul>
    </div>

</template>

<script>
export default {
    props: ['Data'],
    name: "SelectAlert",
    data() {
        return {
            basicData: this.Data
        }
    },
    created() {
        console.log(this.basicData)
    },
    mounted() {
        let _this = this;
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                if (!tree.contains(e.target)) {
                    const closeState = 10;
                    // this.$parent.closeSelectAlert(closeState);
                    // this.$parent.selectGroupCLick(closeState);
                    // this.isShowTree =false
                }
            }
        })
    },
    methods: {
        selectButClick(item) {
            this.$emit('returnData', { type: this.Data.type, data: item })
        }
    }
}
</script>

<style lang="less" scoped>
/* 设置滚动条的样式 */

::-webkit-scrollbar {
    width: 6px;
}

/* 滚动槽 */

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

/* 滚动条滑块 */

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.1);
}

.select-alert-class {
    position: absolute;
    left: -80px;
    top: 36px;
    //max-width: 150px;
    //height: 300px;
    //background: #FFFFFF;
    box-shadow: 3px 3px 20px rgba(255, 224, 188, 0.25);
    opacity: 1;
    z-index: 100;
    font-weight: bold;
    max-height: 40vh;
    overflow-y: scroll;

    li:first-child {
        border-radius: 10px 10px 0 0;
    }

    li:last-child {
        border-radius: 0 0 10px 10px;
    }

    li {
        background: #FFFFFF;
        text-align: center;

        padding: 0;

        .main-class {
            padding: 8px 0;
            line-height: 40px;
            //width: 80%;
            margin: 0 auto;
            //border-bottom: 1px solid #F2F2F2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
        }


        //.main-class:hover {
        //    background: #FFC17F;
        //    color: #FFFFFF;
        //}
    }

    li:not(:last-child) {
        .main-class {
            border-bottom: 1px solid #F2F2F2;
        }
    }

    li:hover {
        background: #FFC17F;
        color: #FFFFFF;
    }
}
</style>
