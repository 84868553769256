import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class TeacherUser extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {
                // label: '书本纪AI正在为您准备适合您的题目...'
            },
        }
    }

    // 首页几班信息
    static getBasisInfo(params, fn) {
        BaseCall.get('getBasisInfo', {params: params}, ({type, data}, res) => {
            // console.log(type);
            // console.log(data);
            // return false;
            let list = data.data;
            typeof fn === 'function' ? fn({ type, list }) : '';
            // typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    //首页班级信息
    static getGroupList(params, fn) {
        BaseCall.get('getGroupList', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    // 首页课程信息
    static getCourseList(params, fn) {
        BaseCall.get('getCourseList', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    } // 首页课程信息
    static getTest(params, fn) {
        BaseCall.get('test', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
