var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"star star2",style:('width:' +
					_vm.size +
					'px;height:' +
					_vm.size +
					'px;top:' +
					_vm.top +
					'px;left:' +
					_vm.left +
					'px;right:' +
					_vm.right +
					'px;animation-delay:' +
					_vm.delay +
					's')})])
}
var staticRenderFns = []

export { render, staticRenderFns }