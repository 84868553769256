import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class Scratch extends BaseModel {

    /**
     * 首页本周上周统计记录
     */
    static getScratchBase(params, fn) {
        BaseCall.get('getScratchBase', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }

    static getScratchBase2(params, fn) {
        BaseCall.get('getScratchBase2', {params: params}, ({type, data}, res) => {
            let list = data.data
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
