import BaseModel from "../../common/model/BaseModel";
import BaseCall from "../../common/model/BaseCall";

export default class Explain extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.format = {
            load_text: {
                // label: '书本纪AI正在为您准备适合您的题目...'
            },
        }
    }

    // 帮助人员信息
    static getHelperStaffList(params, fn) {
        BaseCall.get('getHelperStaffList', {params: params}, ({type, data}, res) => {
            let list = data.list
            typeof fn === 'function' ? fn({type, list}) : '';
        });
    }
}
