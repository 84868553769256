<template>
    <div class="center-class">
        <div class="center-box-class">
            <div class="main-class">
                <div style="width:100px;text-align: center">
                    <el-upload
                        class="avatar-uploader"
                        accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                        action="/resource/v1/uploadFile"
                        :headers="headerObj"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <!--                    <img v-if="imageUrl" :src="imageUrl" class="avatar">-->
                        <el-image v-if="imgStatus" class="code-but-class" :src="imageUrl" fit="cover"
                                  style="width: 100px;height:100px;cursor: pointer;border-radius: 50%"></el-image>
                        <!--                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
                        <div v-else
                             class="head-default-class" :style="{backgroundImage:'url(' + imageUrl + ')',backgroundRepeat:'no-repeat',cursor: 'pointer'}">
                            点击上传头像
                        </div>
                    </el-upload>
                    <div class="save-head-class">更换头像</div>
                </div>

                <div style="margin-top: 30px">
                    <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="54px" class="demo-ruleForm">
                        <el-form-item label="昵称:" prop="username" style="width: 500px">
                            <el-input v-model.number="ruleForm.username"></el-input>
                        </el-form-item>
                        <el-form-item label="签名:" prop="pass" style="width: 500px">
                            <el-input v-model="ruleForm.sign"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
                            <el-button @click="resetForm('ruleForm')">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Login from '../../models/Login/Login';
import Qs from 'qs';
import eventVue from '../../assets/js/event.js';

export default {
    props: {
        homeBasisData: {type: Object}
    },
    name: "PersonalCenter",
    data() {
        return {
            headerObj: {
                token: JSON.parse(localStorage.getItem('info')).token
            },
            imageUrl: '',
            avatarId: 0,
            ruleForm: {
                username: '',
                sign: '',
            },
            imgStatus: true
        };
    },
    created() {
        if (this.$parent.homeBasisData != undefined) {
            this.ruleForm = {
                username: this.$parent.homeBasisData.userinfo.nickname,
                sign: this.$parent.homeBasisData.userinfo.slogan
            }
            this.imageUrl = this.$parent.homeBasisData.userinfo.user_avatar;
        }
        console.log(this.$parent.homeBasisData)
        // if (this.homeBasisData.userinfo.user_avatar === "") {
        this.imgStatus = false;
        eventVue.$emit('topMenu', [{text: '个人中心', type: 1, url: 'PersonalCenter'}]);
        // } else {
        // this.imageUrl = this.homeBasisData.userinfo.user_avatar;
        // }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.avatarId = res.data.id;
            this.imageUrl = URL.createObjectURL(file.raw);
            this.imgStatus = true;
        },
        beforeAvatarUpload(file) {
            console.log(file);
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            // return isJPG && isLt2M;
        },
        submitForm(formName) {
            this.$Dialog.confirm(
                {
                    message: '<span style="font-size: 16px">确定修改吗？</span>',
                    confirmButtonColor: '#FF8D15',
                })
                .then(() => {
                    const request = Qs.stringify({
                        slogan: this.ruleForm.sign,
                        nickname: this.ruleForm.username,
                        avatar_id: this.avatarId,
                    });
                    Login.saveUserInfo(request, ({type, list}) => {
                        if (type === 200) {
                            //修改父级id
                            // this.$emit('onBasic')
                            eventVue.$emit('onBasicInfo', {
                                slogan: this.ruleForm.sign,
                                nickname: this.ruleForm.username,
                                imageUrl: this.imageUrl
                            })
                            // console.log();
                        }
                    })
                })
                .catch(() => {
                    // on cancel
                });

        },
        resetForm(formName) {
            this.ruleForm = {
                username: '',
                sign: ''
            }
        }
    }
}
</script>

<style lang="less" scoped>
.header_home {
    width: 86%;
}

.head-default-class {
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border-radius: 50%;
    background: #F4F4F4;
    background-size: 100% 100%;
}

.center-class {
    width: 100%;
    height: calc(99.7vh - 120px);

    .center-box-class {
        width: calc(100% - 40px);
        margin: 12px auto;
        height: calc(99.7vh - 175px);
        background: #FFFFFF;
        border-radius: 10px;

        .main-class {
            padding: 30px;
        }

        .save-head-class {
            padding: 10px 0;
        }
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    /deep/ .el-button--primary {
        background: #FF8B0C;
        border: 1px solid #FF8B0C;
    }
}
</style>
