<template>
  <div class="report-class">
    <div class="report-box-class" v-if="flag" style="background: #ff8d15">
      <!--                <div style="width:100%;height:60px;position: absolute;top: 0;z-index: 0;opacity: 1"-->
      <!--                     :style="{background:colorBack}">-->

      <!--                </div>-->
      <!--                <div-->
      <!--                    style="position: absolute;top: 0;z-index: 2;color:#FF8E0E;margin-top:16px;padding: 6px 20px;background: #FFFFFF;border-radius: 0 22.5px 22.5px 0">-->
      <!--                    {{ shareData.bureau_name }}-->
      <!--                </div>-->
      <!--                <div>-->
      <!--                    <el-image-->
      <!--                        style="width: 100%; height: 100%"-->
      <!--                        :src="shareReportImg"-->
      <!--                        fit="cover"></el-image>-->
      <!--                </div>-->

      <div class="study-main-class" style="margin-top: 60px">
        <div class="study-main-min-class">
          <span class="study-title-class">成果展示</span>
          <div
            style="padding: 30px 0; width: 88%; margin-left: 15%"
            @click="worksClick()"
          >
            <el-image
              v-if="shareData.report_img === ''"
              style="width: 80%; height: 90%"
              :src="bfWorksImg"
              fit="fill"
            ></el-image>
            <el-image
              v-else
              style="width: 80%; height: 90%"
              :src="shareData.report_img"
              fit="fill"
            ></el-image>

            <el-image
              style="
                width: 20%;
                height: 20%;
                position: absolute;
                top: 35%;
                left: 40%;
              "
              :src="bfIconImg"
              fit="fill"
            ></el-image>
          </div>
        </div>
      </div>

      <div
        style="
          font-family: PingFang-Bold;
          margin-top: -20px;
          margin-bottom: 80px;
        "
      >
        <div style="margin-left: 20px; color: #ffffff">
          点击<span style="font-size: 24px">视频</span>左上角的
          <el-image
            round
            class="code-but-class"
            :src="flagIcon"
            fit="cover"
            style="width: 20px; height: 20px; cursor: pointer"
          ></el-image>
        </div>
        <div style="float: right; margin-right: 20px; font-size: 16px">
          <div
            style="
              background: #ffffff;
              padding: 6px 10px;
              border-radius: 22.5px;
              color: #ff8d15;
            "
            @click="worksClick()"
          >
            点击运行<i class="el-icon-caret-right"></i>
          </div>
        </div>
      </div>
      <div class="study-main-class">
        <div class="study-main-min-class">
          <span class="study-title-class">学习课时</span>
          <span
            class="lesson-title-class"
            style="height: 4rem; line-height: 4rem"
            >{{ shareData.learning_content }}</span
          >
        </div>
      </div>

      <div class="study-main-class">
        <div class="study-main-min-class" style="height: 6rem">
          <span class="study-title-class">学习时长</span>
          <div class="time-class">课程总耗时</div>
          <div class="stat-class">
            <div class="progress-bar-class">
              <span
                style="
                  display: inline-flex;
                  width: 30%;
                  height: 10px;
                  background: #ff8e0e;
                  border: 1px solid #ff8e0e;
                  float: left;
                  border-radius: 23px 0 0 23px;
                "
                :style="{ width: statWidth + '%' }"
              ></span>
              <div
                style="
                  display: inline-block;
                  position: absolute;
                  top: -5px;
                  left: 30%;
                  background: #ffffff;
                  width: 10px;
                  height: 10px;
                  border: 1px solid #ff8e0e;
                  border-radius: 50%;
                  padding: 5px;
                "
                :style="{ left: statWidth + '%' }"
              ></div>
              <span
                v-if="shareData.elaspsed_time < 100"
                style="
                  display: inline-block;
                  width: 68%;
                  height: 10px;
                  background: #ffffff;
                  border: 1px solid #ff8e0e;
                  float: left;
                  border-radius: 0 23px 23px 0;
                "
                :style="{ width: statBigWidth + '%' }"
              ></span>

              <div class="zero-class">0分</div>
              <div
                v-if="shareData.elaspsed_time < 100"
                class="course-class"
                :style="{ left: statWidth + '%' }"
              >
                共完成{{ shareData.elaspsed_time }}分
              </div>
              <!--手写宽度-->
              <div class="hundred-class">100分</div>
            </div>
          </div>
          <div
            style="position: absolute; top: 4rem; left: 0.5rem; color: #ff8e0e"
          >
            完成作品数：{{ shareData.works_num }}个
          </div>
        </div>
      </div>

      <div class="study-main-class" v-if="shareData.teacher_message !== ''">
        <div class="study-main-min-class" style="height: 6rem">
          <span class="study-title-class">老师留言</span>
          <div style="padding: 20px; overflow-y: scroll; height: 4.8rem">
            {{ shareData.teacher_message }}
          </div>
        </div>
      </div>

      <div
        class="study-main-class"
        v-if="shareData.teacher_tickling.length !== 0"
      >
        <div class="study-main-min-class">
          <span class="study-title-class">留言内容</span>
          <div style="padding-top: 20px; overflow-y: scroll">
            <!--                            <LeavingVideo></LeavingVideo>-->
            <!--                            <van-swipe @change="onChange">-->
            <span v-if="videoMsg.length !== 0">
              <el-row :gutter="0">
                <el-col :span="24" v-for="item in videoMsg" :key="item">
                  <div class="grid-content bg-purple">
                    <LeavingVideo :videoUrl="item.url"></LeavingVideo>
                  </div>
                </el-col>
              </el-row>
            </span>
            <span
              v-if="imageMsg.length !== 0"
              style="padding-top: 0.5rem; display: inline-block"
            >
              <el-row :gutter="0">
                <el-col :span="8" v-for="item in imageMsg" :key="item">
                  <div class="grid-content bg-purple">
                    <el-image
                      style="width: 100%"
                      :src="item.url"
                      :preview-src-list="[item.url]"
                    >
                    </el-image>
                  </div>
                </el-col>
              </el-row>
            </span>
            <!--                                <van-row>-->
            <!--                                    <van-col span="8">-->
            <!--                                        <el-image-->
            <!--                                            style="width: 100px; height: 100px"-->
            <!--                                            :src="item.url"-->
            <!--                                            :preview-src-list="item.url">-->
            <!--                                        </el-image>-->
            <!--                                    </van-col>-->
            <!--                                </van-row>-->

            <!--                                <van-swipe-item>1</van-swipe-item>-->
            <!--                                <van-swipe-item>-->
            <!--                                    <LeavingVideo></LeavingVideo>-->
            <!--                                </van-swipe-item>-->
            <!--                                <van-swipe-item>3</van-swipe-item>-->
            <!--                                <van-swipe-item>-->
            <!--                                    <LeavingVideo></LeavingVideo>-->
            <!--                                </van-swipe-item>-->
            <!--                                <template #indicator>-->
            <!--                                    <div class="custom-indicator">{{ current + 1 }}/4</div>-->
            <!--                                </template>-->
            <!--                            </van-swipe>-->
          </div>
        </div>
      </div>

      <!--                <div style="text-align: center;color: #FFFFFF;font-size: 24px">-->
      <!--                    <div>您的孩子已经上完了{{ shareData.finished_lesson_num }}节课</div>-->
      <!--                    <div>还有{{ shareData.unfinished_lesson_num }}节课等待他去探索</div>-->
      <!--                </div>-->
      <van-popup v-model="show" closeable :close-on-click-overlay="false">
        <div style="text-align: center; height: 20px; line-height: 46px">
          长按保存分享给好友
        </div>
        <div style="margin-top: 20px">
          <el-image
            round
            class="code-but-class"
            :src="imgUrl"
            fit="cover"
            style="width: 322px"
          ></el-image>
        </div>
      </van-popup>

      <div style="opacity: 1; position: fixed; top: 0; z-index: -1">
        <div ref="imageWrapper">
          <HshareAlert :shareData="qrData"></HshareAlert>
          <!--                    <ShareAlert :shareData="qrData" style="padding: 10px;width: 100%;margin:0 auto;font-size: 14px;"></ShareAlert>-->
          <!--                    <div style="padding: 10px;width: 322px;margin:0 auto;font-size: 14px;">-->
          <!--                        <div-->
          <!--                            :style="{backgroundImage:'url(' + shareBack + ')',backgroundRepeat:'no-repeat',cursor: 'pointer'}"-->
          <!--                            style="background-size: 100% 100%;height: 50vh;">-->
          <!--                            <div-->
          <!--                                style="width:50px;height: 50px;border-radius: 50%;background: #FFFFFF;float: left;margin:16px 10px">-->
          <!--                                <el-image round class="code-but-class" :src="shareData.bureau_avatar_url"-->
          <!--                                          fit="cover"-->
          <!--                                          style="width: 50px;height:50px;cursor: pointer;border-radius: 50%"></el-image>-->
          <!--                            </div>-->
          <!--                            <div style="width:calc(100% - 110px);float: left;margin:16px;color:#FFFFFF;">-->
          <!--                                {{ shareData.nickname }}，在{{ shareData.bureau_name }}学习果宝编程课程。-->
          <!--                            </div>-->
          <!--                        </div>-->

          <!--                        <el-row>-->
          <!--                            <el-col :span="16" style="margin-top: 20px">-->
          <!--                                <div-->
          <!--                                    style="padding: 10px 0;font-size: 18px;color: #FF8D15;font-weight: bold">-->
          <!--                                    {{ shareData.bureau_name }}-->
          <!--                                </div>-->
          <!--                                <div style="color: #FF8D15;font-weight: bold;padding-bottom: 10px">-->
          <!--                                    扫右边二维码看我的作品-->
          <!--                                </div>-->
          <!--                                <div style="padding-top: 10px;">-->
          <!--                                    <span><i class="el-icon-phone-outline"></i></span>-->
          <!--                                    &nbsp;-->
          <!--                                    <span>{{ shareData.bureau_phone }}</span>-->
          <!--                                </div>-->
          <!--                            </el-col>-->
          <!--                            <el-col :span="8">-->
          <!--                                <div style="margin-top:20px">-->
          <!--                                    <el-image slot="reference" class="code-but-class"-->
          <!--                                              :src="shareData.qr_code"-->
          <!--                                              fit="cover"-->
          <!--                                              style="width: 100%;height:100%;"></el-image>-->
          <!--                                </div>-->
          <!--                            </el-col>-->
          <!--                            <el-col :span="24">-->
          <!--                                <div style="padding: 10px 0;">-->
          <!--                                    <span><i class="el-icon-map-location"></i></span>-->
          <!--                                    &nbsp;-->
          <!--                                    <span>{{ shareData.bureau_location }}</span>-->
          <!--                                </div>-->
          <!--                            </el-col>-->
          <!--                        </el-row>-->

          <!--                    </div>-->
        </div>
      </div>

      <!--                <div-->
      <!--                    style="color: #FFFFFF;position: absolute;top: 0;z-index: 1;width: 96%;line-height: 50px;text-align: right;margin-right: 30px"-->
      <!--                    @click="downImgClick">-->
      <!--                    分享图片-->
      <!--                </div>-->

      <div
        style="
          box-shadow: 5px 5px 5px 5px saddlebrown;
          color: #ffffff;
          position: fixed;
          bottom: 0;
          z-index: 1;
          background: #ffba6e;
          width: 100%;
          line-height: 50px;
          text-align: center;
        "
        @click="downImgClick"
      >
        分享我的作品
      </div>
      <div style="width: 100%; height: 80px"></div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import "../../assets/font/font.css";
import HshareAlert from "../../components/share/HshareAlert";
import Report from "../../models/Share/Report";
import LeavingVideo from "./LeavingVideo";

export default {
  name: "ShareWorksReport",
  data() {
    return {
      srcList: [],
      colorBack: "#FFFFFF",
      bfWorksImg: "./img/bf-works.png",
      flagIcon: "./img/flag-icon.png",
      bfIconImg: "./img/bf-icon.png",
      shareReportImg: "./img/share-report.jpg",
      footerShareImg: "./img/share-footer.png",
      shareData: {},
      flag: false,
      statWidth: 0,
      statBigWidth: 0,
      shareBack: "./img/share-back2.png",
      show: false,
      imgUrl: "",
      textStatus: false,
      gotop: false,
      current: 0,
      videoMsg: [],
      imageMsg: [],
      qrData: {},
    };
  },
  components: {
    LeavingVideo,
    HshareAlert,
  },
  created() {
    this.shareInfo();
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let self = this;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
          self.downImgClick();
        }
      }, 0);
    },
    handleScroll(e) {
      let scrolltop = e.target.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    downImgClick() {
      this.textStatus = true;
      html2canvas(this.$refs.imageWrapper, {
        useCORS: true,
        x: 0,
        y: window.pageYOffset,
      }).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        console.log(dataURL);
        this.imgUrl = dataURL;

        if (this.imgUrl !== "") {
          this.dialogTableVisible = true;
        }
      });
      this.show = true;
    },
    //首页基本信息
    // homeBasisInfo() {
    //     TeacherUser.getBasisInfo({}, ({type, list}) => {
    //         if (type === 200) {
    //             this.homeBasisData = list;
    //             this.homeGroupList()
    //         }
    //     })
    // },
    worksClick() {
      // this.$router.push({
      //     path: "myWorks",
      //     name: "MyWorks",
      //     query: {target: this.$route.query.target},
      // });
    },
    shareInfo() {
      const params = {
        report_id: this.$route.query.target,
      };
      const _this = this;
      Report.getShareReport(params, ({ type, list }) => {
        if (type === 200) {
          this.qrData = {
            work_name: list.work_name,
            work_image_url: list.report_img,
            bureau_avatar_url: list.bureau_avatar_url,
            nikename: list.nikename,
            bureau_phone: list.bureau_phone,
            work_num: list.works_num,
            bureau_location: list.bureau_location,
            qr_code: list.qr_code,
            bureau_name: list.bureau_name,
            user_name: list.nickname,
          };
          if (list.learning_content.indexOf("<br>") > -1) {
            list.learning_content = list.learning_content.replace("<br>", "-");
          }

          if (list.elaspsed_time >= 100) {
            list.elaspsed_time = 100;
          }

          list.teacher_tickling.forEach((item) => {
            if (item.type === "video") {
              _this.videoMsg.push(item);
            }
            if (item.type === "image") {
              _this.imageMsg.push(item);
            }
          });
          this.shareData = list;
          this.statWidth = list.elaspsed_time;
          this.statBigWidth = 100 - list.elaspsed_time - 2;
          this.flag = true;
          this.colorBack = "#FF8E0E";
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.report-class {
  width: 100%;
  //background: #FF8E0E;
  font-family: ZXFChildhoodDream-W, serif;
  padding-bottom: 3rem;

  .report-box-class {
    font-size: 0.5rem;
    height: 100vh;
    overflow-y: scroll;

    .study-main-class {
      width: 86%;
      margin: 1rem auto;
      background: #ffa128;
      border: 40px solid #ffa128;
      border-radius: 60px;

      .study-main-min-class {
        width: 100%;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 60px;
        position: relative;

        .study-title-class {
          padding: 18px 100px;
          background: #ff8e0e;
          position: absolute;
          top: -0.7rem;
          color: #ffffff;
          left: 30%;
          border-radius: 1rem;
          border: 30px solid #ffa128;
        }

        .lesson-title-class {
          display: inline-block;
          text-align: center;
          width: 100%;
          color: #ff8e0e;
          text-overflow: ellipsis; /*只是起到注解作用*/

          white-space: nowrap; /*不让文本换行*/

          overflow: hidden; /*超出的部分显示...*/
        }

        .time-class {
          padding: 1rem 0.5rem 0.5rem 0.5rem;
        }

        .progress-bar-class {
          width: 88%;
          //height: 20px;
          ////background: #FF8E0E;
          //border:10px solid #F4F4F4;
          position: relative;
          margin: 0 auto;
        }

        .zero-class {
          font-size: 0.2px;
          position: absolute;
          top: 0.5rem;
          color: #ff8e0e;
        }

        .course-class {
          font-size: 0.2px;
          position: absolute;
          top: -0.5rem;
          left: 25%;
          color: #ff8e0e;
        }

        .hundred-class {
          font-size: 0.2px;
          position: absolute;
          right: 0;
          top: -0.5rem;
          color: #ff8e0e;
        }
      }
    }
  }

  .study-main-class:last-child {
    margin: 1rem auto 0;
  }

  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
  }

  /deep/ .van-swipe-item {
    width: 100%;
    height: 4.6rem;
  }
}
</style>
